import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { withStyles } from "@material-ui/styles";
import { Grid, IconButton } from "@material-ui/core";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#3576A7",
  },
  title: {
    textAlign: "center",
  },
  contactUs: {
    border: "2px solid #4576A3",
    borderRadius: "30px",
    width: "194px",
    height: "59px",

    marginBottom: "48px",
  },
}));


const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#3576A7",
  },
});

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, onClose, classes, ...other } = props;
  return (
    <DialogTitle disableTypography {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});



const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    justifyContent: "center",
    padding: theme.spacing(1),
  },
}))(DialogActions);

export default function CreateCollaborationDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleContactUs = () => {
    const url = "mailto:info@tracebloc.io";
    window.open(url, "");
    props.onClose(false);
  };

  return (
    
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 12 },
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <CustomDialogTitle onClose={handleClose}>
          Create Collaboration
        </CustomDialogTitle>
        <DialogContent>
          <Grid className="create-competition-dialog" container>
            <Grid item xs={6}>
              <h1>Collaboration</h1>
              <div className="competition-description">
                Collaborations are a technology-focused approach to sourcing. In
                competitions, AI vendors are competing based on their model
                performance on a specific use case. Create competitions to vet
                AI vendors, startups, and students globally.
              </div>
              <div className="competition-image">
                <img src="/images/create-competition@2x.png" />
              </div>

              <div>
                <div container className="key-points">
                  <Grid item>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 12,
                        marginRight: 12,
                        color: "#3576A7",
                      }}
                    />
                  </Grid>
                  <Grid className="description" item lg={9}>
                    Identify the most relevant technologies and innovations
                  </Grid>
                </div>

                <div container className="key-points">
                  <Grid item>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 12,
                        marginRight: 12,
                        color: "#3576A7",
                      }}
                    />
                  </Grid>
                  <Grid className="description" item lg={9}>
                    Technically vet your vendors AI competency
                  </Grid>
                </div>

                <div container className="key-points">
                  <Grid item>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 12,
                        marginRight: 12,
                        color: "#3576A7",
                      }}
                    />
                  </Grid>
                  <Grid className="description" item lg={9}>
                    Source AI globally and fully automated. Source 2.2x faster,
                    28% cheaper, generate more high-quality output
                  </Grid>
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <h1>Powerhouse</h1>
              <div className="competition-description" lg={9}>
                A Powerhouse is like a Collaboration, just within your
                organization. Powerhouses are designed to identify peers and
                knowledge within your enterprise to boost the success of your
                use case.
              </div>
              <div className="competition-image">
                <img src="/images/create-competition-powerhouse@2x.png" />
              </div>

              <div>
                <div container className="key-points">
                  <Grid item>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 12,
                        marginRight: 12,
                        color: "#3576A7",
                      }}
                    />
                  </Grid>
                  <Grid className="description" item lg={9}>
                    Find talented, motivated peers within your enterprise
                    willing to solve the challenge
                  </Grid>
                </div>

                <div container className="key-points">
                  <Grid item>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 12,
                        marginRight: 12,
                        color: "#3576A7",
                      }}
                    />
                  </Grid>
                  <Grid className="description" item lg={9}>
                    Benchmark all your team efforts and technology approaches
                  </Grid>
                </div>

                <div container className="key-points">
                  <Grid item>
                    <FiberManualRecordIcon
                      style={{
                        fontSize: 12,
                        marginRight: 12,
                        color: "#3576A7",
                      }}
                    />
                  </Grid>
                  <Grid className="description" item lg={9}>
                    Enterprise-wide alignment and strategy on solving the
                    challenge
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <CustomDialogActions>
          <Button
            className={classes.contactUs}
            onClick={handleContactUs}
            color="primary"
          >
            Contact Us
          </Button>
        </CustomDialogActions>
      </Dialog>
    
  );
}
