/**
 * This file contains some helper functions which are stateless (provide a pure interface)
 * and are used by the timeline component.
 */


/**
 * Differance between two dates
 *
 * @param  {Date} first Date of the first event
 * @param  {Date} second Date of the second event
 * @return {number} Differance between the two dates
 */
export const daydiff = (first, second) => Math.round((second - first));


/**
 * Takes a list of lists and zips them together (size should be the same).
 *
 * e.g. zip([['row0col0', 'row0col1', 'row0col2'], ['row1col0', 'row1col1', 'row1col2']]);
 * = [["row0col0","row1col0"], ["row0col1","row1col1"], ["row0col2","row1col2"]]
 * @param {array} rows An array (of size 2) of arrays (of equal size).
 * @return {array} An array (of size of either array in param) of arrays (of size 2)
 */
export const zip = rows => rows[0].map((_, c) => rows.map(row => row[c]));


/**
 * Determines the minimum and maximum distance between a list of dates
 * @param {array} dates The array containing all the dates
 * @return {{min: number, max: number}} The minimum and maximum distances
 */
export const dateDistanceExtremes = (dates) => {
  // determine the minimum distance among events
  const datePairs = zip([ dates.slice(0, -1), dates.slice(1) ]);
  const dateDistances = datePairs.map(([ x, y ]) => daydiff(x, y));
  // return the minimum distance between two dates but considering that all dates
  // are the same then return the provided minimum seperation.
  return {
    min: Math.min.apply(null, dateDistances),
    max: Math.max.apply(null, dateDistances)
  };
};


const dateDiffInDays = (first, second) => {
  return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
}


/**
 * Given dates and some bounds returns an array of positioning information w.r.t. some origin for
 * that set of dates.
 *
 * @param {dates} the array containing dates the dates
 * @param {number} labelWidth The width the label is going to use
 * @param {number} minEventPadding The minimum padding between events.
 * @param {number} maxEventPadding The maximum padding between events.
 * @param {number} startPadding The padding at the beginning of the timeline
 * @return {array} positioning information for dates from a given origin point
 */
// the interface for this function is pure
export const cummulativeSeperation = (dates, visibleWidth, labelWidth, minEventPadding, maxEventPadding, startPadding) => {
  
  // using dynamic programming to set up the distance from the origin of the timeline.
  const distances = new Array(dates.length);
  
  // set the distance of the first date
  distances[0] = (labelWidth / 3) + minEventPadding;
  

  const totalDays = dateDiffInDays((dates[0]), (dates[dates.length - 1])) 
  const perDayWidth = (visibleWidth - distances[0]) / totalDays;

  console.log("dateDiffInDays-cummulativeSeperation", perDayWidth, totalDays , perDayWidth * totalDays, visibleWidth )

  let adjustment = 0;

  for (let index = 1; index < distances.length; index += 1) {
    const distance = dateDiffInDays(dates[index - 1], dates[index]) * perDayWidth;
    

    console.log("DAYSDIFF::", index, dateDiffInDays(dates[index - 1], dates[index]), distance, dates[index - 1], dates[index])
  
    
    distances[index] = distances[index - 1] + distance;

    // if (distances[index] < labelWidth) {
    //   distances[index] = labelWidth + minEventPadding
    //   adjustment = labelWidth - distance

    // } else {
      // distances[index] = distances[index] - (adjustment/6);
    // }

    // console.log("dateDiffInDay: ", index,  distance, adjustment, distances)
  }


  return distances;
};
