import { Avatar, Badge, Box, Button, CircularProgress, Container, Grid, IconButton, Input, InputAdornment, Paper, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Sidebar from '../../Components/Sidebar';
import './style.css'
import Header from '../../Components/Header';
import DatasetBuilder from '../../Components/DatasetBuilder';
import SaveDataset from '../../Components/SaveDataset';
import WorldMap from '../../Components/WorldMap';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import { Alert } from '@material-ui/lab';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AddPictureIcon, LastNameIcon, SurNameIcon } from '../../Components/Icon';
import OnboardWizard from '../../Components/OnboardWizard';
import DefaultAvatar from '../../assets/avatar.png'
import UserPopup from '../../Components/UserPopup';


const useStyles = makeStyles((theme) => ({
    avatarLarge: {
        width: 72,
        height: 72,
        "& .MuiAvatar-img": {
            // objectFit: "contain",
        }
      },
}));

  const StyledBadge = withStyles((theme) => ({
      badge: {
          backgroundColor: '#bdbdbd',
          color: '#fff',
          boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
          width: '25px',
          height: '25px',
          borderRadius: '50%',
          border: '1px solid #FFFFFF',
          "& .MuiSvgIcon-root": {
              fontSize: '25px',

          }

    }
  }))(Badge);
  

const UpdateProfile = (props) => {
   
    const classes = useStyles();
    const { user, state: { dataset }, setUserUpdate } = useContext(GlobalContext);
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const profilePicRef = useRef(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fileError, setFileError] = useState(false)
    const history = useHistory()


    console.log("history", history)

    useEffect(() => {
        if (firstNameRef && firstNameRef.current) {
            firstNameRef.current.value = user.first_name;
        }
        if (lastNameRef && lastNameRef.current) {
            lastNameRef.current.value = user.last_name;
        }
        
    }, [])

    useEffect(() => {

        console.log("Test")
        if (success) {
            const datasetKey = localStorage.getItem("datasetKey");
            setTimeout(() => {
                if (datasetKey) {
                    localStorage.removeItem("datasetKey")
                    history.push({
                        pathname: `/` + (`experiments/${datasetKey}/`),
                    })
                } else {
                    history.push({
                        pathname: `/` + (`experiments/`),
                    })
                }
            }, 1000);
        }
    }, [success])

    const updateProfile = ({ first_name, last_name, profile_image }) => {
        
        setLoading(true);

        console.log(first_name, last_name, profile_image);

        const formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        if (profile_image) {
            formData.append("profile_image", profile_image);
        }
        

        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'PATCH',
            url: `${API_URL}/userinfo/`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Token ${token}`
            },
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("FILE RETURNED", data);
            setError(null);
            setSuccess(true);
            setLoading(false)
            setUserUpdate({...data })
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response.status === 404) {
                setError("error", {
                    type: "manual",
                    message: error.response.statusText,
                });

            } else if (error.response && error.response.data) {
                // setError(error.response.data)
                Object.entries(error.response.data).forEach((value) => {
                    if (["old_password", "new_password", "confirm_password"].includes(value[0])) {
                        setError(value[0], {
                            type: "manual",
                            message: value[1],
                        });
                    }
                });
                setError("error", {
                    type: "manual",
                    message: "An Error occurred.",
                });
            } else {
                setError("error", {
                    type: "manual",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
        });
    }

    const onSubmit = data => {
        console.log('profile-update-validate', data, preview)
        updateProfile({...data, profile_image: preview})

    };

    // console.log('profile-update-validate', errors);

    return (
        <Grid direction="column" container justifyContent='center' alignItems="stretch" style={{ height: 'calc(100vh - 20px)' }}>
            {/* <Header /> */}


            { localStorage.getItem("datasetKey") && success?
                <Grid container direction="row" justify="flex-start" className="profile-update-main">

                <Grid className="profile-update" container direction="column">

                        <Grid className="form" container direction="column" style={{ height: "305px", width: "616px"}}>
                            <Grid item>
                                <h2 className="title" style={{ margin: '8px 0' }}>Thank You</h2>
                            </Grid>
                            <Grid item>
                               <div className='thank-you'>Your account is ready in just a second. You will be redirected automatically.</div>
                            </Grid>
                            
                        

                    </Grid>
                    
                    
                    
                </Grid>
                
                </Grid>
                :
            
            
                <Grid container direction="row" justify="flex-start" className="profile-update-main">

                <Grid className="profile-update" container direction="column">

                    <Box padding={1} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ width: '100%', padding: '0px 32px', alignSelf: 'center'}}>
                            <h2
                                className="title"
                                style={{ alignSelf: 'center', textAlign: 'center' }}>
                                Update Profile
                            </h2>
                            

                            </Box>
                    </Box>

                    { (user && (!user.first_name || !user.last_name)) && <OnboardWizard page={"profile"} />}

                        <Grid className="form" container direction="column">
                            {user && user.is_password_changed === false ?
                                <Grid item>
                                    <Alert icon={false} severity="warning" className="sub-title" style={{ textAlign: 'left' }}>
                                    For security, you need to change the auto generated password sent to you in email.
                                    </Alert>
                                </Grid> : null
                                // <div className="sub-title">
                                //     For security, you need to change the auto generated password sent to you in email.
                                // </div>
                                
                            }

                            {
                                errors.error &&
                                <Grid item>
                                    <Alert icon={false} severity="error">asd{errors.error?.message}</Alert>
                                </Grid>
                            }

                            {
                                success &&
                                    <Grid item>
                                        <Alert
                                            icon={false}
                                            variant="filled"
                                            severity="success">Profile updated successfully.</Alert>
                                    </Grid>
                            }

                            <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                                <Grid className="profile-picture" item>
                                    <input
                                        ref={profilePicRef}
                                        // {
                                        // ...register("profile_picture")
                                        // }
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="profile-picture-file"
                                        onChange={(e) => {
                                            console.log("FILE", e.target.files[0])
                                            const file = e.target.files[0]
                                            if (file && file['type'].split('/')[0] === 'image') {
                                                console.log("FILE", file['type']);
                                            }
                                            setPreview(e.target.files[0]);
                                            // clearErrors()
                                        }}
                                    />

                                    <label htmlFor="profile-picture-file">
                                        <IconButton component="span" >
                                            <StyledBadge
                                                component="span"
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                badgeContent={<AddPictureIcon />}>
                                                <Avatar
                                                    variant="circular"
                                                    src={preview ? URL.createObjectURL(preview) : (user && user.profile_image || DefaultAvatar)}
                                                    className={classes.avatarLarge} />
                                            </StyledBadge>
                                        </IconButton>
                                    </label>
                                    {<div className='error'>{errors.profile_picture?.message}</div>}
                                </Grid>
                                <Grid item>
                                
                                    <TextField
                                        inputProps={{
                                            ...register("first_name", {
                                                required: "First Name is required",
                                                minLength: { value: 3, message: 'First Name must contain at least 3 characters.' },
                                                // pattern: { value: /[A-Za-z]{8}/, message: 'error message' }
                                            })
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SurNameIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        type={"text"}
                                        // InputLabelProps={{ shrink: true }}
                                        onChange={()=> clearErrors()}
                                        inputRef={firstNameRef}
                                        margin= 'normal'
                                        className="profile-update-name"
                                        placeholder="First Name"
                                        defaultValue={user.first_name}
                                        helperText= {<span className='error'>{errors.first_name?.message}</span>}
                                        variant="outlined"/>
                                        
                                </Grid>
                                
                                <Grid item>
                                    <TextField
                                        inputProps={{
                                            ...register("last_name", {
                                                required: "Last Name is required",
                                                minLength: { value: 3, message: 'Last Name must contain at least 3 characters.' },
                                            })
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LastNameIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={()=> clearErrors()}
                                        inputRef={lastNameRef}
                                        type={"text"}
                                        margin= 'normal'
                                        className="profile-update-name"
                                        placeholder="Last Name"
                                        defaultValue={user.last_name}
                                        helperText= {<span className='error'>{errors.last_name?.message}</span>}
                                        variant="outlined"/>
                                    
                                </Grid> 
                                <Grid item>
                                    <Button
                                        type='submit'
                                        size="large"
                                        variant="contained"
                                        className="profile-update-button"
                                        color="primary"
                                        classes={{ label: "label" }}
                                        // onClick={passwordUpdateHandle}
                                        disableElevation>
                                        {loading ? <CircularProgress size={20} color="inherit" /> : "Update"}
                                    </Button>
                                </Grid>
                                
                            </form>

                    </Grid>
                    
                    
                    
                </Grid>
                
            
                </Grid>
            }

        </Grid>
    );
}

export default UpdateProfile;