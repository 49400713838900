import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./style.css";
import { Grid } from "@material-ui/core";
import { convertDateToDDMMYY, getDateDifferenceInDays, getMidDate, numberWithSpaces } from "utils/format";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import HorizontalTimeline from "Components/HotizontalTImeline/HorizontalTimeline";
import { isDateBetween } from "utils";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: "24px",
    "& a": {
      color: "var(--primary)",
      textDecoration: "none",
    },
    "& pre": {
      fontFamily:
        "Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L , Monaco, Courier New ,Courier, monospace",
      whiteSpace: "pre-wrap",
      overflowX: "auto",
      tabWidth: 4,
      background: "rgba(0,0,0, 0.6)",
      color: "white",
      padding: 20,
      borderRadius: 5,
    },
    "& ul": {
      // margin: 0,
      // padding: 0,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "90px",
  },
  flexContainerVertical: {
    alignItems: "flex-start",
  },
}));


const OverView = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [timelineValue, setTimelineValue] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [timelineData, setTimeLineData] = useState([])
  const [isScrolled, setIsScrolled] = useState(false);


  const contentRef = useRef(null);
  const [titles, setTitles] = useState([]);
  const [activeTitle, setActiveTitle] = useState(null);



  useEffect(() => {
   const datesList = [
      {title: "Announced" , value: props.announced_date},
      {title: "Start", value: props.start_date},
      {title: "Team merger deadline", value: props?.team_merger_date},
      {title: "Final submission", value: props?.final_submission_date},
      {title: "Final Results", value: props?.end_date,}
   ]
    
    const currentDate = (new Date()).toISOString()
    
    if (isDateBetween(props.start_date, props?.end_date, (new Date()).toISOString())) {
        datesList.push({ title: "Current", value: currentDate })
    }
    
    setTimeLineData(datesList.sort((a, b) => new Date(a.value) - new Date(b.value)))


  // Track scrolling to apply blue shadow

    const handleScroll = () => {
      setIsScrolled(contentRef.current.scrollTop > 0);
    };

    const ref = contentRef.current;
    ref.addEventListener('scroll', handleScroll);
    return () => ref.removeEventListener('scroll', handleScroll);


  }, [])


    



    // Parse HTML and extract h2 titles
    useEffect(() => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(props.description, 'text/html');
      const h2Elements = Array.from(doc.querySelectorAll('h2'));
      console.log("##h2Elements", h2Elements)
      setTitles(h2Elements.map((el, index) => ({ id: `title-${index}`, text: el.innerText })));
    }, [props.description]);
  
  
  
  
  
    // Use Intersection Observer to detect visible h2 elements
    useEffect(() => {
      const observerOptions = {
        root: contentRef.current,
        rootMargin: '0% 0px -50% 0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("##entry", entry)
            setActiveTitle(entry.target.id);
          }
        });
      }, observerOptions);
  
      const h2Elements = contentRef.current.querySelectorAll('h2');
      h2Elements.forEach((el, index) => {
        el.id = `title-${index}`;
        observer.observe(el);
      });
  
      return () => observer.disconnect();
    }, [titles]);
  
  
    // Scroll to title on click
    const scrollToTitle = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    };
  
  

  const prizesList =
    props.prizes &&
    props.prizes.map((prize) => {
      let place = "1st place";
      switch (prize.place) {
        case 1:
          place = "1st place";
          break;
        case 2:
          place = "2nd place";
          break;
        case 3:
          place = "3rd place";
          break;
        default:
          place = prize.place + "th place";
          break;
      }
      return (
        <Grid container>
          <Grid xs={1} className="prize-title" item>
            {place}
          </Grid>
          <Grid xs={2} className="prize-value" item>
            € {numberWithSpaces(prize.prize_amount)}
          </Grid>
        </Grid>
      );
    });
  
  

  return (
    <>
      {/* time-line box */}

      <Box style={{
        marginTop: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        height: '95px',
      }}>

        <HorizontalTimeline
          index={timelineValue}
          indexClick={(index) => {
            setPrevious(value)
            setTimelineValue(index)
          }}
          styles={{ background: '#ffffff', foreground: '#5dd39e', outline: '#5dd39e' }}
          // linePadding={40}
          isOpenBeginning={false}
          isOpenEnding={false}
          showArrowButtons={false}
          minEventPadding={10}
          maxEventPadding={300}
          daysToGo={props?.final_submission_date}
          count={timelineData.length}
          getLabel={(data) => {
            // console.log("DATE-DATA", data)
            return convertDateToDDMMYY(data.value)
          }}
          values={timelineData } />
      </Box>
      
      <div className={`${classes.root} competition-Overview`}>

        <div className="left-bar" >

        
        <div className="inner">
        {titles.map((title) => (
          <div
            key={title.id}
            onClick={() => scrollToTitle(title.id)}
            style={{
              fontWeight: activeTitle === title.id ? 'bold' : 'normal',
              cursor: 'pointer',
              margin: '5px 0',
              height: '48px',
              lineHeight: '48px',
              fontStyle: 'italic'
            }}
          >
            {title.text}
          </div>
        ))}
          </div>

          </div>
        
        
        <div className="right-bar"
        //   style={{
        //   boxShadow: isScrolled ? '0px -3px 8px rgba(0, 123, 255, 0.5)' : 'none',
        //   transition: 'box-shadow 0.3s ease-in-out',
        // }}
        >
          <div
            className="details html-section"
            ref={contentRef}
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
        
      </div>
    </>
  );
};

export default OverView;
