import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import './style.css';
import { API_URL } from '../../../../../Context/GlobalState';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { colorGradient, LightenDarkenColor, pickHex } from '../../../../../utils';

const GRADIENT_COLOR = {
    min: {
        red: 255,
        green: 255,
        blue: 255
    },
    mid: {
        red: 121,
        green: 212,
        blue: 224
    },
    max: {
        red: 53,
        green: 118,
        blue: 167
    }
}

function IntentRow(props) {
    return props.intents.map(key => {
        const value = props.confusionMatrix[props.intent][key] && props.confusionMatrix[props.intent][key];
        const { max, min } = props.range;
        const decrease = ((max - value) / max) // formulate for percentage of decrease in value
        
        console.log("CONFUSION-PERCENTAGE", 'decrease:', decrease, value, colorGradient(GRADIENT_COLOR.max, GRADIENT_COLOR.min, decrease)); 
        // console.log("CONFUSION-DATA", y, 'decrease:', decrease, percentage, value, LightenDarkenColor('#006fa8', percentage), props.range);
        return <td
            height={96}
            // style={{ backgroundColor: value ? LightenDarkenColor(GRADIENT_COLOR.max, decrease)  : GRADIENT_COLOR.min }}
            style={{
                backgroundColor: value ? colorGradient(decrease, GRADIENT_COLOR.max, GRADIENT_COLOR.mid, GRADIENT_COLOR.min) : GRADIENT_COLOR.min,
                color: decrease < 0.66 ? '#ffffff' : '#707070'
            }}
            className='numbers' key={key}>{value}</td>
    });
}


const renderLegend = (props) => {
    const { payload } = props;
  
    return (
      <div>
        {
          payload.map((entry, index) => (
              <span
                  style={{ fontSize: "12px", lineHeight: "14px", fontWeight: "500", fontFamily: "Roboto", color: "#707070" }}
                  key={`item-${index}`}>Images</span>
          ))
        }
      </div>
    );
}
  

const ConfusionMatrix = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [code, setCode] = useState(null);
    const [confusionMatrix, setConfusionMatrix] = useState(null);
    try {
        console.log("CONFUSION",
            JSON.parse(JSON.stringify(eval('(' + props.confusionMatrix + ')')))
        )

    } catch (error) {
        console.log("CONFUSION-error", error)
    }

    console.log("ConfusionMatrix: expanded-experiment", props);
    const getCM = () => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'get',
            url: `${API_URL}/cycle/${props.experiment}/${props.cycleNo}/`,
            //url: `${API_URL}/cycle/${394}/${1}/`,
            headers: {
              'Authorization': `Token ${token}`
            }
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("COMMENT-OBJECT", data);
            //setConfusionMatrix(data)
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure you are authorized.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if(typeof props.confusionMatrix === 'string' && props.confusionMatrix !== null) {
            
            console.log("confusionMatrixTable-log-expanded", props.confusionMatrix)
            try {
                setConfusionMatrix(JSON.parse(JSON.stringify(eval('(' + props.confusionMatrix + ')'))));
            } catch (error){
                console.log(error)
            }
        }
        
        // if (props.expanded) {
        //     getCM();
        // }
        // if (experiments.length > 0 && experiments.next) {
        //     getExperiments();
        //     console.log("EXPERIMENTS-LIST-PAGE-NEXT", page);
        // }
    }, [props.expanded, props.cycleNo])


    useEffect(() => {
        console.log("confusionMatrixTable-log", props.confusionMatrix, typeof props.confusionMatrix)
        if (typeof props.confusionMatrix === 'string' && props.confusionMatrix !== null) {
            try {

                setConfusionMatrix(JSON.parse(JSON.stringify(eval('(' + props.confusionMatrix + ')'))));
            } catch (error) {
                console.log(error)
            }
            
        }
    }, []);

    const confusionMatrixTable = () => {
        console.log('################### BUILDING CONFUSION MATRIX TABLE ###################', confusionMatrix);

        let intents = confusionMatrix && confusionMatrix.cm && Object.keys(confusionMatrix.cm).reduce((list, intent) => {
            list.push(intent);
            return list
        }, []) || [];
        // intents.sort();
        let cmTableHeader = intents.map(key => <td key={key}><div><span>{key}</span></div></td>);

        console.log("confusionMatrixTable", intents, confusionMatrix)
        return (
            <Grid container>
                <Grid item>
                    <table className="confusion-matrix">
                        <tbody>
                            {intents.map((key, index) => {
                                return (
                                    <tr height={96} key={key} className={"intent-cm-row"}>
                                        {
                                            (0 === index) &&
                                            <td align='center' className='true-label' rowSpan={intents.length}>
                                                     <h4>True Label</h4>
                                                </td>
                                            
                                        }
                                        <td className={"intent-label"}>{key}</td>
                                        <IntentRow intent={key} intents={intents} confusionMatrix={confusionMatrix.cm} range={confusionMatrix && confusionMatrix.range || {}} />
                                        {
                                            (0 === index) &&
                                            <td className='graph-column' rowSpan={intents.length}>
                                                    <ResponsiveContainer width={100} height={400}>
                                                        <BarChart
                                                            width={100}
                                                            height={290}
                                                            data={confusionMatrix && [confusionMatrix.range] || []}
                                                            barGap={0}
                                                            barCategoryGap={0}
                                                            margin={{
                                                                top: 0,
                                                                right: 0,
                                                                left: 0,
                                                                bottom: 0
                                                            }}
                                                        >
                                                            <defs>
                                                                <linearGradient
                                                                id="colorGradient"
                                                                x1="0"
                                                                y1="0"
                                                                x2="0"
                                                                y2="100%"
                                                                gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop
                                                                        offset="0"
                                                                        stopColor={`rgb(${GRADIENT_COLOR.max.red}, ${GRADIENT_COLOR.max.green}, ${GRADIENT_COLOR.max.blue})`} />
                                                                    <stop
                                                                        offset="0.5"
                                                                        stopColor={`rgb(${GRADIENT_COLOR.mid.red}, ${GRADIENT_COLOR.mid.green}, ${GRADIENT_COLOR.mid.blue})`} />

                                                                    <stop
                                                                        offset="1"
                                                                        stopColor={`rgb(${GRADIENT_COLOR.min.red}, ${GRADIENT_COLOR.min.green}, ${GRADIENT_COLOR.min.blue})`} />
                                                                </linearGradient>
                                                            </defs>
                                                        {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                        {/* <XAxis dataKey="max" /> */}
                                                            <YAxis
                                                                axisLine={false}
                                                                tickLine={false}
                                                                allowDataOverflow={false}
                                                                domain={[0, 'dataMax']}
                                                                padding={{ left: 0, right: 0 }}
                                                                interval="preserveStartEnd"
                                                                orientation='right'
                                                                tick={{
                                                                    fontFamily: 'Roboto',
                                                                    fontSize: '12px',
                                                                    lineHeight: '14px',
                                                                    fontStyle: 'italic',
                                                                    color: '#707070',
                                                                    paddingLeft: '4px'
                                                                }}
                                                                // tickFormatter={(value) => {
                                                                //     if(value === 0) return "No"

                                                                // }}
                                                            />
                                                            <Legend
                                                                verticalAlign="top"
                                                                height={40}
                                                                align='center'
                                                                content={renderLegend}
                                                            />
                                                        <Bar className='bar-grad' dataKey="max" fill="url(#colorGradient)" />
                                                        
                                                        </BarChart>
                                                    </ResponsiveContainer>

                                                </td>
                                        }
                                    </tr>
                                )
                            })}
                            <tr className={"intent-header"}>
                                <td></td><td></td>{cmTableHeader}
                            </tr>

                            <tr>
                                <td className='predicted-label' align='center' colSpan={intents.length + 3}>
                                    <h4 style={{ marginBottom: 0, textAlign: 'center' }}>Predicted Label</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                
                
            </Grid>
            
        )
    };

    return (
        <div className={`confusion-matrix-container ${props.expanded ? 'expand' : ''}`}>
            <Box mb={4}></Box>
            {props.expanded && confusionMatrix &&
                
                <Grid container alignItems="center">
                    <Grid xs={8} item>
                        {confusionMatrixTable()}
                    </Grid>
                    
                </Grid>
            }
            {
                (props.expanded && confusionMatrix === null && !loading) &&
                    <Grid container justifyContent='center'>
                        <h3>Confusion matrix not ready yet</h3>
                    </Grid>
                    
            }
            {
                loading && <Grid container justify="center" alignItems="center"><CircularProgress /></Grid>
            }
        </div>
    );
}

export default ConfusionMatrix;