import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputBase,
  Paper,
  Portal,
  Snackbar,
} from "@material-ui/core";
import { HtmlTooltip } from "Components/Sustainability";
import React, { useContext, useState } from "react";
import { numberWithSpaces } from "utils/format";
import axios from "axios";
import { API_URL, GlobalContext } from "Context/GlobalState";
import "./style.css";
import { Alert } from "@material-ui/lab";
import { NotificationCheckIcon } from "Components/Icon";

const Inference = (props) => {
  const { user } = useContext(GlobalContext);
  const [cycle, setCycle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inferenceFailed, setInferenceFailed] = useState(null);
  const [inferenceSuccess, setInferenceSuccess] = useState(false);
  const { id, userId, test_metrics, category } = props.experiment;



  const onInputChange = (e) => {
    console.log(e.target.value);
    setCycle(e.target.value);
  };

  const onInputKeyPress = (e) => {
    if (e.keyCode === 13) {
      console.log("onInputKeyPress", e, e.target.value);
      setCycle(e.target.value);
    }
    
    // setCycle(e.target.value);
  }



  const showInferenceFailedMessage = (message) => {
    setInferenceFailed(message);
    setTimeout(() => {
      setInferenceFailed(null);
    }, 3000);
  };

  const showInferenceSuccessMessage = (e) => {
    setInferenceSuccess(true);
    setTimeout(() => {
      setInferenceSuccess(false);
    }, 3000);
  };

  const handleStartInference = (event) => {
    const token = localStorage.getItem("_key_usr_tkn");
    setLoading(true)
    const config = {
      method: "POST",
      url: `${API_URL}/submit/${id}/${cycle}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        showInferenceSuccessMessage()
        setLoading(false)
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response && error.response.status===400) {
          showInferenceFailedMessage(error.response?.data?.message)
          console.log("handleStartInference-data", error.response.data.message)
        } else if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false)
      });
  };

  const listTestMetrics =
    test_metrics &&
    test_metrics.map((item) => {
      /*
        accuracy: 0.10043668001890182
        cycle: 1
        f1_score: 1
        inferred_on: "test_submit"
        score: { 
          loss: 1.0955857038497925
          precision: 1
          recall: 1,
        }
        inference_captured: false
        */
      return (
        <Grid container className="inference-cycle">
          <HtmlTooltip
            arrow
            title={
              <div className="sustain-tooltip">
                <div className="tooltip-sustain-title">Cycle</div>
                <div className="tooltip-sustain-sub-title">
                  cycle {item?.cycle} metrices.
                </div>
              </div>
            }
          >
            <Grid item className="item">
              <span
                style={{ textAlign: "left", marginLeft: "0px" }}
                className="inference-text"
              >
                c={item?.cycle}
              </span>
            </Grid>
          </HtmlTooltip>

          <HtmlTooltip
            arrow
            title={
              <div className="sustain-tooltip">
                <div className="tooltip-sustain-title">{ category === "keypoint_detection" ? "pck" : "accuracy"}</div>
                <div className="tooltip-sustain-sub-title">{ category === "keypoint_detection" ? "pck" : "accuracy"}</div>
              </div>
            }
          >
            <Grid item className="item">
              <span className="head">{ category === "keypoint_detection" ? "pck" : "accuracy"}</span>
              <span className="inference-text">
                { item?.inference_captured ?
                  (parseFloat(item?.accuracy).toFixed(4))
                  : <>-<span style={{ color: "white"}}>-0.000</span></>
                }
              </span>
            </Grid>
          </HtmlTooltip>

          <HtmlTooltip
            arrow
            title={
              <div className="sustain-tooltip">
                <div className="tooltip-sustain-title">loss</div>
                <div className="tooltip-sustain-sub-title">loss</div>
              </div>
            }
          >
            <Grid item className="item">
              <span className="head">{"loss"}</span>
              <span className="inference-text">
                {
                  item?.inference_captured ?
                    (parseFloat(item?.loss).toFixed(4))
                    : <>-<span style={{ color: "white"}}>-0.000</span></>
                }
              </span>
            </Grid>
          </HtmlTooltip>

          <HtmlTooltip
            arrow
            title={
              <div className="sustain-tooltip">
                <div className="tooltip-sustain-title">F1 score</div>
                <div className="tooltip-sustain-sub-title">
                  F1 score is the harmonic mean of precision and recall.
                </div>
                <div className="tooltip-sustain-sub-title">
                  Value range [0,1], where 1 is the most performant value.
                </div>
              </div>
            }
          >
            <Grid item className="item">
              <span className="head">{"F1 score"}</span>
              <span className="inference-text">
                {
                  item?.inference_captured ?
                    (parseFloat(item?.scores?.f1_score).toFixed(4))
                    : <>-<span style={{ color: "white"}}>-0.000</span></>
                }
              </span>
            </Grid>
          </HtmlTooltip>

          <HtmlTooltip
            arrow
            title={
              <div className="sustain-tooltip">
                <div className="tooltip-sustain-title">precision</div>
                <div className="tooltip-sustain-sub-title">
                  precision is the number of true positive results divided by
                  the number of all positive results, including those not
                  identified correctly.
                </div>
                <div className="tooltip-sustain-sub-title">
                  Value range [0,1], where 1 is the most performant value.
                </div>
              </div>
            }
          >
            <Grid item className="item">
              <span className="head">{"precision"}</span>
              <span className="inference-text">
                {
                  item?.inference_captured ?
                    (parseFloat(item?.scores?.precision).toFixed(4))
                    : <>-<span style={{ color: "white"}}>-0.000</span></>
                }
              </span>
            </Grid>
          </HtmlTooltip>

          <HtmlTooltip
            arrow
            title={
              <div className="sustain-tooltip">
                <div className="tooltip-sustain-title">recall</div>
                <div className="tooltip-sustain-sub-title">
                  recall is the number of true positive results divided by the
                  number of all samples that should have been identified as
                  positive.
                </div>
                <div className="tooltip-sustain-sub-title">
                  Value range [0,1], where 1 is the most performant value.
                </div>
              </div>
            }
          >
            <Grid item className="item">
              <span className="head">{"recall"}</span>
              <span className="inference-text">
                {
                  item?.inference_captured ?
                    (parseFloat(item?.scores?.recall).toFixed(4))
                    :<>-<span style={{ color: "white"}}>-0.000</span></>
                }
              </span>
            </Grid>
          </HtmlTooltip>
        </Grid>
      );
    });

  // console.log("test_metrics", test_metrics);

  return (
    <>
      <Grid container className="inference-container" item>
        <Grid container className="cycle-head">
          <Grid item>
            <span className="selected">Test</span>
          </Grid>
          {
            <Grid
              className="input-container"
              style={{ textAlign: "center" }}
              item
            >
              <span>Submit for inference at c =</span>
            </Grid>
          }

          {
            <Grid item>
              <HtmlTooltip
                title={
                  <div className="sustain-tooltip">
                    <div className="tooltip-sustain-title">Cycle</div>
                    <div className="tooltip-sustain-sub-title">
                      Please enter a cycle number.
                    </div>
                  </div>
                }
              >
                <Box className="cycle-input-box">
                  <Paper
                    variant="outlined"
                    className="cycle-input"
                    style={{
                      borderColor: cycle.length < 1 ? "#C7D2D5" : "#3576a7",
                    }}
                    elevation={0}
                  >
                    <InputBase
                      onChange={onInputChange}
                      onKeyDown={onInputKeyPress}
                      placeholder="-"
                      inputProps={{ min: 1, type: "number", "aria-label": "-" }}
                      disabled={userId?.id !== user.user_id}
                    />

                    <Button
                      onClick={handleStartInference}
                      className="cycle-btn"
                      disabled={cycle.length < 1 || userId?.id !== user.user_id}
                    >
                      { loading ? <CircularProgress style={{ color: "#fff" }} size={12} /> :`Submit`}
                    </Button>
                  </Paper>
                </Box>
              </HtmlTooltip>
            </Grid>
          }

          <Grid style={{ marginLeft: "24px", background: "#0ff" }} xs={10} item>
            <div className="line" />
          </Grid>
        </Grid>

        {listTestMetrics}
      </Grid>
      <Portal>
      {inferenceFailed && (
          <Snackbar
            open={(!!inferenceFailed)}
            autoHideDuration={4000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="warning"
            >
              {inferenceFailed}
            </Alert>
          </Snackbar>
        )}

        { inferenceSuccess && 
          <Snackbar
          open={inferenceSuccess}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            variant="filled"
            severity="success"
            iconMapping={{
              success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
            }}
          >
            {`Inference submitted successfully.`}
          </Alert>
        </Snackbar>
        }

      </Portal>
    </>
    
  );
};

export default React.memo(Inference);
