import { Avatar, Box, Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { withStyles } from '@material-ui/styles';




const OnboardWizard = ({ page }) => {
    
    console.log("UserPopup", 'user')
   
    return (
        <ul className='onboard-wizard'>
            <li className={ page === "login" ? "active" : ''}>1</li>
            <li className={ page === "password" ? "active" : ''}>2</li>
            <li className={ page === "profile" ? "active" : ''}>3</li>
        </ul>
        
    );
}

export default OnboardWizard;