import { Avatar, Box, Button, CircularProgress, Collapse, Grid, makeStyles, Portal, Snackbar, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import React, { useEffect, useRef, useState } from 'react';

import './style.css';
import companyImg from '../../../assets/competition_company@2x.png'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/styles';
import { API_URL } from '../../../Context/GlobalState';
import axios from 'axios';
import { Alert, Autocomplete } from '@material-ui/lab';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { isEmail } from '../../../utils';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 24,
      borderRadius: 4,
    },
    colorPrimary: {
      backgroundColor: "rgba(112, 112, 112, 1)",
    },
    bar: {
        borderRadius: 4,
        borderBottomRightRadius: 1,
        borderTopRightRadius: 1,
        backgroundColor: 'rgba(75, 189, 204, 1)',
    },
  }))(LinearProgress);

const CustomAutocomplete = withStyles({
    tag: {
        backgroundColor: "var(--primary)",
        height: 24,
        fontSize: 12,
        position: "relative",
        zIndex: 0,
        "& .MuiChip-label": {
            color: "#fff"
        },
        "& .MuiChip-deleteIcon": {
            color: "#fff",
            height: 18,
            width: 18,
        },
        "&:after": {
            content: '""',
            right: 8,
            top: 6,
            height: 12,
            width: 12,
            position: "absolute",
            backgroundColor: "#333333",
            zIndex: -1
        },
    },
    listbox: {
    },
    option: {
        fontSize: 10,
    },
    noOptions: {
        fontSize: 10,
    },
    loading: {
        
    }
})(Autocomplete);

const InviteButton = withStyles((theme) => ({
    root: {
    
        boxShadow: 'none',
        textTransform: 'none',
        wordBreak: 'break-all',
        fontSize: 10,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.3,
        fontWeight: 500,
        textAlign: 'left',
      
      '&:hover': {
        boxShadow: 'none',
        textTransform: 'none',
      },
    },
  }))(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: '1px solid #e6e6e6'
    },
    input: {
        marginRight: '11px',
        flex: 1,
        fontSize: 12,
        backgroundColor: 'white',
        width: '149px',
        height: '32px',
        border: '1px solid #E6E6E6',
        borderRadius: '5px',
        padding: '3px 7px'
    },
    inviteButton: {
        padding: 4,
        minWidth: '65px',
        backgroundColor: 'var(--primary)',
        color: 'white',
        fontSize: 10,
        textTransform: 'capitalize',
        '&.Mui-disabled': {
            opacity: 0.5,
            color: 'white',
        },
        '&:hover': {
            opacity: 0.8,
            backgroundColor: 'var(--primary)',
        },
    },
    divider: {
      height: 20,
      margin: 4,
    },
  }));

let cancelSearchToken, selectedUsers = new Set();

const TeamPopup = ({ title, users, host, owner, pending, accessType, teamId, datasetId, datasetKey, isCollaboration }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [enableInvite, setEnableInvite] = useState(false);
    const inputRef = useRef(null)
    const classes = useStyles();


    console.log("DATASET-TeamPopup", users, host, accessType, "TEAM ID", teamId, datasetId, pending, isCollaboration)

    const getSearchResults = (e, value) => {
      
        // only send request if the input is a valid email
        // this might change in future if we want to search with name
        if (!isEmail(e.target.value)) {
          return 
        }
        console.log("USER-SEARCH", e.target.value, value);
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        
         //Check if there are any previous pending requests
        if (typeof cancelSearchToken != typeof undefined) {
          cancelSearchToken.cancel("canceled")
        }
        // Save the cancel token for the current request
        cancelSearchToken = axios.CancelToken.source()
    
        const config = {
            method: 'GET',
            url: `${API_URL}/search-user/${e.target.value}/`,
            data: { claps: 1 },
            headers: {
                'Authorization': `Token ${token}`
          },
          cancelToken: cancelSearchToken.token //Pass the cancel token to the current request
        };
    
        axios(config).then((res) => {
            const data = res.data
            console.log("USER SUMMARY", data)
            setOptions(data);
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.message);
            if (error.response) {
                setError("Please make sure you are logged in.")
            } 
          // the loadding continue if the the request is canceled due to user typing.
          if (error.message !== 'canceled') {
            setLoading(false)
            setError(null)
          }
        })
    }


    const setShareDataset = (email) => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'POST',
            url: `${API_URL}/invitations/invite/`,
            data: { email: email, datasets: [datasetId] },
            headers: {
                'Authorization': `Token ${token}`
          }
        };
    
        axios(config).then((res) => {
          console.log("USER SUMMARY", res)
          const data = res.data;
            setLoading(false);
            setError(null);
            setOpen(true)
        //   closeCallback(true);
        }).catch((error) => {
            console.log('setShareDataset-CATCH-ERROR', error.response);
            // const error = []

            if (error.response && error.response.status === 400) {
                setError(error.response?.data?.non_field_errors)
            } else if (error.response && error.response.status === 500) {
                setError(["Something went wrong on our end. We are working hard to fix it as soon as possible."])
            } else {
              setError(["Make sure you are connected to the internet."])
            }
            
          // the loadding continue if the the request is canceled due to user typing.
          if (error.message !== 'canceled') {
            setLoading(false)
          }
        })
    }
    


    const setTeamInvite = (data) => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')

        const key = isEmail(data) ? "email" : "merger_team";

        const config = {
            method: 'POST',
            url: `${API_URL}/invitations/team-invite/`,
            data: { [key]: data, "team": teamId },
            headers: {
                'Authorization': `Token ${token}`
          }
        };
    
        axios(config).then((res) => {
          console.log("USER SUMMARY", res)
          const data = res.data;
            setLoading(false);
            setError(null);
            setOpen(true)
        //   closeCallback(true);
        }).catch((error) => {
            console.log('setShareDataset-CATCH-ERROR', error.response);
            // const error = []

            if (error.response && error.response.status === 400) {
                setError(error.response?.data?.non_field_errors)
            } else if (error.response && error.response.status === 500) {
                setError(["Something went wrong on our end. We are working hard to fix it as soon as possible."])
            } else {
              setError(["Make sure you are connected to the internet."])
            }
            
          // the loadding continue if the the request is canceled due to user typing.
          if (error.message !== 'canceled') {
            setLoading(false)
          }
        })
    }
    


    const handleInvite = () => {
        const data = inputRef.current.value;
        console.log(data);
        if (isCollaboration) {
            setTeamInvite(data)
        } else {
            if (isEmail(data)) {
                setShareDataset(data);
            }
        }
        
    }

    const handleKeyDown = (e) => {
        console.log(e.keyCode)
        if (e.keyCode === 13) {
            handleInvite()
        }
    }

    const onInputChange = (e) => {
        console.log(e.target.value)
        if (isCollaboration) {
            setEnableInvite(e.target.value.length > 2)
        } else {
            setEnableInvite(isEmail(e.target.value))
        }
        
    }

    console.log(error)
    
    return (
        <div className='team-popup'>
            
            <div style={{ color: 'var(--textPrimary)', fontSize: 12, marginLeft: 4, marginBottom: 16 }}>
                { title || `Invite to dataset`}
            </div>
              
            <Grid container direction='row'>
                
                <Grid item>
                    <InputBase
                        title={isCollaboration ? `Enter a valid email or team name` : `Enter a valid email address`}
                        onChange={onInputChange}
                        onKeyDown={handleKeyDown}
                        inputRef={inputRef}
                        className={classes.input}
                        placeholder={ isCollaboration ? `Enter a valid email or team name` : `Enter a valid email address`}
                        inputProps={{ 'aria-label': isCollaboration ? `Enter a valid email or team name` : `Enter a valid email address` }}
                    />
                </Grid>
                
                <Grid className='gradient-button' style={{ minWidth: '65px' }} item>
                    <Button
                        disabled={(!enableInvite) || (loading)}
                        onClick={handleInvite}
                        color="secondary"
                        style={{ minWidth: '65px' }}
                        // className={classes.inviteButton}
                        aria-label="directions">
                        {loading ? <CircularProgress color="inherit" size={12} /> : "Invite"}
                    </Button>
               </Grid>
                
                
                <Box mt={1} className={'inner-message'}>
                    <Collapse in={error !== null}>
                        <Alert
                            severity="error"
                            icon={false}
                            // action={
                            //     <IconButton size="small" onClick={() => { setError(null) }}>
                            //         <CloseIcon fontSize="inherit" />
                            //     </IconButton>}
                        >
                            {error && error.length > 0 && error.map(str => <div style={{ fontSize: 12 }}>* {str}</div>)}
                        </Alert>
                    </Collapse>

                    <Collapse in={open}>
                        <Alert icon={false} variant="filled" severity="success">User invited successfully.</Alert>
                    </Collapse>
                </Box>
            </Grid>

            <Box mt={2} className="team-header">
                <Grid container justify="space-between">
                    <Grid item>Users</Grid>
                    {/* <Grid item>{ users.length }</Grid> */}
                </Grid>
            </Box>

            <Box mt={1} className="teams-list">
                {
                    isCollaboration && 
                        
                            <Grid container className="team-row" key={host.id}>
                                {/* <Grid xs={1} item>1</Grid> */}
                                <Grid item>
                                    <Avatar variant="circular" className="avatar-small" alt={host.name} src={`${host.company_logo}`} />
                                </Grid>
                                <Grid xs={7} item className="team-name">{host.name}</Grid>
                                <Grid xs={2} item className="team-member">Host</Grid>
                            </Grid>
                
                }
                {
                    users && users.map(user => {
                        // console.log(user.id, owner.id)
                        return (
                            <Grid container className="team-row" key={user.id}>
                                {/* <Grid xs={1} item>1</Grid> */}
                                <Grid item>
                                    <Avatar variant="circular" className="avatar-small" alt={user.first_name} src={`${user.profile_image}`} />
                                </Grid>
                                <Grid xs={7} item className="team-name">{user.first_name ? `${user.first_name} ${user.last_name}` : user.username}</Grid>
                                <Grid xs={2} item className="team-member">
                                    { user.id === owner?.id ? "Owner" : "Member" }
                                </Grid>
                            </Grid>
                        )
                    })
                }

                {
                    pending && pending.map(email => {
                        return (
                            <Grid container className="team-row" key={email}>
                                {/* <Grid xs={1} item>1</Grid> */}
                                <Grid item>
                                    <Avatar variant="circular" className="avatar-small">{email.charAt(0).toUpperCase()}</Avatar>
                                </Grid>
                                <Grid xs={6} item className="team-name">{email}</Grid>
                                <Grid xs={3} item className="team-member">Pending</Grid>
                            </Grid>
                        )
                    })
                }
            </Box>
        </div>
    );
}

export default TeamPopup;