import React from 'react';
import ReactDOM from 'react-dom';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { reactPlugin } from './AppInsights';




// replace console.* for disable log on production
if (process?.env?.REACT_APP_MY_ENVIRONMENT === 'production') {
  const noop = () => {}
  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ].forEach((method) => {
    window.console[method] = noop
  })
}


ReactDOM.render(
  <React.StrictMode>
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong. We are working hard to fix it. Please bear with us.</h1>}
      appInsights={reactPlugin}>
      <App />
    </AppInsightsErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
