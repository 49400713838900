import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  GridList,
  IconButton,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  Portal,
  Select,
  Slider,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import {
  Defects,
  defectStandards,
  tubeVoltage,
  machineParts,
  material,
} from "../../utils";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { spacing } from "@material-ui/system";
import CustomCheckBox from "../CustomCheckBox";
import DatasetExampleImages from "./DatasetExampleImages";
import TotalClassDistribution from "./TotalClassDistribution";
import TotalFigures from "./TotalFigures";
import { GlobalContext } from "../../Context/GlobalState";
import { Alert } from "@material-ui/lab";
import TrainingParameters from "./TrainingParameters";
import { NotificationCheckIcon } from "../Icon";
import RelativeClassDistribution from "./RelativeClassDistribution";
import TotalClassPerImage from "./TotalClassPerImage";
import RelativeClassPerImage from "./RelativeClassPerImage";
import DatasetDescription from "./DatasetDescription";
import TypeDialog from "./TypeDialog";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectType: {
    "& .MuiSelect-outlined": {
      // padding: "2px",
      paddingBottom: "0px",
      paddingTop: "0px",
      paddingLeft: "0px",
      fontSize: "12px",
      color: "#3576a7",
      textTransform: "capitalize",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));

const SaveDataset = (props) => {
  const {
    dataset,
    dataset: { count, groups, loading, saved },
  } = props;
  const {
    state: {
      dataset: { dataset_type },
      datasetOptions,
    },
    saveDataset,
  } = useContext(GlobalContext);
  const [saving, setSaving] = useState(false);
  const [savedDataset, setSavedDataset] = useState(false);
  const [dataSetName, setDataSetName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (saved) {
      setSaving(false);
    }
    setSavedDataset(saved);
  }, [saved]);

  const onInputChange = (event) => {
    console.log(event.target.value);
    setDataSetName(event.target.value);
  };

  const saveDatasetHandler = (accessType) => {
    console.log("accessType", accessType);
    setOpenDialog(false);
    if (!saving) {
      const imageSamples = JSON.stringify([
        ...dataset.data_samples.slice(0, 5),
        ...dataset.data_samples.slice(
          dataset.data_samples.length - 5,
          dataset.list.length
        ),
      ]);
      saveDataset(dataSetName, imageSamples, dataset_type, accessType);
    }
    setSaving(true);
  };

  const handleOpenClick = (data) => {
    setOpenDialog(data);
    console.log(data);
  };



  console.log('accessType', dataset);

  return !loading ? (
    <>
      {count && (
        <Box paddingBottom={8}>
          <Grid
            spacing={2}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className="save-dataset-box"
          >
            <DatasetExampleImages isSaving={props.isSaving} dataset={dataset} />
            <DatasetDescription dataset={dataset} />

            <TotalClassDistribution dataset={dataset} />
            <RelativeClassDistribution dataset={dataset} />
            <TotalFigures isSaving={props.isSaving} dataset={dataset} />

            <TotalClassPerImage dataset={dataset} />
            <RelativeClassPerImage dataset={dataset} />
            <TrainingParameters dataset={dataset} />
          </Grid>

          {props.isSaving ? (
            <Box className="save-dataset-input-box">
              <Paper
                variant="outlined"
                className="save-dataset-input"
                elevation={0}
              >
                <InputBase
                  onChange={onInputChange}
                  placeholder="Dataset name* (minimum 5) "
                  inputProps={{ "aria-label": "Dataset name* (min 5) " }}
                />

                <Button
                  // onClick={saveDatasetHandler}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  className="save-dataset-btn"
                  disabled={dataSetName.length < 5}
                >
                  {!dataset.saved && saving ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Save dataset"
                  )}
                </Button>

                <TypeDialog
                  handleOpen={handleOpenClick}
                  handleSelection={saveDatasetHandler}
                  open={openDialog}
                />
              </Paper>
            </Box>
          ) : null}
        </Box>
      )}
      {dataset.saved && (
        <Portal>
          <Snackbar
            open={savedDataset}
            disableWindowBlurListener
            autoHideDuration={6000}
            onClose={() => setSavedDataset(false)}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              variant="filled"
              severity="success"
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
            >
              Dataset titled <b>{dataset?.saved?.title}</b> having ID{" "}
              <b>{dataset?.saved?.dataset_key}</b> saved successfully
            </Alert>
          </Snackbar>
        </Portal>
      )}
    </>
  ) : (
    <Grid container direction="row" justify="center" alignItems="center">
      <CircularProgress />
      <div className="bold-400-12 ml-4">loading...</div>
    </Grid>
  );
};

export default SaveDataset;
