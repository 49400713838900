import { Box, Button, Checkbox, CircularProgress, Collapse, FormControlLabel, Grid, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

import './style.css';
import { spacing } from '@material-ui/system';
import { GlobalContext } from '../../Context/GlobalState';
import axios from 'axios';
import LoginHeader from '../../Components/LoginHeader';
import Footer from '../../Components/Footer';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useLocation, useHistory, Link } from 'react-router-dom';
import OnboardWizard from '../../Components/OnboardWizard';
import { EmailIcon, PasswordHiddenIcon, PasswordIcon, PasswordShowIcon } from '../../Components/Icon';
import { CheckBox } from '@material-ui/icons';

const Login = (props) => {
    const { state: { error }, sidebar, user, login, network, getUserInfo } = useContext(GlobalContext);
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const location = useLocation()
    let history = useHistory();
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const consentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [relogin, setRelogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const appInsights = useAppInsightsContext();
    const trackRelogin = useTrackEvent(appInsights, "Relogin", { "from": location });
    const trackLogin = useTrackEvent(appInsights, "Login", { });
    
    

    error && console.log("ERROR", Object.values(error))
    console.log("first_time", location.state?.first_time )

    console.log("Location", `/` + (location.state?.datasetKey && `experiments/${location.state?.datasetKey}/`));

    console.log("Location-LOgin", location?.state?.redirect_to )

    const onSubmit = data => {
        // console.log('Login-validate', data, consentRef.current.checked)
        setLoading(true);
        login({
            username: data.email, password: data.password
        })
        trackLogin({"email": data.email })
        // signupUser(data)

    };

    useEffect(() => {
        const token = localStorage.getItem('_key_usr_tkn')
        const datasetKey = location.state?.datasetKey ? location.state?.datasetKey : localStorage.getItem("datasetKey")

        if (user) {
            if (location?.state?.redirect_to) {
                history.push({
                    pathname: location?.state?.redirect_to,
                })
            } else {
                history.push({
                    pathname: `/` + (datasetKey && `experiments/${datasetKey || ""}`),
                })
            }
        }
        if (!user && token) {
            setRelogin(true);
            getUserInfo();
            trackRelogin()
        }

    }, [user]);


    useEffect(() => {
        if (error) {
            setLoading(false)
            setRelogin(false)
        }
    }, [error]);

    const displayErrorMessages = error && Object.values(error).map((value) => <div>{value}</div>);

    return (
        <Box>
            <LoginHeader />
            <Box className="login-main">
                
                <div className="login">
                { relogin ?
                        <CircularProgress />
                        :
                        <>
                            {/* {location.state?.first_time && <OnboardWizard page={"login"} />} */}

                            <Grid component={"form"} onSubmit={handleSubmit(onSubmit)} className="form" container direction="column">
                                <Grid item>
                                    <h3>Sign in to tracebloc</h3>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        inputRef={usernameRef}
                                        inputProps={{
                                            ...register('email', {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Please enter a valid email address.',
                                                },
                                            })
                                        }}
                                        onChange={() => clearErrors()}
                                        fullWidth={true}
                                        margin= 'normal'
                                        className="login-username"
                                        placeholder="Email address"
                                        variant="outlined"
                                        // helperText={<span className='error'>{errors.email?.message}</span>}
                                        style={{ border: errors.email ? "1px solid #E31010" : null}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon style={{ marginRight: '8px' }} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    { errors.email && <div className='error-message'>{errors.email?.message}</div>}
                                </Grid>
                                <Grid item>
                                    <TextField
                                         inputProps={{
                                            ...register("password", {
                                                required: "Password is required",
                                            })
                                        }}
                                        inputRef={passwordRef}
                                        type={showPassword ? "text" : "password"}
                                        fullWidth={true}
                                        margin= 'normal'
                                        className="login-password"
                                        placeholder="Password"
                                        variant="outlined"
                                        // helperText= {<span className='error'>{errors.password?.message}</span>}
                                        style={{ border: errors.password ? "1px solid #E31010" : null}}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PasswordIcon style={{ marginRight: '8px' }} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? <PasswordShowIcon /> : <PasswordHiddenIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }} />
                                    {errors.password && <div className='error-message'>{errors.password?.message}</div>}

                                    { error && <div className='error-message'>{displayErrorMessages}</div> }
                                </Grid>

                                <Grid item>
                                    <Link to="/forgot-password/">Forgot your password?</Link>
                                </Grid>

                                <Grid item>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        className="login-button"
                                        color="primary"
                                        classes={{ label: "label" }}
                                        disableElevation>
                                        {loading ? <CircularProgress size={35} color="inherit" /> : "Sign in"}
                                </Button>

                                </Grid>

                                {/* <Grid item>
                                    <FormControlLabel
                                    // {...restProps}
                                        style={{ alignItems: "flex-start", margin: "20px 0px 0px 0px", color: "#707070" }}
                                        label={"Would you like to receive email updates on the latest enterprise code competitions, product updates, announcements, and more?"}
                                        control={
                                            <Checkbox
                                                inputRef={consentRef}
                                                color="default"
                                                checkedIcon={<span style={{ border: '1px solid #3576a7', padding: '1px' }}><span className='custom-checked-icon'></span></span>}
                                                icon={<span style={{ border: '1px solid #e6e6e6', padding: '1px' }}><span className='custom-unchecked-icon' /></span>}
                                                classes={{ root: `custom-checkbox-unchecked`, checked: 'custom-checkbox-checked' }}
                                            />
                                        }
                                        labelPlacement="end"
                                        // classes={{ root: props.className ? props.className : 'custom-checkbox', label: 'bold-400-12'}}
                                    />

                                </Grid> */}
                                
                            </Grid>
                        </>
                    }
                    
                    <div className='terms-of-service'>
                       By creating an account, you agree to the <a href="https://www.tracebloc.io/terms">Terms of Service</a>. For more information about tracebloc’s privacy practices, see the <a href="https://www.tracebloc.io/privacy">tracebloc Privacy Statement</a>. We’ll occasionally send you account-related emails.
                    </div>
                </div>
            </Box>

            {/* <Footer /> */}
        </Box>
    );
}

export default Login;