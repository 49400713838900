import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Avatar, Box, Button, Collapse, Grid, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { API_URL, GlobalContext } from '../../../Context/GlobalState';
import { withStyles } from '@material-ui/styles';
import { Alert, useTabContext } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useContext } from 'react';


let cancelSearchToken, selectedUsers = new Set();


const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "var(--primary)",
    height: 24,
    fontSize: 12,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#fff"
    },
    "& .MuiChip-deleteIcon": {
      color: "#fff",
      height: 18,
      width: 18,
    },
    "&:after": {
      content: '""',
      right: 8,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "#333333",
      zIndex: -1
    }
  }
})(Autocomplete);

const ShareDialog = ({ title, dataset, datasetKey, show, closeCallback }) => {

  const { user } = useContext(GlobalContext);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  //const loading = open && options.length === 0;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getSearchResults = (e, value) => {
      
    // if (e.target.value.length < 3) {
    //   return 
    // }
    console.log("USER-SEARCH", e.target.value, value);
    setLoading(true);
    const token = localStorage.getItem('_key_usr_tkn')
    
     //Check if there are any previous pending requests
    if (typeof cancelSearchToken != typeof undefined) {
      cancelSearchToken.cancel("canceled")
    }
    // Save the cancel token for the current request
    cancelSearchToken = axios.CancelToken.source()

    const config = {
        method: 'GET',
        url: `${API_URL}/search-user/${e.target.value}/`,
        data: { claps: 1 },
        headers: {
            'Authorization': `Token ${token}`
      },
      cancelToken: cancelSearchToken.token //Pass the cancel token to the current request
    };

    axios(config).then((res) => {
        const data = res.data
        console.log("USER SUMMARY", data)
        setOptions(data);
        setLoading(false)
        setError(null)
    }).catch((error) => {
        console.log('CATCH', error.message);
        if (error.response) {
            setError("Please make sure you are logged in.")
        } 
      // the loadding continue if the the request is canceled due to user typing.
      if (error.message !== 'canceled') {
        setLoading(false)
        setError(null)
      }
        
    })
  }
    useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }
        // (async () => {

        //     //const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
        //     //await sleep(1e3); // For demo purposes.
        //     //const countries = await response.json();
        //     const users = autoData
        //   if (active) {
        //     setOptions([...users]);
        //   }
        // })();
    
        return () => {
          active = false;
        };
      }, [loading]);
    
      // useEffect(() => {
      //   if (!open) {
      //     setOptions([]);
      //   }
      // }, [open]);


      const setShareDataset = (users) => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'GET',
            url: `${API_URL}/dataset/${datasetKey}/share/?mit=${users}&type=users`,
            data: { claps: 1 },
            headers: {
                'Authorization': `Token ${token}`
          }
        };
    
        axios(config).then((res) => {
          console.log("USER SUMMARY", res)  
          const data = res.data;
          setLoading(false);
          setError(null);
          closeCallback(true);
        }).catch((error) => {
          //console.log('CATCH-ERROR', error);
          if (error.response) {
                setError(error.response.data.error)
          } else {
              setError("Make sure you are connected to the internet.")
          }
          // the loadding continue if the the request is canceled due to user typing.
          if (error.message !== 'canceled') {
            setLoading(false)
          }
        })
      }

    const handleShare = (e) => {
      console.log("Share clicked", Array.from(selectedUsers).toString());
      // console.log("ME", user, "DATASET", dataset)
      setShareDataset(Array.from(selectedUsers).toString());
    }

  return (

    <Dialog open={show} onClose={()=> closeCallback(false)} aria-labelledby="form-dialog-title">

      {/* <DialogTitle id="form-dialog-title">Share {title} having ID {datasetKey}</DialogTitle> */}
      <DialogContent>
        
        <DialogContentText id="form-dialog-title">
          <span className='grey-italic-text'>Share {title} having ID {datasetKey}</span>
        </DialogContentText>
        {/* <DialogContentText>Please select the users you want to share the dataset with. You can search by name or email</DialogContentText> */}
        
        <CustomAutocomplete
          id="asynchronous-search"
          multiple
          style={{ width: 500 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
              setOpen(false);
          }}
          filterSelectedOptions
          // getOptionSelected={(option, value) => {
          //   console.log("USER getOptionSelected", option.id, value.id);
          //   if (option.id === value.id) {
          //     selectedUsers.add(value.id)
          //   }
          //   return option.id === value.id
          // }}
          getOptionLabel={(option) => {
            console.log("USER OPTIONS", option);
            return `${option.first_name} (${option.email})`;
          }}
          renderOption={(props, option) => {
            console.log("USER renderOption", props)
            return (
            <Grid key={props.user_id} container alignItems='center'>
                <Avatar alt={`${props.first_name} ${props.last_name} `} src={props.profile_image} />
                <Box ml={1}>{props.first_name} {props.last_name} ({props.email})</Box>
            </Grid>
            )
          }}
          options={options}
          loading={loading}
          onChange={(e, value, reason) => {
            selectedUsers = new Set();
            value.forEach(user => {
              console.log("DATA", user.id);
              selectedUsers.add(user.id)
            });
          }}
          renderInput={(params) => (
            <TextField
              margin="dense"
              onChange={getSearchResults}
              {...params}
              label="Search for user to share or invite"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Collapse in={error !== null}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError(null)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>{error}</Alert>
         </Collapse>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCallback(false)} color="inherit">Cancel</Button>
        <Button onClick={handleShare} color="primary">Share</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareDialog;