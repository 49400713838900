import { Avatar, Box, Button, CircularProgress, Container, Grid, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Sidebar from '../../Components/Sidebar';
import './style.css'
import Header from '../../Components/Header';
import DatasetBuilder from '../../Components/DatasetBuilder';
import SaveDataset from '../../Components/SaveDataset';
import WorldMap from '../../Components/WorldMap';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import { Alert } from '@material-ui/lab';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";
import { PasswordHiddenIcon, PasswordIcon, PasswordShowIcon } from '../../Components/Icon';
import OnboardWizard from '../../Components/OnboardWizard';

const UpdatePassword = (props) => {
   
    const { user, state: { dataset }, setUserUpdate } = useContext(GlobalContext);
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const oldPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory()
    // const [error, setError] = useState(null);


    const updatePassword = ({new_password}) => {
        setLoading(true);

        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'PATCH',
            url: `${API_URL}/change-password/`,
            data: { new_password },
            headers: {
                'Authorization': `Token ${token}`
            },
        };
        
        axios(config).then((res) => {
            const data = res.data
            console.log("UpdatePassword", data);
            setError(null);
            setSuccess(true);
            setLoading(false)
            setUserUpdate({ ...user, is_password_changed: true })
            if (user && (!user.first_name || !user.last_name)) {
                setTimeout(() => {
                    history.push({
                        pathname: `/update-profile/`,
                        state: {
                            first_time: true,
                        }
                    })
                }, 1000);
                
            }
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response.status === 404) {
                setError("error", {
                    type: "manual",
                    message: error.response.statusText,
                });

            } else if (error.response && error.response.data) {
                // setError(error.response.data)
                Object.entries(error.response.data).forEach((value) => {
                    if (["old_password", "new_password", "confirm_password"].includes(value[0])) {
                        setError(value[0], {
                            type: "manual",
                            message: value[1],
                        });
                    }
                });
                setError("error", {
                    type: "manual",
                    message: "An Error occurred.",
                });
            } else {
                setError("error", {
                    type: "manual",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
        });
    }

    const onSubmit = data => {
        updatePassword(data)

    };

    

    return (
        <Box paddingX={7}>
            <Header />
            <Grid container direction="row" justify="flex-start" className="update-password-main">

                <Grid className="update-password" container direction="column">

                    <Box padding={1} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ width: '100%', padding: '0px 32px', alignSelf: 'center'}}>
                            <h2
                                className="title"
                                style={{ alignSelf: 'center', textAlign: 'center', margin: '64px 0' }}>
                                Update Password
                            </h2>
                            {user && user.is_password_changed === false ?
                                <Alert icon={false} severity="warning" className="sub-title" style={{ textAlign: 'left'}}>
                                    For security, you need to set a password.
                                </Alert> : null
                                // <div className="sub-title">
                                //     For security, you need to change the auto generated password sent to you in email.
                                // </div>
                                
                            }

                            </Box>
                    </Box>

                    { (user && user.is_password_changed === false) && <OnboardWizard page={"password"} />}

                    {
                        errors.error &&
                        <Grid item>
                            <Alert icon={false} severity="error">{errors.error?.message}</Alert>
                        </Grid>
                    }

                    {
                        success &&
                            <Grid item>
                                <Alert icon={false} variant='filled' severity="success">Password updated successfully.</Alert>
                            </Grid>
                    }
                    <Grid className="form" container direction="column">
                        <form onSubmit={handleSubmit(onSubmit)}>
                         
                            <Grid item>
                                <TextField
                                    inputProps={{
                                        ...register("new_password", {
                                            required: "New password is required",
                                            minLength: { value: 8, message: 'Your password must contain at least 8 characters.' },
                                            // pattern: { value: /[A-Za-z]{8}/, message: 'error message' }
                                        })
                                    }}
                                    onChange={()=> clearErrors()}
                                    inputRef={newPasswordRef}
                                    type={showNewPassword ? "text" : "password"}
                                    margin= 'normal'
                                    className="onboard-password"
                                    placeholder="New password"
                                    helperText= {<span className='error'>{errors.new_password?.message}</span>}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                    onMouseDown={() => setShowNewPassword(!showNewPassword)}>
                                                    {showNewPassword ? <PasswordShowIcon /> : <PasswordHiddenIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }} />
                                
                            </Grid>
                            <Grid item>
                                <TextField
                                    inputProps={{
                                        ...register("confirm_password", {
                                            required: "Confirm password is required",
                                            validate: value => value === newPasswordRef.current.value || 'Confirm password must match New password.',
                                            minLength: { value: 8, message: 'Your password must contain at least 8 characters.' },
                                            // pattern: { value: /[A-Za-z]{8}/, message: 'error message' }
                                        })
                                    }}
                                    onChange={()=> clearErrors()}
                                    inputRef={confirmPasswordRef}
                                    type={showConfirmPassword ? "text" : "password"}
                                    margin= 'normal'
                                    className="onboard-password"
                                    placeholder="Confirm new password"
                                    helperText= {<span className='error'>{errors.confirm_password?.message}</span>}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                    {showConfirmPassword ? <PasswordShowIcon /> : <PasswordHiddenIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}/>
                            </Grid>

                            <Grid item>
                                <Button
                                    type='submit'
                                    size="large"
                                    variant="contained"
                                    className="onboard-button"
                                    color="primary"
                                    classes={{ label: "label" }}
                                    // onClick={passwordUpdateHandle}
                                    disableElevation>
                                    {loading ? <CircularProgress size={20} color="inherit" /> : "Update"}
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UpdatePassword;