import { Box, Table, TableBody } from "@material-ui/core";
import React, { useState } from "react";
import "./style.css";
import DataSetRow from "Components/ExperimentsList/DataSetRow";
import ArchivedDataSetRow from "Components/ExperimentsList/ArchivedExperiments/DataSetRow";
import PlaceHolderHelp from "Components/PlaceHolderHelp";

export default function Experiments(props) {
  const [hasExperiment, setHasExperiment] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);

  const showArchivedExperiments = (e) => {
    setShowArchived(!showArchived)
  }
  return (
    <div className="competition-experiments">
      {loading === false && hasExperiment === false && (
        <PlaceHolderHelp />
      )}
      {
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            // justifyContent: "center",
            marginRight: "32px",
            marginTop: "19px",
          }}
        >
          <Table stickyHeader className="table" aria-label="simple table">
            <TableBody>
              { !showArchived ?
                <DataSetRow
                  hover
                  onClick={(event) => { }}
                  showArchived={showArchivedExperiments}
                  role="checkbox"
                  aria-checked={true}
                  tabIndex={-1}
                  datasetIndex={0}
                  selected={true}
                  key={props.dataset}
                  archiveDataset={() => { }}
                  labelId={`enhanced-table-checkbox-${0}`}
                  row={{ ...props, id: props.dataset }}
                  showHeader={false}
                  hasExperiment={setHasExperiment}
                  expLoading={setLoading}
                /> :
                <ArchivedDataSetRow
                  hover
                  onClick={(event) => { }}
                  showArchived={showArchivedExperiments}
                  role="checkbox"
                  aria-checked={true}
                  tabIndex={-1}
                  datasetIndex={0}
                  selected={true}
                  key={props.dataset}
                  archiveDataset={() => { }}
                  labelId={`enhanced-table-checkbox-${0}`}
                  row={{ ...props, id: props.dataset }}
                  showHeader={false}
                  hasExperiment={setHasExperiment}
                  expLoading={setLoading}
                />
              }
            </TableBody>
          </Table>
        </Box>
      }
    </div>
  );
}
