import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

export const CustomTabs = withStyles({
    
    root: {
        borderBottom: '0px solid #e8e8e8',
        position: 'relative',
        left: '20px',
        top: '20px',
    },
    indicator: {
        backgroundColor: '#ffffff00',
    },
})(Tabs);
