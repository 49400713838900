import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();


const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true
};


// Application Insights Configuration
const configObj = {
  connectionString: process?.env?.REACT_APP_INSIGHTS_CONNECTION_STRING,
  // Alternatively, you can pass in the instrumentation key,
  // but support for instrumentation key ingestion will end on March 31, 2025.
  // instrumentationKey: "YOUR INSTRUMENTATION KEY",
  enableAutoRouteTracking: true,
  extensions: [clickPluginInstance, reactPlugin],
  extensionConfig: {
    [clickPluginInstance.identifier]: clickPluginConfig
  },
};

const appInsights = new ApplicationInsights({ config: configObj });
appInsights.loadAppInsights();
appInsights.trackPageView();


export { reactPlugin, appInsights };