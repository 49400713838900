
import { checkCompetitors, countClasses } from '../utils';
import { ARCHIVE_DATASET, CREATE_DATASET, CREATING_DATASET, JOIN_COMPETITION, LOADING_ARCHIVED_EXPERIMENTS, LOADING_COMPETITIONS, LOADING_DATASETS, LOADING_EXPERIMENTS, LOAD_ARCHIVED_EXPERIMENTS, LOAD_COMPETITIONS, LOAD_DATASETS, LOAD_EXPERIMENTS, LOGIN_USER, OPEN_SIDEBAR, SAVE_DATASET_IN_DB, SET_AUTONOMOUS_DATASET, SET_DATASET, SET_DATASET_IMAGES, SET_ERROR, SET_MEDICAL_DATASET, UNARCHIVE_DATASET, UPDATE_EXPERIMENTS_STATUS } from './Types'

const AppReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload }
            };
        
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case OPEN_SIDEBAR:
            return {
                ...state,
                sidebar: { ...state.sidebar, open: action.payload}
            }
        case CREATE_DATASET:
            return {
                ...state,
                dataset: {
                    ...state.dataset,
                    saved: null
                },
                datasetOptions: { ...state.datasetOptions, ...action.payload }
            }
        case SET_DATASET:
            return {
                ...state,
                dataset: {
                    ...state.dataset,
                    dataset_type: action.payload.dataset_type,
                    count: action.payload.count,
                    loading: false,
                    groups: action.payload.groups,
                    ok: action.payload.ok,
                    nok: action.payload.nok,
                }
            }
        
        case SET_MEDICAL_DATASET:
            return {
                ...state,
                dataset: {
                    ...state.dataset,
                    description: action.payload.description,
                    label_density: action.payload.label_density,
                    data_items_label_count: action.payload.data_items_label_count,
                    unique_data_items_count: action.payload.unique_data_items_count,
                    data_samples: [...action.payload.data_samples],
                    dataset_type: action.payload.dataset_type,
                    count: action.payload.count,
                    loading: false,
                    groups: action.payload.groups,
                    ok: action.payload.ok,
                    nok: action.payload.nok,
                }
            }

        case SET_AUTONOMOUS_DATASET:
            return {
                ...state,
                dataset: {
                    ...state.dataset,
                    description: action.payload.description,
                    label_density: action.payload.label_density,
                    data_items_label_count: action.payload.data_items_label_count,
                    unique_data_items_count: action.payload.unique_data_items_count,
                    data_samples: [...action.payload.data_samples],
                    dataset_type: action.payload.dataset_type,
                    count: action.payload.count,
                    loading: false,
                    groups: action.payload.groups,
                    ok: action.payload.ok,
                    nok: action.payload.nok,
                }
            }
        /// SET Dump images: we need to change this
        case SET_DATASET_IMAGES:
            return {
                ...state,
                dataset: { ...state.dataset, data_samples: [...action.payload.dataset]}
            }
        
        case SAVE_DATASET_IN_DB:
        // case to handle loader while loading datasets from server
        return {
            ...state,
            dataset: {
                ...state.dataset,
                loading: false,
                list: [],
                count: null,
                groups: [],
                ok: 0,
                nok: 0,
                saved: {...action.payload}
            }
        }
        case CREATING_DATASET:
            return {
                ...state,
                dataset: {...state.dataset, loading: action.payload }
            }
        case LOAD_DATASETS:
            return {
                ...state,
                datasets: {
                    ...state.datasets,
                    count: action.payload.count,
                    list: [
                        ...state.datasets.list,
                        ...action.payload.results.map(dataset => {
                            console.log("LOAD_DATASETS-SINGLE", dataset);
                            return {
                                ...dataset,
                                dataset_meta: dataset.dataset_meta ? JSON.parse(dataset.dataset_meta) : null,
                                data_samples: dataset.data_samples ? JSON.parse(dataset.data_samples) : null,

                            }
                        })
                    ],
                    prev: action.payload.previous,
                    next: action.payload.next,
                    loading: false
                }
            }
        case LOADING_DATASETS:
            return {
                ...state,
                datasets: {...state.datasets, loading: action.payload }
            }
        case ARCHIVE_DATASET:
            console.log("ARCHIVE_DATASET2", action.payload.dataset)
            const { index, dataset, remove } = action.payload;
            let result = [];
            if (remove) {
                result = state.experiments.list.filter(obj => {
                    return obj.id !== dataset.id
                })
                console.log("ARCHIVE_DATASET", result)
            } else {
                result = state.experiments.list;
                result.splice(index, 0, dataset);
                console.log("ARCHIVE_DATASET", result)
            }
            
            return {
                ...state,
                experiments: {
                    ...state.experiments,
                    list: [...result]
                }
            }
        case UNARCHIVE_DATASET:
            console.log("UNARCHIVE_DATASET", action.payload?.dataset)

            let unArchivedResult = [];
            if (action.payload?.remove) {
                unArchivedResult = state.archivedExperiments.list.filter(obj => {
                    return obj.id !== action.payload?.dataset?.id
                })
                console.log("UNARCHIVE_DATASET", unArchivedResult)
            } else {
                unArchivedResult = state.archivedExperiments.list;
                unArchivedResult.splice(action.payload?.index, 0, action.payload?.dataset);
                console.log("UNARCHIVE_DATASET", unArchivedResult)
            }
            
            return {
                ...state,
                archivedExperiments: {
                    ...state.archivedExperiments,
                    list: [...unArchivedResult]
                }
            }
        
        case LOAD_EXPERIMENTS:
            return {
                ...state,
                experiments: {
                    ...state.experiments,
                    count: action.payload.count,
                    list: [
                        ...state.experiments.list,
                        ...action.payload.results.map(dataset => {
                            // action.payload.results for pagination
                            let dataset_meta = null;
                            try {
                                dataset_meta = dataset.dataset_meta ? JSON.parse(dataset.dataset_meta) : null;
                            } catch (e) {
                                console.log("ERROR-22", e);
                                // console.log("dataset", dataset)
                            }
                            // console.log("dataset_meta", dataset_meta);
                            let class_count = 0
                            class_count = countClasses(dataset_meta?.count.label)
                            // console.log("dataset_dataset" , dataset.title, dataset_meta, );
                            return {
                                ...dataset,
                                images: dataset_meta?.count?.ok + dataset_meta?.count?.nok,
                                class_count: class_count,
                                // description: dataset_meta?.description,
                                label_density: dataset_meta?.label_density,
                                data_items_label_count: dataset_meta?.data_items_label_count,
                                dataset_meta: dataset_meta,
                            }
                        })
                        
                    ],
                    prev: action.payload.previous,
                    next: action.payload.next,
                    loading: false
                }
            }
        
        case LOAD_ARCHIVED_EXPERIMENTS:
            return {
                ...state,
                archivedExperiments: {
                    ...state.archivedExperiments,
                    count: action.payload.count,
                    list: [
                        ...state.archivedExperiments.list,
                        ...action.payload.results.map(dataset => {
                            // action.payload.results for pagination
                            let dataset_meta = null;
                            try {
                                dataset_meta = dataset.dataset_meta ? JSON.parse(dataset.dataset_meta) : null;
                            } catch (e) {
                                console.log("ERROR-22", e);
                                console.log("dataset", dataset)
                            }
                            
                            console.log("dataset_meta", dataset_meta);
                            let class_count = 0
                            class_count = countClasses(dataset_meta?.count.label)
                            

                            return {
                                ...dataset,
                                images: dataset_meta?.count?.ok + dataset_meta?.count?.nok,
                                class_count: class_count,
                                dataset_meta: dataset_meta,
                            }
                        })
                        
                    ],
                    prev: action.payload.previous,
                    next: action.payload.next,
                    loading: false
                }
            }
            
        case UPDATE_EXPERIMENTS_STATUS:
            
            const resultDatasets = state.experiments.list.map(oldDataset => {
                const exists = action.payload.find(newDataset => oldDataset.id == newDataset.id);
                return exists ? {...oldDataset, ...exists} : oldDataset
            })
            
            return {
                ...state,
                experiments: {
                    ...state.experiments,
                    list: [...resultDatasets],
                }
            }

        case LOADING_EXPERIMENTS:
            return {
                ...state,
                experiments: {...state.experiments, loading: action.payload }
            }
        case LOADING_ARCHIVED_EXPERIMENTS:
            return {
                ...state,
                archivedExperiments: {...state.archivedExperiments, loading: action.payload }
            }
        case LOADING_COMPETITIONS:
            return {
                ...state,
                competitions: {...state.competitions, loading: action.payload }
            }
        
        case LOAD_COMPETITIONS:

            console.log('LOAD_COMPETITIONS', action.payload)
            return {
                ...state,
                competitions: {
                    ...state.competitions,
                    count: action.payload.count,
                    list: [
                        ...state.competitions.list,
                        ...action.payload.results.map(competition => {
                            // action.payload.results for pagination
                            const winning_prizes = competition.prizes
                            const prize = winning_prizes && winning_prizes.reduce((previousValue, currentValue) => {
                                return parseFloat(previousValue) + parseFloat(currentValue.prize_amount)
                            }, 0.00);
                            
                            
                            return {
                                loading: false,
                                ...competition,
                                prize: prize.toFixed(2),
                                remaining: competition.end_date,
                                competitors: checkCompetitors(competition.competitor_companies) ? competition.competitor_companies[0].company_type : "Mixed"
                            }
                        })
                    ],
                    prev: action.payload.previous,
                    next: action.payload.next,
                    loading: false
                }
            }

        case JOIN_COMPETITION:
            return {
                ...state,
                competitions: {
                    ...state.competitions,
                    list: [
                        ...state.competitions.list.map((competition) => {
                            if (competition.dataset === action.payload.dataset) {
                                console.log("competition-action.payload", competition, action.payload)
                                const extraPayload = action.payload
                                return {
                                    ...competition,
                                    ...extraPayload
                                }
                            }
                            return competition
                        })
                    ]
                }
            }
        default: return state;
    }
}

export default AppReducer;