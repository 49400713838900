import { Box, Popover } from '@material-ui/core';
import React from 'react';
import './style.css'

CustomPopOver.defaultProps = {
    elevation: 0,
    showNotch: true,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'top', horizontal: 'center' },
    PaperProps: {
        style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 5,
            padding: 8,
        }
    },
    ContainerPadding: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px'
    }
};

function CustomPopOver(props) {
    const { children, ContainerPadding, showNotch, ...restProps } = props;


    return (
        <Popover
            {...restProps}
        >
           { showNotch && <Box
                sx={{
                    position: "relative",
                    mt: "10px",
                    "&::before": {
                        backgroundColor: "#FFFFFF",
                        border: '1px solid #f7f7f7',
                        boxShadow: '-1px -1px 0px #00000019',
                        borderBottomWidth: '0',
                        borderRightWidth: '0',
                        content: '""',
                        display: "block",
                        position: "absolute",
                        width: 14,
                        height: 14,
                        top: -6,
                        transform: "rotate(45deg)",
                        left: "calc(50% - 6px)"
                    }
                }}
            />}

            <div
                onClick={(e) => e.stopPropagation()}
                style={{ ...ContainerPadding }}
                className="custom-popup">
                {children}
            </div>
            

        </Popover>
        
    );
}

export default CustomPopOver;