import { Typography } from "@material-ui/core";
import React from "react";
import { descriptionStyle, headingStyle, subHeadingStyle, useStyles } from "../style";


export default function English() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2>General Collaboration Rules​</h2>

      <Typography style={subHeadingStyle}>Preamble</Typography>

      <Typography style={descriptionStyle}>
      tracebloc GmbH, Rosenheimer Str. 38, 10781 Berlin (hereinafter: "tracebloc") operates a platform on which, among other things, customers can organise competitions for software developers or developer teams (hereinafter referred to individually as "users" or collectively as "teams") and offer prizes for the winners (hereinafter: "Collaboration"). For this purpose, Organisers may publish data sets via the Platform (hereinafter collectively referred to as "Collaboration Data"), which are used by Users to develop applications in the field of machine learning (hereinafter referred to as: "ML Solution"). Users can participate in a Collaboration by registering via the Platform and making the ML Solutions they have developed available via the Platform in order to train them with the Organiser's Training Data.
The following general terms of service (hereinafter "ToS") apply to all users of the platform:
      </Typography>

     
      <br />

    <h3>General provisions</h3>
    <div>
      <ol>
        <li>These ToS apply to all users registered on the tracebloc platform. The current version of the ToS can be accessed at any time via
          the following link <a href="https://www.tracebloc.io/terms" target="_blank">https://www.tracebloc.io/terms</a>.</li>
        <li>Unless otherwise agreed between the parties, the use of the platform is free of charge for users. tracebloc is free to provide the
          user with computing power in the form of a computation budget (FLOPS units) at its own discretion, which can be used by the user to
          train its own ML solutions on general data sets. The computation budget provided in this way cannot be used for competitions.
          This does not constitute a future claim to the granting of further computation budget.</li>
        <li>Additional Computation Budget beyond that specified in Clause 1.2 may be purchased by the User for a fee. The terms and
          conditions of the respective service package shall apply.</li>
        <li>These ToS apply exclusively. Deviating, conflicting or supplementary general terms and conditions of the user shall only become
          part of the contract if and insofar as tracebloc has expressly agreed to their validity.</li>
      </ol>
    
      </div>
      

      <br />

      <h3>Subject of performance and availability</h3>
      <div>
        <ol>
          <li>The main service obligation of tracebloc is the provision of the platform. The provision of the platform is fulfilled by making an online access (SaaS solution) available. In all other respects, tracebloc shall provide the platform as it was available at the time of registration and the ToS applicable at that time ("as is"). Beyond this, the user has no claim to a specific design or equipment of the platform.</li>
          <li>Participation in a Collaboration creates a contractual relationship between the User and the Organiser in accordance with Clause 5. tracebloc does not become a contractual party to this contractual relationship, but merely provides the platform with the functionalities specified in these general terms and conditions. A claim to participate in a competition is not established by these ToS.</li>
          <li>tracebloc will maintain and further develop the platform on an ongoing basis. Further developments of the platform will be made available to the user in the course of updates or upgrades. This does not constitute a claim to the development of individual versions or the provision of new versions.</li>
          <li>tracebloc provides the platform with an overall availability of 98.5% on an annual average. The availability is calculated minus the time periods attributable to necessary maintenance. The platform is not available during maintenance work.</li>
        </ol>
      </div>

      <br />

      <h3>Registration</h3>
      <div>
        <ol>
          <li>Users can only be entrepreneurs within the meaning of § 14 (1) BGB (German Civil Code). By registering, the user accepts the ToS valid at that time. In order to avoid abusive registrations, the registration will be confirmed immediately by e-mail to the e-mail address given in the sign-up form after the form has been submitted to tracebloc. The registration is completed and the user profile activated via the activation link contained therein. If the user's data changes, he/she is obliged to update the information in his/her user profile without delay.</li>
          <li>The user is obliged to keep his password secret and to protect it from unauthorised use by third parties. The user is obliged to change the password at regular intervals. If the user becomes aware of a misuse of his access data, if there is a possibility or a threat of such a misuse, the user shall inform tracebloc immediately. The user is liable for any misuse of his user profile; this does not apply if the user is not responsible for the misuse.</li>
        </ol>
      </div>
      <br />
      
      <h3>Content retrieval and use</h3>
      <div>
        <ol>
          <li>tracebloc makes various contents, in particular competition data, available to users via the platform for viewing for a limited period of time. Such content may in particular be data provided by third parties for the organisation of a competition, in particular image and video material.</li>
          <li>tracebloc is only a technical service provider. The content provided by third parties via the platform is only stored by tracebloc. If tracebloc exceptionally provides its own content, this is expressly marked as such.</li>
          <li>The person posting the content is responsible for compliance with legal regulations with regard to the content posted by third parties. tracebloc will not check this for completeness, correctness, legality, up- to-dateness, quality and suitability for a competition.</li>
        </ol>
      </div>
      <br />
      

      <h3>Participation in a Collaboration</h3>
      <div>
        <ol>
          <li>A user is free to register for participation in a competition. By registering and clicking on the button "Join Collaboration", he/she accepts the terms and conditions of participation determined by the respective organiser and thus becomes a participant (hereinafter: "Competitor"). In the event of contradictions between such terms and conditions of participation of the Organiser and these ToS, the provisions of the terms and conditions of participation of the Organiser shall prevail.</li>
          <li>During an ongoing competition, competitors or teams can train their ML solutions in accordance with the applicable regulations. At the end of a competition, each competitor or team selects a certain number of ML solutions on its own responsibility from the organiser, which take part in the prize determination by the organiser.</li>
          <li>Unless otherwise specified in the oragniser’s Terms and Conditions, the following provisions apply to the conduct of a Collaboration:
          
            <ul>
              <li>The organiser is free to limit the number of participants. Furthermore, the organiser is free to take part in his own competition, in which case he or his team will not be taken into account in the determination of the prize.</li>
              <li>Competitors can form teams of a maximum of 5 natural persons until the end of a Collaboration (the team merger deadline). A competitor can participate in a competition either as an individual or as a team member. A Competitor cannot be a member of different teams. A team merger can only be cancelled for good cause. Team mergers cannot be reversed. As soon as a team merger takes place, the ML solutions developed by the respective Competitor/Team up to this point are shared with all members of a team.</li>
              <li>For each Team or Competitor, the provisions set out in the Organiser's Conditions of Participation shall apply, in particular with regard to the available Computation Budget per User or Team. A merger into a team is excluded if the total Computation Budget per Competitor used up by the time of the desired merger does not exceed the Computation Budget per team determined by the Organiser and if a deadline for the team merger determined by the Organiser, if any, is not exceeded.</li>
              <li>Each team can submit a maximum of 5 ML solution models per day in the tracebloc time zone.</li>
              <li>The ML solutions submitted by competitors or teams are trained by the organiser during the current competition with the training data determined by the organiser. The results are published by the organiser on the leaderboard, whereby the score information (evaluation metric) is
determined solely by the organiser.</li>
              <li>The competitors or teams are not entitled to the provision or transfer of the training data.</li>
              <li>The organiser may design the competition in such a way that pre-trained models are allowed or
not. The pre-trained models must have been trained on data sets that allow the model to be used commercially.</li>

            </ul>
          </li>
        </ol>
      </div>
      
      <br />



      


      <h3>Determination of the winners</h3>
      <div>
        <ol>
          <li>Each Collaboration data will be scored and ranked by the evaluation metric stated on the Collaboration Website. During the Collaboration Period, the current ranking will be visible on the Collaboration Website's public leaderboard. The potential winner(s) are determined solely by the leaderboard ranking on the private leaderboard, subject to compliance with these Rules. The public leaderboard will be based on the public test set and the private leaderboard will be based on the private test set.</li>
          <li>For the avoidance of doubt, it is regulated that the winner is determined by the organiser in sole responsibility.</li>
          <li>In the event of a tie, the Submission that was entered first to the Collaboration will be the winner. In the event a potential winner is disqualified for any reason, the Submission that received the next highest score rank will be chosen as the potential winner.</li>
          <li>The participation of employees of the Organiser and tracebloc are excluded from participation in a Collaboration.</li>
         
        </ol>
      </div>
      
      <br />










      <h3>Rights of use</h3>
      <div>
        <ol>
          <li>All content offered via the platform is protected under copyright, trademark, name and, where applicable, other legal aspects. The compilation of the content as such may be protected as a database or database work within the meaning of §§ 4 (2), 87a (1) UrhG. And §§ 4 (2), 87a (1) UrhG. Users may only use this content in accordance with these ToS and within the framework specified on the platform.</li>
          <li>During the term of the contract tracebloc grants the user a simple and non-transferable right to use the platform, limited in time to the contractual relationship. Any use of the platform beyond this granting of rights is not permitted.</li>
          <li>Unless otherwise stipulated in the terms and conditions of participation of an organiser, the data published on the platform, in particular the competition data, are for viewing purposes only. Users are not granted or transferred any further rights of use and/or exploitation to this content.</li>
          <li>The User shall not be entitled to edit the Platform or the content made available via it, in particular to modify, translate, reverse engineer, decompile, disassemble or redesign it, except as provided in this clause. 6 or unless permitted by law. By way of exception, decompilation of the platform shall only be permitted if the prerequisites and conditions set out in § 69 e (1) UrhG are met. The information obtained in this way may not be used or passed on contrary to the provisions of § 69 e (2) UrhG.</li>
          <li>The user himself is responsible for the creation of the technical conditions necessary in the user's area of responsibility for the use or retrieval of the contents in accordance with the contract.</li>
        </ol>
      </div>
      <br />
      

      <h3>Contents and obligations of users and infringements</h3>
      <div>
        <ol>
          <li>The user is responsible for ensuring that he or she complies with the conditions of participation of the respective competition organiser.</li>
          <li>Unless otherwise stipulated in the terms and conditions of participation of an organiser, users are prohibited during an ongoing competition from making available to third parties, in particular other teams, an ML solution or parts thereof or the underlying source code developed within the framework of the respective competition on the basis of the competition data, unless these third parties were demonstrably connected to the user in a team prior to the provision. The provision is permissible if this is done for all participants of a competition via the forum of the platform.</li>
          <li>Furthermore, every user must observe the applicable law and protect the rights of third parties. In particular, users are prohibited from,
          
            <ul>
              <li>violate third party property rights such as trademarks, copyrights and rights to the use of names;</li>
              <li>To crawl or scrape all or part of the content of the platform;</li>
              <li>Decompile or reverse engineer the Platform or otherwise attempt to obtain the source code or content provided by third parties through the Platform, unless expressly permitted;</li>
              <li>post content that is relevant to criminal law;</li>
              <li>use mechanisms, software and/or scripts that go beyond the functionalities and interfaces
provided on the tracebloc platform, in particular if this blocks, modifies, copies and/or overwrites tracebloc services and these services are required for the contractual use of tracebloc.</li>
            </ul>
          </li>
          <li>tracebloc is entitled to delete individual illegal contents or contents that violate these ToS. Furthermore, tracebloc is entitled to impose one or more sanctions on the user at its reasonable discretion (§ 315 BGB), taking into account the interests of the user, if the user culpably violates these ToS or the respective conditions of participation or if there is an important reason within the meaning of section 8.4 of these ToS. 8.4 of these ToS. The severity of the sanctions depends on the severity of the respective violation. The sanction shall remain in force until the sanctioned act has been stopped by the user and any risk of repetition has been eliminated.</li>
          <li>The following sanctions may be considered: (i) the deactivation or blocking of individual functions of the platform for the affected user (ii) the deletion, postponement or closure of the content posted for the user (iii) the complete blocking of access to the platform as well as (iv) the complete deletion of an account. The right to extraordinary termination for good cause by tracebloc pursuant to para. 6.4 remains unaffected by this as well as the assertion of further claims.</li>

        </ol>
      </div>
      
      <br />


      <h3>Term and termination</h3>
      <div>
        <ol>
          <li>The contractual relationship concerning the use of the platform begins with the successful registration of the user and runs for an indefinite period.</li>
          <li>The contractual relationship may be terminated by either party with 3 months' notice to the end of the month.</li>
          <li>To be effective, a termination requires a clear declaration in text form and can be made by e-mail to info@tracebloc.io.</li>
          <li>The right to extraordinary termination for good cause remains unaffected. An important reason for termination exists for tracebloc in particular if
          
            <ul>
              <li>a user fails to comply with the obligations under para. 4 and/or 5 culpably violates them,</li>
              <li>a user culpably violates the provisions of section 7.2,</li>
              <li>a user culpably reconstructs competition data (so-called reverse engineering or model-inversion-attack), or</li>
              <li>a user culpably violates the respective conditions of participation of a competition, or § Section 314 BGB remains unaffected.</li>
            </ul>
          </li>
        </ol>
      </div>
      
      <br />


      <h3>Liability</h3>
      <div>
        <ol>
          <li>The parties shall be liable to each other for direct and indirect property damage and financial loss caused by them, their legal representatives or vicarious agents or third parties engaged by them, in particular subcontractors, in accordance with the following provisions.</li>
          <li>tracebloc shall only be liable for intent and gross negligence - except in the case of breach of essential contractual obligations, injury to life, limb or health or in the case of claims arising from the Product Liability Act. Essential contractual obligations are those whose fulfilment is necessary to achieve the purpose of the contract.</li>
          <li>The user himself/herself shall be exclusively liable for breaches of duty by the user, in particular in the event of infringement of third-party trademarks, copyrights and rights to a name. The user indemnifies tracebloc from all claims for payment of other users and third parties, which are asserted against tracebloc due to an infringement of rights by the contents posted for a user and/or due to the use of the platform by the user. The user shall bear all reasonable costs incurred by tracebloc resulting from such an infringement upon first request. This includes in particular the necessary costs of legal defence. This shall not apply if the user is not responsible for the infringement. Other claims of tracebloc against the user remain unaffected.</li>
          <li>The availability of the platform pursuant to ckause 2.3 may be temporarily restricted in whole or in part due to technical disruptions for which tracebloc is not responsible or for reasons of force majeure (power supply and/or internet failure, fire, explosion, earthquake, storm, flooding, industrial action for which tracebloc is not responsible), furthermore due to the performance of maintenance work which serves to maintain and improve the operability and functionality of the platform. tracebloc is only liable for the consequences of limited availability within the scope of the availability promised to the user in the service description.</li>
          <li>Liability for damages regardless of fault for defects, in particular in accordance with § 536a of the German Civil Code (BGB), which already existed at the time of conclusion of the contract, is excluded. These claims shall otherwise be governed by the statutory provisions.</li>
        </ol>
      </div>
    <br />


    <h3>Final provisions</h3>
      <div>
        <ol>
          <li>The law of the Federal Republic of Germany shall apply to the exclusion of the UN Convention on Contracts for the International Sale of Goods and the conflict of laws provisions; Art. 3 EGBGB shall remain unaffected. If the user has his habitual residence in another country within the EU/EEA at the time of the establishment of the contractual relationship, the application of mandatory legal provisions of this country shall remain unaffected by the choice of law made in these ToS.</li>
          <li>No verbal ancillary agreements have been made. Amendments and supplements to these ToS as well as all declarations of the parties relating to the contractual relationship must be made in text form (§ 126b BGB). This also applies to the waiver of the formal requirement.</li>
          <li>Place of performance and place of jurisdiction for mutual claims arising from the contractual relationship is the registered office of tracebloc, unless otherwise stipulated. tracebloc is entitled to take legal action against the user at his general place of jurisdiction.</li>
        </ol>
      </div>
    <br />

      

</div>

    

    
  );
}
