import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './style.css';
import { API_URL } from '../../../../../../Context/GlobalState';
import { Box, Button, CircularProgress, Grid, IconButton, Portal, Snackbar } from '@material-ui/core';
import { CopyIcon, NotificationCheckIcon } from '../../../../../Icon';
import { Alert } from '@material-ui/lab';




const CodeView = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [code, setCode] = useState(null);
    const [copied, setCopied] = useState(false);

    console.log("Code View: expanded-experiment", props.expanded, props.experiment);
    const getCode = () => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'get',
            url: `${API_URL}/modelcode/${props.experiment}/`,
            headers: {
              'Authorization': `Token ${token}`
            }
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("COMMENT-OBJECT", data["code"]);
            setCode(data["code"])
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure you are authorized.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!code && props.expanded) {
            getCode();
        }
        // if (experiments.length > 0 && experiments.next) {
        //     getExperiments();
        //     console.log("EXPERIMENTS-LIST-PAGE-NEXT", page);
        // }
    }, [props.expanded])

    const showCopyMessage = (e) => {
        setCopied(true)
        console.log("Copied")
        setTimeout(() => {
            setCopied(false);
        }, 2000)
    }

    return (
        <div className={`codeview ${props.expanded ? 'expand' : ''}`}>
           
            {props.expanded && code &&
                <Grid direction="row" justifyContent="space-between" container>
                    <Grid style={{ width: '94%'}} item>
                        <SyntaxHighlighter
                            language="python"
                            showLineNumbers
                            style={vs2015}
                            className="code-editor">
                            {code}
                        </SyntaxHighlighter>
                    </Grid>
                    <Grid item>
                        <CopyToClipboard text={code}
                            onCopy={() => showCopyMessage()}>
                            <IconButton><CopyIcon className="copy-icon" /></IconButton>
                        </CopyToClipboard>
                        
                    </Grid>
                </Grid>
                
                
            }
            {
                loading && <Grid container justify="center" alignItems="center"><CircularProgress /></Grid>
            }

            <Portal>
                <Snackbar
                    open={copied}
                    autoHideDuration={6000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                    <Alert
                        variant="filled"
                        severity="success"
                        iconMapping={{ success: <NotificationCheckIcon style={{ fontSize: 20 }} /> }}
                        onClose={() => setCopied(false)}>Code successfully copied</Alert>
                </Snackbar>
                
            </Portal>
        </div>
    );
}

export default CodeView;