import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import DomainOutlinedIcon from '@material-ui/icons/DomainOutlined';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        "& :hover": {
            backgroundColor: "#f3f3f3",
        }
    },
  }));
  
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ fontSize: "1.1rem !important"}} variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginLeft: 10,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TypeDialog(props) {

    const { open, handleOpen, handleSelection } = props;
    const classes = useStyles();

    const handleClickOpen = () => {
      handleOpen(true);
    };
    
    const handleClose = () => {
        handleOpen(false);
    };



  return (
   
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>Who should have access to your dataset?​</DialogTitle>
      <DialogContent>
        <List className={classes.root}>
          <ListItem onClick={()=> handleSelection("Enterprise")}>
              <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#673ab7"}} variant="rounded">
                        <LockOutlinedIcon />
                    </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Enterprise" secondary="Only people in your organization with your permission can join." />
          </ListItem>
          <ListItem onClick={()=> handleSelection("Global")}>
              <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#2979ff"}} variant="rounded">
                  <LanguageOutlinedIcon />
              </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Global" secondary="Anyone with your permission can join." />
          </ListItem>
                
          {/* <ListItem onClick={() => handleSelection("Public")}>
            <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#ff9100"}} variant="rounded">
                <DomainOutlinedIcon />
            </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Public" secondary="everyone automatically joins the dataset​" />
          </ListItem> */}

        </List>
        
           
      </DialogContent>
      
          
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
  );
}