import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './style.css';
import { Box, Button, CircularProgress, IconButton, InputBase, Paper, Portal, Snackbar } from '@material-ui/core';
import { API_URL } from 'Context/GlobalState';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import { NotificationCheckIcon } from 'Components/Icon';
import { HtmlTooltip } from 'Components/Sustainability';

function FinalSubmissions(props) {

    const [loadingSubmission1, setLoadingSubmission1] = useState(false);
    const [loadingSubmission2, setLoadingSubmission2] = useState(false);
    const [submissions, setSubmissions] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [submission1Disabled, setSubmission1Disabled] = useState(true);
    const [submission2Disabled, setSubmission2Disabled] = useState(true);

    const {dataset} = props
    const expIdRef1 = useRef(null)
    const expIdRef2 = useRef(null)
    const cycleRef1 = useRef(null)
    const cycleRef2 = useRef(null)



    console.log("FinalSubmissions-props", props)
    console.log("FinalSubmissions-submissions", submissions)
    
    
    const getSubmission = () => {
        setLoadingSubmission1(true);
        setLoadingSubmission2(true);
        const token = localStorage.getItem("_key_usr_tkn");
        const config = {
        method: "get",
        url: `${API_URL}/submit/final/${dataset}/`,
        headers: {
            Authorization: `Token ${token}`,
        },
        };
        axios(config)
        .then((res) => {
            const data = res.data;
            console.log("FinalSubmissions-get", data?.results, dataset);
            if (data?.results) {
                setSubmissions([...data.results])
            }
            
            setLoadingSubmission1(false);
            setLoadingSubmission2(false);

        })
        .catch((error) => {
            console.log("CATCH", error);
            if (error.response) {
            setError("Please make sure your credentials are correct.")
            } else {
            
            setError("Make sure you are connected to the internet.")
            }
            setLoadingSubmission1(false);
            setLoadingSubmission2(false);
        });
    };


    const submitSubmission1 = (e) => {

        console.log(e.currentTarget, expIdRef1.current.value)
        let data = {
            "experiment": expIdRef1.current.value,
            "cycle": cycleRef1.current.value
        };
        setLoadingSubmission1(true);
        const token = localStorage.getItem("_key_usr_tkn");
        const submission = submissions.length > 0 ? `${submissions[0].id}/` : ''
        const config = {
            method: submissions.length > 0 ? "PUT" : "POST",
            url: `${API_URL}/submit/final/${dataset}/${submission}`,
            headers: {
                Authorization: `Token ${token}`,
            },
            data: data
        };
        axios(config)
        .then((res) => {
            const data = res.data;
            console.log("FinalSubmissions-get", data, dataset);
            // setCollaboration({ ...data })
            // updateCollaboration({...data})
            setSuccess(true)
            setError(null)
            setLoadingSubmission1(false);
            getSubmission()
        })
        .catch((error) => {
            console.log("CATCH", error.response.data);
            if (error.response && error.response.data) {
                setError(error.response.data)
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoadingSubmission1(false);
        });


    };


    const handleSubmission1Disabled = () => {
        const exp  = expIdRef1.current.value.length
        const cycle = cycleRef1.current.value.length
        console.log((exp < 1), cycle)
        setSubmission1Disabled((exp < 1 || cycle < 1))
        
    }


    const submitSubmission2 = (e) => {

        console.log(e.currentTarget, expIdRef2.current.value)
        let data = {
            "experiment": expIdRef2.current.value,
            "cycle": cycleRef2.current.value
        };
        setLoadingSubmission2(true);
        const token = localStorage.getItem("_key_usr_tkn");
        const submission = submissions.length > 1 ? `${submissions[1].id}/` : ''
        const config = {
            method: submissions.length > 1 ? "PUT" : "POST",
            url: `${API_URL}/submit/final/${dataset}/${submission}`,
            headers: {
                Authorization: `Token ${token}`,
            },
            data: data
        };



        axios(config)
        .then((res) => {
            const data = res.data;
            console.log("FinalSubmissions-get", data, dataset);
            // setCollaboration({ ...data })
            // updateCollaboration({...data})
            setSuccess(true)
            setError(null)
            setLoadingSubmission2(false);
            getSubmission()
        })
        .catch((error) => {
            console.log("CATCH", error.response.data);
            if (error.response && error.response.data) {
                setError(error.response.data)
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoadingSubmission2(false);
        });


    };


    const handleSubmission2Disabled = () => {
        const exp  = expIdRef2.current.value.length
        const cycle = cycleRef2.current.value.length
        console.log((exp < 1), cycle)
        setSubmission2Disabled((exp < 1 || cycle < 1))
    }
    
    useEffect(() => {
        getSubmission()
    }, [])
    
    const errorMessages = () => {
        // Check if the 'experiment' key exists in the error data
        let errorList = null
        if (error.experiment) {
            errorList = (
            error.experiment.map((message, index) => (
                <p style={{ textAlign: 'left'}} key={index}>experiment: {message}</p>
            ))
          );
        }
    
        // Check if the 'cycle' key exists in the error data
        if (error.cycle) {
            const cycleList = (
                error.cycle.map((message, index) => (
                  <p style={{ textAlign: 'left'}} key={index}>cycle: {message}</p>
                ))
            );
            errorList = [errorList, ...cycleList]
        }

        if (error.non_field_errors) {
            const cycleList = (
                error.non_field_errors.map((message, index) => (
                  <p style={{ textAlign: 'left'}} key={index}>{message}</p>
                ))
            );
            errorList = [errorList, ...cycleList]
        }
    
        // If none of the expected keys are found, display a generic error message
        return (
            errorList ? errorList : <p style={{ textAlign: 'left'}}>An unknown error occurred.</p>
        );
      }
    
    useLayoutEffect(() => {
        expIdRef1.current.value = submissions.length > 0 ? submissions[0].experiment_key : ""
        cycleRef1.current.value = submissions.length > 0 ? submissions[0].cycle : ""
        expIdRef2.current.value = submissions.length > 1 ? submissions[1].experiment_key : ""
        cycleRef2.current.value = submissions.length > 1 ? submissions[1].cycle : ""
    }, [submissions])
    
    return (
        <>
            <Box title={"Select up to two models for the final submission"} className="final-submission-box">
                
                <HtmlTooltip
                    title={<span>'Select up to two models for the final submission'</span>}>
                        <div className="title">Final Submission</div>
                </HtmlTooltip>
                    <Paper
                    variant="outlined"
                    className="cycle-input"
                    style={{
                        borderColor: submission1Disabled ? "#C7D2D5" : "#3576a7",
                        marginRight: "16px"
                    }}
                    elevation={1}
                    >
                        <HtmlTooltip
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Experiment Key
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Go to on the Experiments tab and click on the experiment title to copy the experiment key.
                            </div>
                          </div>
                        }
                      >
                        <InputBase
                            onChange={handleSubmission1Disabled}
                            // onKeyDown={onInputKeyPress}
                            onClick={()=> expIdRef1.current.focus()}
                            inputRef={expIdRef1}
                            placeholder="exp ID"
                                inputProps={{
                                    "aria-label": "exp ID",
                                    style: {
                                        maxWidth: "55px",
                                        borderRight: submission1Disabled ? "1px solid #C7D2D5" : "1px solid #3576a7",
                                        height: "1.140em",
                                        zIndex: "400",
                                    }
                                }}
                            // style={{
                            //     borderRight: submission1Disabled ? "1px solid #C7D2D5" : "1px solid #3576a7",
                            // }}
                        // disabled={userId?.id !== user.user_id}
                            />
                        </HtmlTooltip>
                        
                        <HtmlTooltip
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Cycle Number
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Enter the cycle number of the experiment you selected.
                            </div>
                          </div>
                        }
                      >
                    <InputBase
                        onChange={handleSubmission1Disabled}
                        // onKeyDown={onInputKeyPress}
                        placeholder="cycle"
                        inputRef={cycleRef1}
                        inputProps={{ min: 1, type: "number", "aria-label": "cycle", style: { maxWidth: "25px", zIndex: "400",} }}
                        // disabled={userId?.id !== user.user_id}
                            />
                            </HtmlTooltip>

                    <Button
                        onClick={submitSubmission1}
                        className="cycle-btn"
                        disabled={submission1Disabled}
                    >
                            {loadingSubmission1 ? <CircularProgress style={{ color: "#fff" }} size={12} /> : ``}
                            {!loadingSubmission1 && ((submissions.length > 0 ) ? `Update` : `Submit`)}
                            
                    </Button>

                    </Paper>
                
                
                
               

                    <Paper
                    variant="outlined"
                    className="cycle-input"
                    style={{
                    borderColor: submission2Disabled ? "#C7D2D5" : "#3576a7",
                    }}
                    elevation={1}
                    >
                        <HtmlTooltip
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Experiment Key
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Go to on the Experiments tab and click on the experiment title to copy the experiment key.
                            </div>
                          </div>
                        }
                      >
                        <InputBase
                            onChange={handleSubmission2Disabled}
                            // onKeyDown={onInputKeyPress}
                            inputRef={expIdRef2}
                            placeholder="exp ID"
                                inputProps={{
                                    "aria-label": "exp ID",
                                    style: {
                                        maxWidth: "55px",
                                        borderRight: submission1Disabled ? "1px solid #C7D2D5" : "1px solid #3576a7",
                                        height: "1.140em",
                                        zIndex: "400",
                                    }
                                }}
                            // defaultValue={submissions.length > 1 ? submissions[1].cycle : ""}
                            // style={{
                            //     borderRight: submission2Disabled ? "1px solid #C7D2D5" : "1px solid #3576a7",
                            // }}
                            // disabled={userId?.id !== user.user_id}
                            />
                        </HtmlTooltip>
                        

                        <HtmlTooltip
                        title={
                          <div className="sustain-tooltip">
                            <div className="tooltip-sustain-title">
                              Cycle Number
                            </div>
                            <div className="tooltip-sustain-sub-title">
                              Enter the cycle number of the experiment you selected.
                            </div>
                          </div>
                        }
                      >
                        <InputBase
                                onChange={handleSubmission2Disabled}
                                // onKeyDown={onInputKeyPress}
                                placeholder="cycle"
                                inputRef={cycleRef2}
                                inputProps={{ min: 1, type: "number", "aria-label": "cycle", style: { maxWidth: "25px", zIndex: "400",} }}
                                // defaultValue={submissions.length > 1 ? submissions[1].cycle : ""}
                                // disabled={userId?.id !== user.user_id}
                            />
                            </HtmlTooltip>

                    <Button
                        onClick={submitSubmission2}
                        className="cycle-btn"
                        disabled={submission2Disabled}
                    >
                            {loadingSubmission2 ? <CircularProgress style={{ color: "#fff" }} size={12} /> : ``}
                            {!loadingSubmission2 && ((submissions.length > 1 ) ? `Update` : `Submit`)}
                    </Button>

                    </Paper>

                
                

            </Box>
            <Portal>
                {success && (
                    <Snackbar
                        open={success}
                        autoHideDuration={3000}
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        onClose={()=> setSuccess(null)}
                    >
                    <Alert
                        iconMapping={{
                        success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
                        }}
                        variant="filled"
                        severity="success"
                    >
                        Inference Submitted successfully.
                    </Alert>
                    </Snackbar>
                )}

                {error && (
                    <Snackbar
                        open={error}
                        autoHideDuration={3000}
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        onClose={()=> setError(null)}
                    >
                    <Alert
                            // iconMapping={{
                            // success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
                            // }}
                            icon={false}
                            variant="filled"
                            severity="error"
                        >
                            {errorMessages()}
                        </Alert>
                    </Snackbar>
                )}

            </Portal>
        </>
    );
}

export default FinalSubmissions;