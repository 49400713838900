import { Box, Grid, Paper, } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css'


import {Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart} from 'recharts';
import { GlobalContext } from '../../../Context/GlobalState';
import { numberWithCommas } from '../../../utils/format';
import { generateGradient } from 'utils/gradient';

const CustomizedAxisTick = (props) => {
    const {
      x, y, stroke, payload,
    } = props;

    console.log("CustomizedAxisTick",  payload.value.split(' - ')[1]);

    return (
      <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={6} textAnchor="end"
                style={{ fontSize: '12px', fontWeight: '400', fontFamily: '"Roboto", Arial' }}
                fill="#3576a7"
                transform="rotate(-60)">
                {payload.value.split(' - ')[1]} mm
            </text>
      </g>
    );
}

const CustomizedMedicalAxisTick = (props) => {
    const {
      x, y, stroke, payload,
    } = props;

    console.log("CustomizedAxisTick",  payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={6} textAnchor="end"
                style={{ fontSize: '12px', fontWeight: '400', fontFamily: '"Roboto", Arial' }}
                fill="#3576a7"
                transform="rotate(-60)">
                {payload.value}
            </text>
      </g>
    );
}


const CustomizedYAxisTick = (props) => {
    const {
      x, y, stroke, payload,
    } = props;
    return (
      <g transform={`translate(${x},${y})`}>
            <text x={1} y={0} dy={2} textAnchor="end"
                style={{ fontSize: '12px', fontWeight: '400', fontFamily: '"Roboto", Arial' }}
                fill="#3576a7"
                >
                { numberWithCommas(payload.value)}
            </text>
      </g>
    );
}

const renderLegend = (props) => {
    const { payload } = props;
    let ids = payload.map(o => o.dataKey);
    let filtered = payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
    return (
        <Paper className="defect-bar-legend" variant="outlined">
            {
                filtered && filtered.map((entry, index) => {
                    if(props.checkAvailability[entry.value]) {
                        return <span className={entry.value} key={`item-${index}`}>{entry.value}</span>
                    }
                    return null;
                })
            }
        </Paper>
    );
}


// const renderToolTip = (props) => {
//     const { payload, label } = props;
//     let ids = payload && payload.map(o => o.dataKey);
//     let filtered = payload && payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
//     const splitLabel = label && label.split(' - ');
//     return (
//         <Paper className="defect-bar-tooltip" variant="outlined">
//             <div className="bold-700-12 label-margin">Label: { splitLabel && splitLabel[0]}mm - { splitLabel && splitLabel[1]}mm</div>
//             {
//                 filtered && filtered.map((entry, index) => {
//                     return (
//                         <span className={entry.name} key={`item-${entry.name}`}>
//                             {entry.name}: { entry.value }
//                         </span>
//                     )
//                 })
//             }
//         </Paper>
//     );
// }

const CustomTooltip = ({ active, payload, label, total }) => {
    if (active && payload && payload.length) {
        console.log("renderToolTip-entry", payload, label, total)
      return (
          <Paper className="defect-bar-tooltip" variant="outlined">
              <div style={{ color: "#3576a7" }} className={label} key={`item-${label}`}>
                <span style={{ color: "inherit", fontWeight: "500" }}>Class: </span><span style={{ color: "inherit", }}>{`${label}`}</span>
              </div>

              <div style={{ color: "#3576a7" }} className={payload[0].name} key={`item-${label}-value`}>
              <span style={{ color: "inherit", fontWeight: "500" }}>Count: </span><span style={{ color: "inherit",  }}>{`${payload[0].value} (${((payload[0].value/total)*100).toFixed(2)}%)`}</span>
              </div>
        </Paper>
      );
    }
  
    return null;
  };



// const renderMedicalToolTip = (props) => {
//     const { payload, label } = props;
//     let ids = payload && payload.map(o => o.dataKey);
//     let filtered = payload && payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
//     // const splitLabel = label && label.split(' - ');
    
//     return (
//         <Paper className="defect-bar-tooltip" variant="outlined">
//             <div className="bold-700-12 label-margin">Label: { label }</div>
//             {
//                 filtered && filtered.map((entry, index) => {
//                     return (
//                         <span className={entry.name} key={`item-${entry.name}`}>
//                             {entry.name}: { entry.value }
//                         </span>
//                     )
//                 })
//             }
//         </Paper>
//     );
// }


const TotalClassDistributionTab = (props) => {

    const { dataset, dataset: { count, dataset_type, total } } = props;
    const { sidebar, state: { dataset: { amplify }} } = useContext(GlobalContext);
    const { open } = sidebar;
    const [groups, setGroups] = useState([]);

    const [range, setRange] = useState({ lowest: 0, highest: 0, median: 0, mean: 0, mode: 0 });
    const [legendFilter, setLegendFilter] = useState([])
    const [colors, setColors] = useState(["#3576A7"]);

    useEffect(() => {
        const filterLegend = [];
        for(let key in count?.defect) {
            if (count.defect[key] > 0) {
                filterLegend[key] = 1;
            }
        }
        setLegendFilter(filterLegend);

        if (props.defect !== "all") {
            const filteredGroups = dataset.groups && dataset.groups.map((group) => {
                return {
                    name: group.name,
                    [props.defect]: group[props.defect] * amplify /// This will be removed when the data gets large enough
                };
            }).filter((group) => group[props.defect]) // filter out NaN values
            setGroups(filteredGroups);
        } else {
            /// This will be removed when the data gets large enough
            const filteredGroups = dataset.groups && dataset.groups.map((group) => {

                let amplifiedGroup = { ...group };
                delete amplifiedGroup.name
                
                for(let key in amplifiedGroup) {
                    amplifiedGroup[key] = amplifiedGroup[key] * amplify

                    console.log("amplifiedGroup", group, amplifiedGroup[key], key, amplifiedGroup, amplify);
                }
                return {
                    name: group.name,
                    ...amplifiedGroup
                };
            })
            //setGroups(dataset.groups);
            console.log('diseases-ONE-filteredGroups', filteredGroups)
            setGroups(filteredGroups)
        }

        if (count?.stats) {
            setRange({ ...range, ...count?.stats[props.defect]});
        }
        if (count?.label) {
            const data = count?.label
            console.log("COUNT-AUTO", data)
            const objects = Object.keys(data).map(key => ({ name: key, value: data[key] }))
            console.log("COUNT-AUTO-ob", objects)
            setGroups(objects)
        }

    }, [])

    useEffect(() => {
        setColors(generateGradient('#4BBDCC', '#3576A7', groups?.length))
    }, [groups])


    console.log("DATASET-COUNT", props);
    return (
        <Grid container direction="column" justify="flex-start">
            <Box mt={count?.defect ? 0 : 2} pl={0} pr={2} pb={1} className="inner-content">
                {/* <span style={{ marginLeft: '30px'}} className="bold-400-12 label-margin">Defects: {defectPercent}% of total, {numberWithSpaces(dataset.total * dataset.amplify)} images</span> */}
                <ResponsiveContainer width={open? '99.8%' : '100%'} height="90%">
                    <ComposedChart data={groups} margin={{ top: 20, bottom: 5, }}>
                        <defs>
                            <linearGradient
                                id="colorUv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="100%"
                                spreadMethod="reflect">
                                <stop offset="0" stopColor="#4BBDCC" />
                                <stop offset="1" stopColor="#233A87" />
                            </linearGradient>
                        </defs>

                        <CartesianGrid vertical={false} strokeDasharray="3 3" />
                        
                        <XAxis
                            padding={{ left: 10, right: 0 }}
                            axisLine={false}
                            tickLine={true}
                            dataKey="name"
                            height={90}
                            interval={dataset_type === "industrial" ? 3 : 0}
                            tick={dataset_type === "industrial" ? <CustomizedAxisTick /> : <CustomizedMedicalAxisTick /> } />
                        
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            tick={<CustomizedYAxisTick />} />
                        
                        <Tooltip content={<CustomTooltip total={total} />} />

                        <Legend
                            checkAvailability={legendFilter}
                            wrapperStyle={{ width: '92px', display: count?.label ? '' : 'none' }}
                            verticalAlign="top" align="right"
                            iconType="plainline"
                            content={renderLegend} />
                        <Bar barSize={35} dataKey="porosity" stackId="a" fill="#a4debe" />
                        <Bar barSize={35} dataKey="inclusion" stackId="a" fill="#ffc47f" />
                        <Bar barSize={35} dataKey="crack" stackId="a" fill="#8d6e97" />

                        
                        <Bar barSize={35} dataKey="value" stackId="a" fill="#ffc47f" >
                            {

                                groups && groups.map((entry, index) => {
                                    console.log("ENTRY", entry)
                                    // const color = entry.name === 'none' ? "#a4debe" : "#8d6e97";
                                    return <Cell key={`cell-${index}`} fill={colors[index]} />;
                                })
                            }
                        </Bar>
                        {/* <Line dot={false} type='basis' dataKey='crack' stroke='rgba(72, 3, 85, 1)' strokeWidth={2} />
                        <Line dot={false} type='basis' dataKey='inclusion' stroke='rgba(255, 167, 0, 1)' strokeWidth={2} />
                        <Line dot={false} type='basis' dataKey='porosity' stroke='rgba(93, 211, 158, 1)' strokeWidth={2} /> */}
                        
                    </ComposedChart>
                </ResponsiveContainer>
            
                {dataset_type !== "industrial" ? null :
                    <Grid container style={{ paddingLeft: '10px', fontSize: '10px' }} justify="space-between">
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Median: {range.median}</span>
                        </Grid>
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Range: { range.lowest } - { range.highest }</span>
                        </Grid>
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Means: {range.mean}</span>
                        </Grid>
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Mode per Defect: { range.mode.toString()}</span>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Grid>
    );
}

export default TotalClassDistributionTab;

