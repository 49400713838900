import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";

export default function CollaborationChip({ icon, text, marginLeft, marginRight=0, marginTop=0 }) {
  return (
    <Box
      style={{
        display: "flex",
        marginLeft: marginLeft ? marginLeft : 0,
        marginTop,
        marginRight: marginRight ? marginRight : 0,
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          // padding: 1,
          borderWidth: "0.76px",
          borderRadius: 2,
          borderColor: "#E2E2E2",
          borderStyle: "solid",
        }}
      >
        {icon}
        <Divider light orientation="vertical" flexItem />
        <Typography
          style={{
            fontSize: "10px",
            marginLeft: 4,
            marginRight: 3.5,
            letterSpacing: "0px",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
