import {
    makeStyles,
    ButtonGroup as MaterialButtonGroup
  } from "@material-ui/core";
  
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "12px",
        "& > button": {
            borderRadius: "5px",
            padding: "6px 12px",
            backgroundColor: "#FFFFFF",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            minWidth: "45px",
            boxShadow: "none",
            "& > span": {
                textAlign: "left",
                fontWeight: "normal",
                fontStyle: "normal",
                fontSize: "10px",
                lineHeight: "13px",
                color: "#909B9D",
                textTransform: "none",
                
            },
        },
        "& > button:hover": {
            backgroundColor: "#3576A7",
            color: "#fff",
            textTransform: "none",
            border: "1px solid #3576A7",
            boxShadow: "none",
            "& > span": {
                color: "#fff",
            },

        },
        "& > button.selected": {
            backgroundColor: "#3576A7",
            border: "none",
            "& > span": {
                color: "#F7F7F7",
            }
        },
        "& > *:not(:last-child)": {
            marginRight: "4px"
        }
    }
}));
  
  
  export const ButtonGroup = ({ children, style }) => {
    const classes = useStyles();
    return <div style={{...style}} className={classes.root}>{children}</div>;
  };
  