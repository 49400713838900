import { Grid, Tooltip } from '@material-ui/core';
import { ClockCountIcon, ClockIcon } from 'Components/Icon';
import React, { useEffect, useState } from 'react';
import { daysRemaining, daysRemaining_with_time, formatDate } from 'utils/format';
import './style.css';

function TimeRemaining(props) {
    const [remaining, setRemaining] = useState(formatDate(new Date(props.end_date)));
    const [completeRemainingDate, setCompleteRemainingDate] = useState(formatDate(new Date(props.end_date)))
    
    const timer = () => {
        let remainingNew = daysRemaining_with_time(props.end_date, Date.now());
        remainingNew = remainingNew?.days === "NaNd"
            ? props.end_date
            :
            `${remainingNew?.days === "0d" ? "" : remainingNew?.days + " " }${remainingNew?.hours === "0h" ? "" : remainingNew?.hours + " " }${remainingNew?.minutes} ${remainingNew?.seconds}`;
        setRemaining(remainingNew || props.end_date)
        setCompleteRemainingDate(daysRemaining(props.end_date, Date.now()))
    }

    useEffect(() => {
        if (props?.status !== "In Progress") {
            return
        }
        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [remaining]);

    // console.log("REMAINING", completeRemainingDate)

    return (
        <Tooltip title={`Remaining time: ${completeRemainingDate.days} ${completeRemainingDate.hours} ${completeRemainingDate.minutes}​`}>
            <Grid container className='time-remaining'>
                { props?.status === "In Progress" ?
                    <ClockCountIcon className='icon' style={{ width: '24px', height: '24px' }} />
                    :
                    <ClockIcon className='icon' style={{ width: '24px', height: '24px' }} /> 
                }
                <span className="time-counter">{remaining}</span>
            </Grid>
        </Tooltip>
    );
}

export default TimeRemaining;