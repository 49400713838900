import { Avatar, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, GridList, IconButton, InputBase, Paper, Portal, Slider, Snackbar, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import {splitNParts, Defects, defectStandards, tubeVoltage, machineParts, material } from '../../../utils';
import { spacing } from '@material-ui/system';
import CustomCheckBox from '../../CustomCheckBox';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


import { numberWithSpaces } from '../../../utils/format';
import { GlobalContext } from '../../../Context/GlobalState';
import { round } from '../../../utils/DefectGrouping';
import { makeStyles } from '@material-ui/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyIcon } from '../../Icon';
  
const SingleRow = ({ title, total, ok, nok, onlyText, okTitle, nokTitle, color='', margin=''}) => {
    
    const totalImages = total && numberWithSpaces(total);
    const okImages = ok && numberWithSpaces(ok);
    const nokImages = nok && numberWithSpaces(nok);
    
    return !onlyText ? (
        <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid xs={3} item style={{ textAlign: 'left' }}>
                <span style={{ color, margin }} className={`grey-italic-text`}>{title}</span>
            </Grid>
            <Grid xs={3} item>
                <span style={{ color, margin }}  className="grey-italic-text">{totalImages}</span>
            </Grid>
            <Grid xs={3} item>
                <span style={{ color, margin }}  className="grey-italic-text">{okImages}</span>
            </Grid>
            <Grid xs={3} item>
                <span style={{ color, margin }}  className="grey-italic-text">{nokImages}</span>
            </Grid>
        </Grid>
    ) : (
        <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid xs={3} item style={{ textAlign: 'left' }}>
                
            </Grid>
            <Grid xs={3} item>
                <span className="grey-italic-text">{title}</span>
            </Grid>
            <Grid xs={3} item>
                <span className="grey-italic-text">{okTitle}</span>
            </Grid>
            <Grid xs={3} item>
                <span className="grey-italic-text">{nokTitle}</span>
            </Grid>
        </Grid>
    ) 
}

const MultiColumnRow = ({ title, total, color='', margin='', whiteSpace=''}) => {
    const totalImages = total;
    const [copied, setCopied] = useState(false);

    const showCopyMessage = (e) => {
        setCopied(true)
        console.log("Copied")
        setTimeout(() => {
            setCopied(false);
        }, 2000)
    }

    return (
        <>
            <Grid container direction="row" alignItems="center" justify="space-between">
                <Grid item style={{ textAlign: 'left', width: 'calc(50%)', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span style={{ whiteSpace, color, margin }} className="grey-italic-text">{title}</span>
                </Grid>
                <Grid item style={{ textAlign: 'right', width: 'calc(50%)' }}>
                    <span style={{ margin }} className="grey-italic-text">{totalImages}</span>
                    <CopyToClipboard text={totalImages}
                        onCopy={() => showCopyMessage()}>
                        <IconButton style={{ padding: 12, marginLeft: 2 }}>
                            <CopyIcon color="primary" className="icon" style={{ fontSize: 16 }} />
                        </IconButton>
                    </CopyToClipboard>
                </Grid>
            </Grid>

            <Portal>
                <Snackbar
                    open={copied}
                    autoHideDuration={6000}
                    onClose={()=> setCopied(false)}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                    <Alert variant="filled" severity="success">
                        {`Parameter copied successfully`}
                    </Alert>
                </Snackbar>

            </Portal>
        </>
        
    ) 
}


const useStyles = makeStyles((theme) => ({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectType: {
        
        "& .MuiSelect-outlined": {
            // padding: "2px",
            paddingBottom: '0px',
            paddingTop: '0px',
            paddingLeft: '0px',
            fontSize: '12px',
            color: '#3576a7',
            textTransform: 'capitalize'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        }
      }
  }));


const TrainingParametersTab = ({ isSaving, ...props }) => {
    const { dataset, dataset: { dataset_type } } = props;
    
    return (
        <>
            <Grid container direction="column" justify="flex-start">
                <Box mt={2} pl={2} pr={2} pt={2} pb={1} className="inner-content" style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <div className="grey-italic-text mt-8">Training Paramteres that you can use in your experiment.</div> */}
                    <Box mt={3} pb={1} style={{ overflowY: 'auto' }}>
                        
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" wrap>
                            <Grid item xs={12} title="Input Shape">
                                <MultiColumnRow
                                    color={'rgba(53, 118, 167, 1)'}
                                    key={"input-shape"}
                                    title="Input Share"
                                    total={ dataset_type === "industrial" ? "48x48" : "224x224"} />
                            </Grid>

                            <Grid item title="Model Classes" xs={12}>
                                <MultiColumnRow
                                    color={'rgba(53, 118, 167, 1)'}
                                    key={"model-classes"}
                                    title="Model Classes"
                                    total={Object.keys(dataset?.count?.label).length} />
                            </Grid>

                            <Grid item title="Category" xs={12}>
                                <MultiColumnRow
                                    color={'rgba(53, 118, 167, 1)'}
                                    key={"category"}
                                    title="Category"
                                    total="Classification" />
                            </Grid>

                            <Grid item title="Channels" xs={12}>
                                <MultiColumnRow
                                    color={'rgba(53, 118, 167, 1)'}
                                    key={"channels"}
                                    title="Channels"
                                    total="3" />
                            </Grid>
                        </Grid>

                    </Box>
                   
            </Box>
        </Grid>

        </>

    );
}


export default TrainingParametersTab;