import React from "react";
import "./style.css";
import NoteBook from "../../NoteBook";

export default function DataAnalysis({dataset_eda_pdf}) {

  console.log("NOTEBOOK", dataset_eda_pdf);

  return (
      <NoteBook json={dataset_eda_pdf} />
  );
}
