import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Portal,
  Snackbar,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import avatar from "assets/pexelsPixabay220453318c14b7.png";

import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { ColabIcon, NotificationCheckIcon, NotificationIcon } from "Components/Icon";
import { API_URL, GlobalContext } from "Context/GlobalState";
import { nFormatter, numberWithCommas } from "utils/format";
import { Alert } from "@material-ui/lab";
import NotificationItem from "./NotificationItem";
import axios from "axios";

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#FFFFFF",
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #FFFFFF",
    borderRadius: "2px",
  },
}))(Tooltip);

const HtmlNotiTooltip = withStyles((theme) => ({
  arrow: {
    color: "#FFFFFF",
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 273,
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #FFFFFF",
    borderRadius: "2px",
  },
}))(Tooltip);



const UPDATE_NOTIFICATIONS_INTERVAL = 8000;
let cancelExpToken, cancelTimeToken;


function Notifications(props) {
  const { user } = useContext(GlobalContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [notificationsList, setNotificationsList] = useState({
    unread_count: 0,
    count: 0,
  })

  const handleTooltipClose = () => {
    props.handleNotifications(!props.open);
  };

  console.log(props);



  const getNotifications = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    if (typeof cancelExpToken != typeof undefined) {
      cancelExpToken.cancel("Operation canceled due to new request.");
    }
    
    cancelExpToken = axios.CancelToken.source();
    const config = {
      method: "get",
      url: `${API_URL}/notifications/`,
      headers: {
        Authorization: `Token ${token}`,
      },
      cancelToken: cancelExpToken.token, //Pass the cancel token to the current request
    };

    axios(config)
      .then((res) => {
        let data = res.data;

        setNotificationsList({...data})
      })
      .catch((error) => {
        console.log("CATCH", error);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };


  useEffect(() => {
    let intervalDetails;
    getNotifications();
    const token = localStorage.getItem("_key_usr_tkn");
    intervalDetails = setInterval(() => {
      if (token) {
        getNotifications();
      }
      // console.log('getNotifications-token', token);
      // The logic of changing counter value to come soon.
    }, UPDATE_NOTIFICATIONS_INTERVAL);
    
    return () => {
      clearInterval(intervalDetails);
    };
  }, []);


  return (
    <>
      {/* <ClickAwayListener onClickAway={handleTooltipClose}> */}
        <HtmlNotiTooltip
          arrow
          interactive
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={props.open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        title={
          notificationsList.count > 0 ?
            notificationsList.results.map((item) => <NotificationItem {...item} />)
            :
            <div className="notifications-tooltip">No new notifications</div>
          }
      >
        <Tooltip title={`Notifications`}>
          <IconButton
            className="notifications-button"
            onClick={handleTooltipClose}
            variant="outlined"
          >
            <Badge badgeContent={notificationsList.unread_count} color="error">
              <NotificationIcon active={props.open} />
            </Badge>
          </IconButton>
          </Tooltip>
        </HtmlNotiTooltip>
      {/* </ClickAwayListener> */}
    </>
  );
}

export default Notifications;
