import { Box, Grid, Paper } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css'

import {PieChart, Pie, Cell, Tooltip, ResponsiveContainer, LabelList} from 'recharts';
import { GlobalContext } from '../../../Context/GlobalState';
import { generateGradient } from 'utils/gradient';



const CustomTooltip = ({ active, payload, label, total }) => {
    if (active && payload && payload.length) {
        console.log("renderToolTip-entry", payload, label, total)
      return (
          <Paper className="defect-bar-tooltip" variant="outlined">
              <div style={{ color: "#3576a7" }} className={payload[0].name} key={`item-${payload[0].name}`}>
                <span style={{ color: "inherit", fontWeight: "500" }}>Class: </span><span style={{ color: "inherit",  }}>{`${payload[0].name}`}</span>
              </div>

              <div style={{ color: "#3576a7" }} className={payload[0].name} key={`item-${payload[0].name}-value`}>
              <span style={{ color: "inherit", fontWeight: "500" }}>Count: </span><span style={{ color: "inherit",  }}>{`${payload[0].value} (${((payload[0].value/total)*100).toFixed(2)}%)`}</span>
              </div>
        </Paper>
      );
    }
  
    return null;
  };
  
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "middle" : "middle"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };



const RelativeClassDistributionTab = (props) => {

    const { dataset, dataset: { count, total } } = props;
    const { sidebar, state: { dataset: { amplify }} } = useContext(GlobalContext);
    const { open } = sidebar;
    const [classes, setClasses] = useState([]);
    const [colors, setColors] = useState(["#3576A7"]);
    

    useEffect(() => {

        const data = []

        if (dataset?.label_density) {
            Object.entries(dataset?.label_density).forEach(([key, value]) => {
                console.log("class", key, value)
                data.push({ name: key, value: value })
            })
            setClasses(data);
            setColors(generateGradient('#4BBDCC', '#3576A7', data.length));
            
        }

    }, [])


    console.log("RelativeClassDistributionTab", dataset.ok);

  return (
    <Grid container direction="column" justify="flex-start">
      <Box mt={count?.defect ? 0 : 2} pl={0} pr={2} pb={1} className="inner-content">
        {/* <span style={{ marginLeft: '30px'}} className="bold-400-12 label-margin">Defects: {defectPercent}% of total, {numberWithSpaces(dataset.total * dataset.amplify)} images</span> */}
        <ResponsiveContainer width={open ? '99.8%' : '100%'} height="100%">
          <PieChart width={"100%"} height={319} >
            <Pie
              data={classes}
              cx={"100%"/2}
              cy={319/2}
              labelLine={false}
              label={renderCustomizedLabel}
              // label
              outerRadius={120}
              fill="#8884d8"
              dataKey="value">
                <LabelList dataKey="value" position="insideTop" />
                {classes.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
            </Pie>

            <Tooltip content={<CustomTooltip total={total} />} />
                        
        {/* <Legend
            // checkAvailability={legendFilter}
            wrapperStyle={{ width: '100%' }}
            verticalAlign="top" align="right"
            iconType="plainline"
            content={renderLegend} /> */}
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
}

export default RelativeClassDistributionTab;