import { Box, CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './style.css';
import companyImg from '../../../assets/competition_company@2x.png'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/styles';
import { API_URL } from '../../../Context/GlobalState';
import axios from 'axios';
import { nFormatter } from '../../../utils/format';
import imagePlaceHolder from 'assets/avatar.png';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 24,
      borderRadius: 4,
    },
    colorPrimary: {
      backgroundColor: "rgba(112, 112, 112, 1)",
    },
    bar: {
        borderRadius: 4,
        borderBottomRightRadius: 1,
        borderTopRightRadius: 1,
        backgroundColor: 'rgba(75, 189, 204, 1)',
    },
  }))(LinearProgress);

  

const CollaborationPopup = ({ dataset }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [summary, setSummary] = useState(null);

    console.log("DATASET", dataset.host)

    const getTflops = () => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'GET',
            url: `${API_URL}/leaderboard/${dataset.id}/summary/`,
            data: { claps: 1 },
            headers: {
                'Authorization': `Token ${token}`
            },
        };

        axios(config).then((res) => {
            const data = res.data
            console.log("YOU CLAPPED", data)
            setSummary({...data, percentage: (data.company_flops_used/data.company_flops_provided * 100) });
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure you are logged in.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        getTflops()
    }, [])


    const topCandidates = summary && summary.top_candidates_info.map((row, index) => {
        let team = null;
        if (row.is_private) {
            console.log("is_private", row.is_private)
            if (row.team) {
                team = {
                    name: row.team.name,
                    company_logo: imagePlaceHolder,

                }
            } else {
                team = {
                    name: `${row.data_scientist.first_name} ${row.data_scientist.last_name}`,
                    company_logo: imagePlaceHolder,
                }
            }
        } else {
            team = {
                ...row.company,
            }
        }
        
        return (
            <Grid container className="competitor-row">
                <Grid xs={1} item>{index + 1}</Grid>
                <Grid xs={2} item><img src={team?.company_logo} /></Grid>
                <Grid xs={6} item className="competitor-name">{team?.name}</Grid>
                <Grid xs={3} item style={{ textAlign: 'right', color: 'var(--textPrimary)' }}>{(+row.score).toFixed(2)}</Grid>
            </Grid>
        )
    }
)


    return (
        <>
            
            {
            
                loading && <Grid alignContent='center' justifyContent='center' container className="competition-popup">
                    <CircularProgress size={20} />
                </Grid>
            }
            
                
            {
                summary && <div onClick={(e) => e.stopPropagation()} className="competition-popup">
                    <Box mt={2} className="competition-header">
                        <Grid container justify="space-between">
                            <Grid item>Leaderboard</Grid>
                            <Grid item>F1</Grid>
                        </Grid>
                    </Box>
                    
                    <Box mt={1} className="competitors-list">
                        {topCandidates}
                    </Box>

                    <Box mt={2}>
                        <div className="tflops-container">
                            <div className="tflops-progress-text">{nFormatter(summary.company_flops_used)}F of {nFormatter((summary.company_flops_provided))}F utilized</div>
                            <div className="tflops-progress" style={{ width: `${ summary.percentage }%`}} />
                        </div>
                        
                    </Box>
                </div>
            }

            </>
            
    );
}

export default CollaborationPopup;