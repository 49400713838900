import { Box, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import { numberWithSpaces } from '../../../utils/format';
import { GlobalContext } from '../../../Context/GlobalState';

  
const SingleRow = ({ title, total, ok, onlyText, okTitle, nokTitle, style, color='', margin=''}) => {
    
    const totalImages = total && numberWithSpaces(total);
    const okImages = ok && numberWithSpaces(ok);
    return !onlyText ? (
        <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid xs={4} item style={{ textAlign: 'left' }}>
                <span style={{ ...style, color, margin }} className={`grey-italic-text`}>{title}</span>
            </Grid>
            <Grid xs={4} item>
                <span style={{ ...style, color, margin }}  className="grey-italic-text">{totalImages}</span>
            </Grid>
            <Grid xs={4} item>
                <span style={{ ...style, color, margin }}  className="grey-italic-text">{okImages}</span>
            </Grid>
            {/* <Grid xs={3} item>
                <span style={{ color, margin }}  className="grey-italic-text">{nokImages}</span>
            </Grid> */}
        </Grid>
    ) : (
            <Grid container direction="row" alignItems="center" justify="space-between">
                
                <Grid xs={4} item style={{ textAlign: 'left' }}>
                    <span className="bold-700-12">Classes</span>
                </Grid>
                <Grid xs={4} item>
                    <span className="bold-700-12">{title}</span>
                </Grid>
                <Grid xs={4} item>
                    <span className="bold-700-12">{okTitle}</span>
                </Grid>
                
            {/* <Grid xs={3} item>
                <span className="grey-italic-text">{nokTitle}</span>
            </Grid> */}
        </Grid>
    ) 
}

const MultiColumnRow = ({ title, total, color='', margin='', whiteSpace=''}) => {
    const totalImages = total && numberWithSpaces(total);
    return (
        <Grid container direction="row" alignItems="center" justify="space-between">
            <Grid item style={{ textAlign: 'left', width: 'calc(50%)', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span style={{ whiteSpace, color, margin }} className="grey-italic-text">{title}</span>
            </Grid>
            
            <Grid item style={{ textAlign: 'right', width: 'calc(50%)' }}>
                <span style={{ color, margin }}  className="grey-italic-text">{totalImages}</span>
            </Grid>
        </Grid>
    ) 
}





const TotalFiguresTab = ({ isSaving, ...props }) => {
    const { state: { dataset: { amplify }, datasetOptions } } = useContext(GlobalContext);
    const { dataset, dataset: { count, total } } = props;
    const [totals, setTotals] = useState({
        total: total,
        actual: total,
        voltage: [],
        materials: [],
        part: [],
        labels: [],
    });
    
    

    useEffect(() => {
        let filter = 0;
        if (props.label !== "all") {
            for(let key in count?.label) {
                if (key !== props.label) {
                    console.log("KEY-TYPE", key, props.label)
                    if (key !== "none") {
                        filter += count?.label[key]
                    }
                    
                }
            }
            filter = filter * amplify;
        }

        let voltage = [], materials = [], part = [];

        for (let key in count?.voltage) {
            voltage.push({title: `${key}kV`, total: (count?.voltage[key] * amplify) - filter })
        }

        for (let key in count?.material) {
            materials.push({title: `${key}`, total: (count?.material[key] * amplify - filter) })
        }

        for (let key in count?.part) {
            part.push({title: `${key}`, total: (count?.part[key] * amplify) - filter })
        }

        const labels = []
        count.label && Object.entries(count.label).forEach((item) => {
             const [key, value] = item
             labels.push({
                className: key, value: (value * amplify)
            })
        })

        console.log('totals', labels)

        
        setTotals({
            ...totals,
            total: total  - filter,
            actual: ((total) * amplify)  - filter,
            voltage: voltage,
            materials: materials,
            part: part,
            labels: labels,
        });
    }, []);


    
    console.log('totals', totals)
    
    return (
        <>
            <Grid container direction="column" justify="flex-start">
                <Box mt={2} pl={2} pr={2} pb={4} className="inner-content" style={{ display: 'flex', flexDirection: 'column' }}>
                    
                    <Box mt={3} pb={1} pl={2} pr={2} style={{ overflowY: 'auto' }}>
                        <Grid container direction="column" style={{ textAlign: 'right' }}>
                            
                            <SingleRow
                                style={{ fontWeight: "bold" }} color={'rgba(53, 118, 167, 1)'}
                                margin='0px 0px 10px'
                                title={`Total Images: ${dataset?.unique_data_items_count}`}
                                okTitle="" nokTitle="" />

                            <Box pt={2} />
                            
                            <SingleRow onlyText={true} title="%" okTitle="Label count" nokTitle="NOK" />
                            <SingleRow
                                style={{ fontWeight: "bold" }}
                                margin='0px 0px' title="Total"
                                total={100}
                                ok={total}
                                 />


                            {
                                totals.labels.map(item => {
                                    const { className, value } = item;
                                    return (
                                        <SingleRow 
                                            key={`disease-${className}`} 
                                            title={className} 
                                            total={((value/total)*100).toFixed(2)} 
                                            ok={value} 
                                            nok="" />
                                    )
                                })
                                
                            }
                        </Grid>





                        <Grid container direction="row" alignItems="flex-start" justify="space-between">
                            <Grid item style={{ width: 'calc(28%)' }}>
                                {
                                    totals.voltage.map((v) => {
                                        return (
                                            <>
                                                <Grid className="mb-2 mt-8" item><span className="bold-700-12 mt-8">Tube voltage</span></Grid>
                                                <MultiColumnRow
                                                    key={v.title}
                                                    title={v.title}
                                                    total={v.total} />
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item style={{ width: 'calc(35%)', paddingLeft: '16px', paddingRight: '16px' }}>
                                
                                {
                                    totals.materials.map((m) => {
                                        return (
                                            <>
                                                <Grid className="mb-2 mt-8" item><span className="bold-700-12 mt-8">Material</span></Grid>
                                                
                                                <MultiColumnRow
                                                    key={m.title}
                                                    title={m.title}
                                                    total={m.total} />
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item style={{ width: 'calc(36%)'}}>
                                {
                                    totals.part.map((p) => {
                                        return (
                                           
                                            <>
                                                <Grid className="mb-2 mt-8" item><span className="bold-700-12 mt-8">Part</span></Grid>
                                                <MultiColumnRow
                                                whiteSpace='nowrap'
                                                key={p.title}
                                                title={p.title}
                                                total={p.total} />
                                            </>
                                        )

                                    })
                                }
                            </Grid>

                        </Grid>

                    </Box>
            </Box>
        </Grid>

        </>

    );
}


export default TotalFiguresTab;