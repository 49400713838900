import React, { useCallback, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import './style.css'
import RelativeClassPerImageTab from './RelativeClassPerImageTab';
import { TabContext, TabPanel } from '@material-ui/lab';
import { CustomTabs } from '../../CustomTabs/CustomTabs';
import { CustomTab } from '../../CustomTabs/CustomTab';

const RelativeClassPerImage = (props) => {
    const { dataset: { count, dataset_type } } = props;
    let defectsList = [];
    for(let key in count?.defect) {
        if (count.defect[key] > 0) {
            defectsList[key] = count.defect[key];
            defectsList.push({[key]: count.defect[key] })
        }
        // increase the count
    }

    const [tab, setTab] = React.useState("all");
    const handleChange = useCallback((event, newValue) => {
        setTab(newValue);
    });

    useEffect(() => {
        if (dataset_type === "medical") {
            setTab("all");
        }
    }, []);

    return (
        <Grid item xs={4} style={{ maxWidth: "30%", flexBasis: "30%"}} height="100%">
            <TabContext value={tab}>
                <CustomTabs className="custom-tab-container" value={tab} onChange={handleChange} aria-label="ant example">
                    {/* { (defectsList.length > 1) && <CustomTab value="all" label="All" /> } */}
                    {count?.defect && count.defect["crack"] && <CustomTab value="crack" label="crack" />}
                    {count?.defect && count.defect["inclusion"] && <CustomTab value="inclusion" label="inclusion" /> }
                    {count?.defect && count.defect["porosity"] && <CustomTab value="porosity" label="porosity" />}
                    <CustomTab value="all" label="Relative frequency of labels per image" />
                </CustomTabs>
               
                <Paper variant="outlined">
                    
                    {
                        !tab && <div className="tab-panel inner-content" value={"all"}>
                            <RelativeClassPerImageTab {...props} defect={"all"} />
                        </div>
                    }

                    <TabPanel className="tab-panel inner-content" value={"all"}>
                        <RelativeClassPerImageTab {...props} defect={"all"} />
                    </TabPanel>
                    <TabPanel className="tab-panel inner-content" value={"crack"}>
                        <RelativeClassPerImageTab {...props} defect={"crack"} />
                    </TabPanel>
                    <TabPanel className="tab-panel inner-content" value={"inclusion"}>
                        <RelativeClassPerImageTab {...props} defect={"inclusion"} />
                    </TabPanel>
                    <TabPanel className="tab-panel inner-content" value={"porosity"}>
                        <RelativeClassPerImageTab {...props} defect={"porosity"} />
                    </TabPanel>

                    <TabPanel className="tab-panel inner-content" value={"disease"}>
                        <RelativeClassPerImageTab {...props} defect={"disease"} />
                    </TabPanel>
                </Paper>
            </TabContext>
        </Grid>
    );
}

export default RelativeClassPerImage;