import { Box, Button, CircularProgress, Collapse, Grid, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import { spacing } from '@material-ui/system';
import { API_URL, GlobalContext } from '../../../Context/GlobalState';
import axios from 'axios';
import LoginHeader from '../../../Components/LoginHeader';
import Footer from '../../../Components/Footer';
import { useForm } from "react-hook-form";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useLocation, useHistory, Link } from 'react-router-dom';
import OnboardWizard from '../../../Components/OnboardWizard';
import { EmailIcon, FirstNameIcon, LastNameIcon, PasswordHiddenIcon, PasswordIcon, PasswordShowIcon, UserNameIcon } from '../../../Components/Icon';

const COUNT_DOWN_SEC = 30;
const ThankYou = (props) => {
    const { sidebar, user, login, network, getUserInfo } = useContext(GlobalContext);
    
    const location = useLocation()
    let history = useHistory();
    
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [countdown, setCountdown] = useState(COUNT_DOWN_SEC);


    useEffect(() => {
        // Decrease the countdown by 1 every second
        const intervalId = setInterval(() => {
          if (countdown > 0) {
            setCountdown(countdown - 1);
          } else {
            clearInterval(intervalId); // Clear the interval when the countdown reaches 0
          }
        }, 1000);
    
        // Clean up the interval when the component unmounts
        return () => {
          clearInterval(intervalId);
        };
      }, [countdown]);
    


    const resendVerification = () => {
        setLoading(true);

        const config = {
            method: 'GET',
            url: `${API_URL}/resend-verification-link/`,
            headers: {
                'Authorization': `Token ${props.token}`
            },
        };
        axios(config).then((res) => {
            const data = res.data
            setError(null);
            setSuccess(true);
            setLoading(false);
            setCountdown(COUNT_DOWN_SEC)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response.status === 404) {
                setError({
                    type: "required",
                    message: error.response.statusText,
                });
            } else if (error.response && error.response.data) {
                    setError({
                        type: "required",
                        message: "Something went wrong. Please try again in a while.",
                    });
            } else {
                setError({
                    type: "required",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
            // clearErrors()
        });
    }

    const showCountdown = () => {

        return ( countdown > 0 ? (
            <spam>Resend verification in {countdown} seconds</spam>
          ) : (
            <span onClick={resendVerification}>Resend verification mail.</span>
          ))

    }

    console.log('resendVerification-validate-error', error);

    return (
        <Grid className="form" container direction="column">
        
            
           
            <Grid item>
                <h3>Thank you for signing up!</h3>
                <h3 style={{ color: "#212529", marginBottom: "24px" }}>Please check your emails and verify your account.</h3>
            </Grid>
            
            
            
            <Grid item>
               <img style={{marginBottom: "24px"}} src="/images/email_sent.svg" />
            </Grid>
            
            {error && <Grid item><div className='error-message'>{error.message}</div></Grid>}
            { success &&  <Grid item><div className='error-message' style={{ color: 'inherit'}}>Verification mail resent successfully.</div></Grid> }
            <Grid className='resend-verification' item>
                Didn't get the email? Please check your spam/junk folder.
            </Grid>

            <Grid style={{ lineHeight: '22px' }} className='resend-verification' item>
                { !loading ?
                    showCountdown() : <CircularProgress size={16} />
                }
            </Grid>

        </Grid>

    );
}


export default ThankYou;