import { makeStyles } from "@material-ui/core";

export const headingStyle = {
    color: "#3576A7",
    fontSize: "24px",
    letterSpacing: "0px",
    fontStyle: "italic",
  fontWeight: "bold",
  textTransform: "uppercase"
};
  
  export const subHeadingStyle = {
    ...headingStyle,
    fontSize: "20px",
    fontWeight: "500",
    marginTop: "10px",
    textTransform: "none",
  };
  export const descriptionStyle = {
    color: "#333",
    fontSize: "16px",
    lineHeight: '24px',
    letterSpacing: "0px",
    fontStyle: "italic",
    marginTop: "10px",
    whiteSpace: "pre-wrap",
    "& span": {
      color: "#3576A7",
    }
  };


    
export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#333",
    fontSize: "16px",
    lineHeight: '24px',
    letterSpacing: "0px",
    fontStyle: "italic",
    marginTop: "10px",
    fontFamily: "Roboto",
    whiteSpace: "pre-wrap",
    "& span": {
      color: "#3576A7",
    },
    "& h2": {
      color: "#3576A7",
      fontSize: "24px",
      letterSpacing: "0px",
      fontStyle: "italic",
      fontWeight: "bold",
    },
    "& h3": {
      fontWeight: "500",
      marginTop: "10px",
      color: "#3576A7",
      fontSize: "20px",
      letterSpacing: "0px",
      fontStyle: "italic",

    },
    "& ol": {
      marginLeft: "1px",
      paddingLeft: "12px",
      "& span": {
        color: "#707070",
        fontWeight: "bold",
      }
    },
    "& ul": {
      marginLeft: "1px",
      paddingLeft: "12px",
    }
  }
}));
