import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { Grid, Paper } from "@material-ui/core";
import "./style.css";
import { GlobalContext } from "../../../Context/GlobalState";
import DatasetExampleImagesTab from "./DatasetExampleImagesTab";
import { TabContext, TabPanel } from "@material-ui/lab";
import { CustomTabs } from "../../CustomTabs/CustomTabs";
import { CustomTab } from "../../CustomTabs/CustomTab";

const DatasetExampleImages = (props) => {
  const { isSaving } = props;
  const [tab, setTab] = React.useState("all");
  const { sidebar, openSideBar } = useContext(GlobalContext);
  const { open } = sidebar;
  const ref = useRef(null);

  const handleChange = useCallback((event, newValue) => {
    setTab(newValue);
  });

  useEffect(() => {
    if (ref && isSaving) {
      const elementTop = ref.current.offsetTop;
      window.scrollTo(0, elementTop - 255);
    }
  }, [isSaving]);

  console.log("isSaving", isSaving);

  return (
    <Grid
      item
      xs={3}
      style={{ maxWidth: "30%", flexBasis: "30%" }}
      height="100%"
      ref={ref}
    >
      <TabContext value={tab}>
        <CustomTabs
          value={tab}
          onChange={handleChange}
          aria-label="ant example"
        >
          <CustomTab
            value="all"
            style={{ marginLeft: "1px", borderTopLeftRadius: "5px" }}
            label="Data set example images"
          />
        </CustomTabs>
        <Paper variant="outlined">
          <TabPanel className="tab-panel inner-content" value={"all"}>
            <DatasetExampleImagesTab {...props} />
          </TabPanel>
        </Paper>
      </TabContext>
    </Grid>
  );
};

export default DatasetExampleImages;
