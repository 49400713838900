import { Box, Button, CircularProgress, Collapse, Grid, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import { spacing } from '@material-ui/system';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import axios from 'axios';
import Header from '../../Components/LoginHeader';
import Footer from '../../Components/Footer';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { EmailIcon } from 'Components/Icon';

const ForgotPassword = (props) => {
    const { state: { error }, sidebar, user, login, network, getUserInfo } = useContext(GlobalContext);
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const [success, setSuccess] = useState(false);
    const location = useLocation()
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const sendRequest = ({email}) => {
        setLoading(true);

        console.log(email);

        const config = {
            method: 'POST',
            url: `${API_URL}/password_reset/`,
            data: { email },
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("You Archived", data);
            setError(null);
            setSuccess(true);
            setLoading(false)
            // setUserUpdate({...user})
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response.status === 404) {
                setError("error", {
                    type: "manual",
                    message: error.response.statusText,
                });

            } else if (error.response && error.response.data) {
                // setError(error.response.data)
                Object.entries(error.response.data).forEach((value) => {
                    if (["email"].includes(value[0])) {
                        setError(value[0], {
                            type: "manual",
                            message: value[1],
                        });
                    }
                });
                setError("error", {
                    type: "manual",
                    message: "An Error occurred.",
                });
            } else {
                setError("error", {
                    type: "manual",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
        });
    }


    const onSubmit = data => {
        console.log('Onboard-validate', data)
        sendRequest(data)
    };


    return (
        <Box style={{ overflow: "none" }}>
            <Header />
            <Box className="forgot-main">
                
                <Grid className="forgot" container direction="column">
                    
                    {
                        errors.error &&
                        <Grid item>
                            <Alert icon={false} severity="error">aaa{errors.error?.message}</Alert>
                        </Grid>
                    }

                    {
                        
                        success &&
                        <Grid item style={{ marginBottom: '10px'}}>
                            <Alert icon={false} variant="filled" severity="success">We have sent you a reset link. Please check your email.</Alert>
                        </Grid>
                    }

                    <form className='form' onSubmit={handleSubmit(onSubmit)}>

                        <Grid container direction="column">
                            <Grid item>
                                <h3>Forgot your password?</h3>
                                <h3 style={{ color: "#212529", marginBottom: "12px" }}>No problem. We got you covered.​</h3>
                            </Grid>
                            <Grid item>
                                <TextField
                                    inputProps={{
                                        ...register("email", {
                                            required: "Please provide a valid email address.​",
                                            pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Please provide a valid email' }
                                        })
                                    }}
                                    onChange={() => clearErrors()}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon style={{ marginRight: '8px' }} />
                                            </InputAdornment>
                                        )
                                    }}
                                    // onKeyDown={handleKeyDown}
                                    // helperText={ errors.email ? <span className='error'>{errors.email?.message}</span> : null}
                                    fullWidth={true}
                                    margin= 'normal'
                                    className="forgot-email"
                                    placeholder="Enter your email address​"
                                    variant="outlined" />
                                { errors.email && <div className='error-message'>{errors.email?.message}</div>}
                                
                            </Grid>

                            <Button
                                type='submit'
                                size="large"
                                variant="outlined"
                                className="forgot-button"
                                color="primary"
                                classes={{ label: "label" }}
                                // onClick={LoginHandle}
                                disableElevation>
                                {loading ? <CircularProgress size={35} color="inherit" /> : "Request password reset"}
                            </Button>

                        </Grid>
                    </form>
                    


                <div className='terms-of-service'>
                       By creating an account, you agree to the <a href="https://www.tracebloc.io/terms">Terms of Service</a>. For more information about tracebloc’s privacy practices, see the <a href="https://www.tracebloc.io/privacy">tracebloc Privacy Statement</a>. We’ll occasionally send you account-related emails.
                    </div>     
                    
                </Grid>

               
                
            </Box>
            {/* <Footer /> */}
        </Box>
    );
}

export default ForgotPassword;