import { Switch, withStyles } from "@material-ui/core";

const CustomSwitch = withStyles({
  root: {
    width: 30,
    height: 14,
    padding: 4,
    display: 'flex',
    marginLeft: 8,
    marginRight: 8, 
  },  
  switchBase: {
    padding: 0,
    top: 1,
    left: 0,
    color: 'rgba(250, 250, 250, 1)',
    '&$checked': {
      color: 'rgba(255, 255, 255, 1)',
      transform: 'translateX(17px)',
      '& + $track': {
        backgroundColor: '#3576a7',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: `1px solid rgba(230, 230, 230, 1)`
  },
  track: {
    height: 6,
    border: `1px solid rgba(230, 230, 230, 1)`,
    borderRadius: 3,
    opacity: 1,
    backgroundColor: 'rgba(230, 230, 230, 1)',
  },
  checked: {},
})(Switch);
  




const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: '#f7f7f7',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'rgba(53, 118, 167, 1)',
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
        height: 12,
        color: 'rgba(53, 118, 167, 1)',
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #f3f3f3`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#f7f7f7',
    },
    checked: {},
  }))(Switch);




export default CustomSwitch;