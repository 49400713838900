import React from 'react';
import PropTypes from 'prop-types';

import Events from './Events';
import EventLine from './EventLine';
import Faders from './Faders';
import HorizontalTimelineButtons from './HorizontalTimelineButtons';

import Constants from './helpers/Constants';

class EventsBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      position: 0,
      maxPosition: Math.min(props.visibleWidth - props.totalWidth, 0),
    };

  }

  
  render() {
    
    // filled value = distane from origin to the selected event
    const filledValue = this.props.events[this.props.index].distance - this.props.barPaddingLeft;
    const eventLineWidth = this.props.totalWidth - this.props.barPaddingLeft - this.props.barPaddingRight;
    
    const getBGColor = (index) => {
      if (index === 1) {
        return "#C7D2D5"
      } else if (index === this.props.events.length-1) {
        return "#4bbdcc"
      } else {
        return this.props.styles.foreground
      }
    }

    return (
      <div
        style={{
          width: `inherit`, // previous value
          // maxWidth: "1313px",
          height: `${this.props.height}px`,
        }}
      >
        <div
          className='events-wrapper'
          style={{
            position: 'relative',
            height: '100%',
            margin: '0 2px',
            overflow: ''
          }}
        >
            <div
              className='events'
              style={{
                position: 'absolute',
                top: 49,
                height: 5,
                width: this.props.totalWidth,
                // backgroundColor: "#ccc"
              }}
            >

                {
                  this.props.events.map((value, index) => {
                    if (index ===0 || index >= this.props.events.length) {
                      return null
                    }
                    let filledValue_width = (this.props.events[index].distance - ( this.props.events[index-1].distance)) - 20;
                    let filledValue_left = this.props.events[index - 1].distance + 10;
                    
                    console.log("Events-After", index, filledValue_width, filledValue_left);
                      return (
                        <EventLine
                          left={filledValue_left}
                          width={filledValue_width}
                          fillingMotion={this.props.fillingMotion}
                          backgroundColor={getBGColor(index)}
                        />
                      )
                    // }
                   })
                
              }  
              
            <Events
              events={this.props.events}
              daysToGo={this.props.daysToGo}
              selectedIndex={this.props.index}
              styles={this.props.styles}
              handleDateClick={this.props.indexClick}
              labelWidth={this.props.labelWidth}
            />
          </div>
        </div>
         { this.props.showArrowButtons ? <HorizontalTimelineButtons
            maxPosition={this.state.maxPosition}
            position={this.state.position}
            styles={this.props.styles}
            updateSlide={this.updateSlide}
          /> : null }
      </div>
    );
  }
}

EventsBar.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape({
    distance: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  })).isRequired,
  isTouchEnabled: PropTypes.bool.isRequired,
  totalWidth: PropTypes.number.isRequired,
  visibleWidth: PropTypes.number.isRequired,
  index: PropTypes.number,
  styles: PropTypes.object.isRequired,
  indexClick: PropTypes.func.isRequired,
  labelWidth: PropTypes.number.isRequired,
  fillingMotion: PropTypes.object.isRequired,
  barPaddingRight: PropTypes.number.isRequired,
  barPaddingLeft: PropTypes.number.isRequired,
  showArrowButtons: PropTypes.bool.isRequired,
}


export default EventsBar;
