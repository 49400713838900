import { Avatar, Box, Button, Checkbox, Divider, FormControlLabel, Grid, GridList, IconButton, InputBase, Paper, Slider, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import { Defects, defectStandards, tubeVoltage, machineParts, material, colorsPalate } from '../../../utils';
import { spacing } from '@material-ui/system';
import CustomCheckBox from '../../CustomCheckBox';


import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart} from 'recharts';
import { GlobalContext } from '../../../Context/GlobalState';
import { numberWithCommas, numberWithSpaces } from '../../../utils/format';
import { groupDefectRange } from '../../../utils/DefectGrouping';
import { generateGradient } from 'utils/gradient';


const CustomizedAxisTick = (props) => {
    const {
      x, y, stroke, payload,
    } = props;

    console.log("CustomizedAxisTick",  payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
            <text x={8} y={0} dy={10} textAnchor="end"
                style={{ fontSize: '12px', fontWeight: '400', fontFamily: '"Roboto", Arial' }}
                fill="#3576a7"
                transform="rotate(0)">
                {payload.value}
            </text>
      </g>
    );
}


const CustomizedYAxisTick = (props) => {
    const {
      x, y, stroke, payload,
    } = props;
    return (
      <g transform={`translate(${x},${y})`}>
            <text x={1} y={0} dy={2} textAnchor="end"
                style={{ fontSize: '12px', fontWeight: '400', fontFamily: '"Roboto", Arial' }}
                fill="#3576a7"
                >
                { numberWithCommas(payload.value)}
            </text>
      </g>
    );
}

const renderLegend = (props) => {
    const { payload } = props;
    let ids = payload.map(o => o.dataKey);
    let filtered = payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
    return (
        <Paper className="defect-bar-legend" variant="outlined">
            {
                filtered && filtered.map((entry, index) => {
                    if(props.checkAvailability[entry.value]) {
                        return <span className={entry.value} key={`item-${index}`}>{entry.value}</span>
                    }
                    return null;
                })
            }
        </Paper>
    );
}


// const renderToolTip = (props) => {
//     const { payload, label } = props;
//     let ids = payload && payload.map(o => o.dataKey);
//     let filtered = payload && payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
//     const splitLabel = label && label.split(' - ');
//     return (
//         <Paper className="defect-bar-tooltip" variant="outlined">
//             <div className="bold-700-12 label-margin">Label: { splitLabel && splitLabel[0]}mm - { splitLabel && splitLabel[1]}mm</div>
//             {
//                 filtered && filtered.map((entry, index) => {
//                     return (
//                         <span className={entry.name} key={`item-${entry.name}`}>
//                             {entry.name}: { entry.value }
//                         </span>
//                     )
//                 })
//             }
//         </Paper>
//     );
// }


// const renderMedicalToolTip = (props) => {
//     const { payload, label } = props;
//     let ids = payload && payload.map(o => o.dataKey);
//     let filtered = payload && payload.filter(({ dataKey }, index) => !ids.includes(dataKey, index + 1));
//     // const splitLabel = label && label.split(' - ');
    
//     return (
//         <Paper className="defect-bar-tooltip" variant="outlined">
//             <div className="bold-700-12 label-margin">Label: { label }</div>
//             {
//                 filtered && filtered.map((entry, index) => {
//                     return (
//                         <span className={entry.name} key={`item-${entry.name}`}>
//                             {entry.name}: { entry.value }
//                         </span>
//                     )
//                 })
//             }
//         </Paper>
//     );
// }


const CustomTooltip = ({ active, payload, label, total }) => {
    if (active && payload && payload.length) {
        console.log("renderToolTip-entry", payload, label, total)
      return (
          <Paper className="defect-bar-tooltip" variant="outlined">
              <div style={{ color: payload[0]?.payload?.fill }} className={payload[0].name} key={`item-${payload[0].name}`}>
                  <b>{`${payload[0].value}`}</b> images have
              </div>

              <div style={{ color: payload[0]?.payload?.fill }} className={payload[0].name} key={`item-${payload[0].name}-value`}>
                  <b>{`${payload[0].payload?.name}`}</b> label
              </div>
        </Paper>
      );
    }
  
    return null;
  };



const TotalClassPerImageTab = (props) => {

    const { dataset, dataset: { count, dataset_type } } = props;
    const { sidebar, openSideBar, state: { dataset: { amplify }} } = useContext(GlobalContext);
    const { open } = sidebar;
    const [classes, setClasses] = useState([])
    const [colors, setColors] = useState(["#3576A7"])

    useEffect(() => {
        

        const data = []

        if (dataset?.data_items_label_count) {
            Object.entries(dataset?.data_items_label_count).forEach(([key, value]) => {
                console.log("class", key, value)
                data.push({ name: key, value: value })
            })
            setClasses(data);
            setColors(generateGradient('#363534', '#A9AFB0', data.length))
            
        }

    }, [])

    console.log("dataset", dataset?.data_items_label_count)

    return (
        <Grid container direction="column" justify="flex-start">
            <Box mt={count?.defect ? 0 : 2} pl={0} pr={2} pb={1} className="inner-content">
                {/* <span style={{ marginLeft: '30px'}} className="bold-400-12 label-margin">Defects: {defectPercent}% of total, {numberWithSpaces(dataset.total * dataset.amplify)} images</span> */}
                <ResponsiveContainer width={open? '99.8%' : '100%'} height="90%">
                    <ComposedChart
                        // syncId={`experimentChart`}
                        layout="vertical"
                        data={classes}
                        margin={{ top: 20, bottom: 5, right: 20 }}>
                     
                        <CartesianGrid vertical={false} strokeDasharray="3 3" />
                        
                        <XAxis
                            padding={{ left: 10, right: 0 }}
                            axisLine={false}
                            tickLine={true}
                            type="number"
                            // height={90}
                            interval={0}
                            tick={<CustomizedAxisTick />}
                        />
                        
                        <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            tick={<CustomizedYAxisTick
                            />}
                        />

                        
                        <Tooltip content={<CustomTooltip total={dataset?.unique_data_items_count} />} />

                        <Bar barSize={35} dataKey="value" stackId="a" fill="#ffc47f" >
                            {

                                classes && classes.map((entry, index) => {
                                    console.log("ENTRY", entry)
                                    // const color = entry.name === 'none' ? "#a4debe" : "#8d6e97";
                                    return <Cell fill={colors[index]} />;
                                })
                            }
                        </Bar>
                       
                        
                    </ComposedChart>
                </ResponsiveContainer>
            
                {/* {dataset_type !== "industrial" ? null :
                    <Grid container style={{ paddingLeft: '10px', fontSize: '10px' }} justify="space-between">
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Median: {range.median}</span>
                        </Grid>
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Range: { range.lowest } - { range.highest }</span>
                        </Grid>
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Means: {range.mean}</span>
                        </Grid>
                        <Grid item>
                            <span className="grey-italic-text text-10" style={{ wordBreak: 'break-all' }}>Mode per Defect: { range.mode.toString()}</span>
                        </Grid>
                    </Grid>
                } */}
            </Box>
        </Grid>
    );
}

export default TotalClassPerImageTab;