
export const DEFECT_GROUP_LABELS = {
    r0to049: "0 - 0.50", // 0 - 0.50
    r05to099: "0.51 - 1.00", // 0.51 - 1.00
    r10to149: "1.01 - 1.50", // 1.01 - 1.5
    r15to199: "1.51 - 2.00", // 49 to 50
    r20to249: "2.01 - 2.50", // 0 to 01
    r25to299: "2.51 - 3.00",
    r30to349: "3.01 - 3.50",
    r35to399: "3.51 - 4.00",
    r40to449: "4.01 - 4.50",
    r45to499: "4.51 - 5.00",
    r50to549: "5.01 - 5.50",
    r55to599: "5.51 - 6.00",
    r60to649: "6.01 - 6.50",
    r65to699: "6.51 - 7.00",
    r70to749: "7.01 - 7.50",
    r75to799: "7.51 - 8.00",
    r80to849: "8.01 - 8.50",
    r85to899: "8.51 - 9.00",
    r90to949: "9.01 - 9.50",
    r95to999: "9.51 - 10.00",
    r100to1049: "10.01 - 10.50",
    r105to1099: "10.51 - 11.00",
    r110to1149: "11.01 - 11.50",
    r115to1199: "11.51 - 12.00",
    r120to1249: "12.01 - 12.50",
    r125to1299: "12.51 - 13.00",
    r130to1349: "13.01 - 13.50",
    r135to1399: "13.51 - 14.00",
    r140to1449: "14.01 - 14.50",
    r145to1499: "14.51 - 15.00",
    r150to1549: "15.01 - 15.50",
    r155to1599: "15.51 - 16.00",
};


export const groupDefects = (defects, data) => {

    const groups = {}
    defects.forEach(d => {
        // add empty defect object to groups.
        groups[d.label] = {}; 
        // add empty group arrays to each defect.
        Object.entries(DEFECT_GROUP_LABELS).forEach((GROUP) => {
            const [key, value] = GROUP;
            groups[d.label][value] = [];
        })

        data.forEach(function(item) {
            if (item[d.label] !== "" && item[d.label] > d.diameter) {
                const value = item[d.label];
                if (value <= 0.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r0to049].push(item);
                } else if (value <= 1.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r05to099].push(item);
                } else if (value <= 1.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r10to149].push(item);
                } else if (value <= 2.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r15to199].push(item);
                } else if (value <= 2.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r20to249].push(item);
                } else if (value <= 3.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r25to299].push(item);
                } else if (value <= 3.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r30to349].push(item);
                } else if (value <= 4.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r35to399].push(item);
                } else if (value <= 4.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r40to449].push(item);
                } else if (value <= 5.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r45to499].push(item);
                } else if (value <= 5.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r50to549].push(item);
                } else if (value <= 6.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r55to599].push(item);
                } else if (value <= 6.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r60to649].push(item);
                } else if (value <= 7.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r65to699].push(item);
                } else if (value <= 7.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r70to749].push(item);
                } else if (value <= 8.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r75to799].push(item);
                } else if (value <= 8.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r80to849].push(item);
                } else if (value <= 9.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r85to899].push(item);
                } else if (value <= 9.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r90to949].push(item);
                } else if (value <= 10.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r95to999].push(item);
                } else if (value <= 10.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r100to1049].push(item);
                } else if (value <= 11.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r105to1099].push(item);
                } else if (value <= 11.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r110to1149].push(item);
                } else if (value <= 12.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r115to1199].push(item);
                } else if (value <= 12.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r120to1249].push(item);
                } else if (value <= 13.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r125to1299].push(item);
                } else if (value <= 13.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r130to1349].push(item);
                } else if (value <= 14.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r135to1399].push(item);
                } else if (value <= 14.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r140to1449].push(item);
                } else if (value <= 15.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r145to1499].push(item);
                } else if (value <= 15.5) {
                    groups[d.label][DEFECT_GROUP_LABELS.r150to1549].push(item);
                } else if (value <= 16.0) {
                    groups[d.label][DEFECT_GROUP_LABELS.r155to1599].push(item);
                }

            }
        });
    });

    
    return groups;
}

export const groupDefectRange = (gData, by) => {
    let sortedData = [];
    let numberArr = []
    gData.map(defect => {
        const [defectKey, list] = defect;
        Object.entries(list).map(item => {
            const [groupKey, data] = item;
            // console.log(groupKey, data);
            data.forEach(element => {
                let obj = {};
                if (element[defectKey] > 0) {
                    obj[defectKey] = element[defectKey]
                }
                numberArr = [...numberArr, ...Object.values(obj)];
            });
        })
    });

    console.log('numberArr', numberArr);

    const returnedObj = {highest: 0, lowest: 0, median: 0, mean: 0, mode: 0}
    sortedData = numberArr.filter(x => typeof x === "number");
    sortedData.sort(function(a, b){return a-b});

    if(sortedData.length ===0) return returnedObj;
    return {
        lowest: sortedData[0],
        highest: sortedData[sortedData.length - 1],
        median: getMedian(sortedData),
        mean: round(getMean(sortedData),2), // // round to two decimal places
        mode: getMode(sortedData)
    };
}

const sum = function(array) {
    var total = 0;
    for (var i=0; i<array.length; i++) {
      total += array[i];
    }
    return total;
};
  
const getMean = function(array) {
var arraySum = sum(array);
return arraySum / array.length;
};
   
   
const getMedian = function(array) {
if (array.length % 2 === 0) { // array with even number elements
    return (array[array.length/2] + array[(array.length / 2) - 1]) / 2;
}
else {
    return array[(array.length - 1) / 2]; // array with odd number elements
}
};

  
const getMode = function(numbers) {
    // as result can be bimodal or multi-modal,
    // the returned result is provided as an array
    // mode of [3, 5, 4, 4, 1, 1, 2, 3] = [1, 3, 4]
    var modes = [], count = [], i, number, maxIndex = 0;
 
    for (i = 0; i < numbers.length; i += 1) {
        number = numbers[i];
        count[number] = (count[number] || 0) + 1;
        if (count[number] > maxIndex) {
            maxIndex = count[number];
        }
    }
 
    for (i in count)
        if (count.hasOwnProperty(i)) {
            if (count[i] === maxIndex) {
                modes.push(Number(i));
            }
        }

    return modes;
}

export const round = (number, decimals) => {
    if (decimals === 0) return Math.floor(number);
    return Math.round((number + Number.EPSILON) * 100) / Math.pow(10, decimals);
}