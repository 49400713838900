import { Avatar, Grid, Collapse, IconButton, Table, TableBody, TableCell, TableRow, useTheme, Snackbar, CircularProgress, TableContainer, Portal, Button, Menu, withStyles, MenuItem, ListItemIcon, ListItemText, Box, Typography } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';

import {
    UnarchiveIcon, CheckIcon, CloseIcon, CopyIcon,
    InfoIcon, InviteIcon, MenuIcon, NotificationCheckIcon, OpenIcon, TrainingRunningIcon
} from 'Components/Icon';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import ExperimentRow from './ExperimentRow';
import { Alert } from '@material-ui/lab';
import { numberWithSpaces, timeSince } from 'utils/format';
import CopyToClipboard from 'react-copy-to-clipboard';
import { GlobalContext, API_URL, SAMPLES_IMAGES_URL, SERVER_URL } from 'Context/GlobalState';
import axios from 'axios';
import CollaborationPopup from '../CollaborationPopup'
import DatasetDialog from '../DatasetDialog';
import useStickyHeader from 'utils/useStickyHeader';
import TeamPopup from '../TeamPopup';
import { useParams } from 'react-router-dom';

import { encodeQuery, trainingState } from 'utils';
import CustomPopOver from 'Components/CustomPopOver';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
      root: {
          paddingLeft: '24px',
          paddingRight: '24px',
          '& .MuiListItemIcon-root': {
              minWidth: 30,
              color: "#656565",
              '& .icon': {
                width: 16,
                 color: "#656565",
            },
          },
          '& .MuiListItemText-primary': {
              fontSize: 10,
              color: "#707070",
              fontStyle: "italic"
          },
          '&:focus': {
            // backgroundColor: "#efefef",
              '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                //color: theme.palette.common.white,
                  
              },
          },
    },
  }))(MenuItem);


// { id, name, date, members, rating };
function DataSetRow(props) {
    const { sidebar: { open: sidebarStatus},  state: { dataset: { amplify, dataset_type } } } = useContext(GlobalContext);
    const { row, datasetIndex, labelId, onClick, selected, ...rowProps } = props;
    const [open, setOpen] = useState(selected);
    const [openUserPopover, setOpenUserPopover] = useState(false);
    const [openMenuPopover, setOpenMenuPopover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [experimentUnarchived, setExperimentUnarchived] = useState(null);
    const [page, setPage] = useState(0);
    const [next, setNext] = useState(null);
    const [experiments, setExperiments] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorUserEl, setAnchorUserEl] = useState(null);
    const [anchorMenuEl, setAnchorMenuEl] = useState(null);
    const [openDatasetDialog, setOpenDatasetDialog] = useState(false);
    const { componentRef, isSticky, isBottom, coords } = useStickyHeader();
    const { datasetSlug, experimentSlug } = useParams();
    
    const theme = useTheme();
    const lastChange = new Date(row.exp_updated_date);
    const imagesCount = (row.dataset_meta && ((row.dataset_meta?.count.ok + row.dataset_meta?.count.nok) * amplify)) || 0;
    const defects = (row.dataset_meta && row.dataset_meta.count && row.dataset_meta.count.defect) || {};

    const IMAGE_URL = row.dataset_type === "medical" ? SAMPLES_IMAGES_URL : SERVER_URL + 'images/xray/'

    /// converts the defects to html tags
    const defectTags = Object.keys(defects).map((defect) => {
        if (defects[defect] > 0) {
            return (<span className={defect}>{ defect }</span>)
        }
    })


    const rowOpen = () => {
        setOpen(!open)
        onClick();
        setAnchorMenuEl(null);
    }

    const showCopyMessage = (e) => {
        // setCopied(true)
        // setTimeout(() => {
        //     setCopied(false);
        // }, 2000)
    }

    const copyDatasetId = (e) => {
        //e.stopPropagation(); // stop the row from being clicked
    }

    const getExperiments = () => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')

        let query = {};
        query["show-archived"] = 1
        if (page) {
            query["page"] = page;
        }
        if (experimentSlug) {
            query["first"] = experimentSlug;
        }
        query = encodeQuery(query)
        const config = {
            method: 'get',
            url: `${API_URL}/experiment-details/${row.id}/${query}`,
            headers: {
              'Authorization': `Token ${token}`
            }
          };
        axios(config).then((res) => {
            const {results, next} = res.data
            console.log("EXP_DATA", results);
            const experimentsToAdd = [];
            results.forEach(item => {
                const result = experiments.filter(obj => {
                    return obj.id === item.id
                })
                console.log("CHECK_CEHCK", item, result);
                if (result.length < 1) {
                    experimentsToAdd.push(item)
                }

            })
            //setExperiments([...experiments, ...results])
            setNext(next);
            // console.log("CHECK_CEHCK-111", experimentsToAdd);
            if (experimentsToAdd.length > 0) {
                setExperiments([...experiments, ...experimentsToAdd])
            }
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure your credentials are correct.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoading(false)
        })
    }


    const handleDatasetDetails = (e) => {
        e.stopPropagation(); // stop the row from being clicked
        console.log(row)
        setOpenDatasetDialog(true)
    }

    const openPopover = Boolean(anchorEl);
    const popoverId = open ? 'competition-popper' : undefined;

    const usersPopover = Boolean(anchorUserEl);
    const userPopoverId = openUserPopover ? 'users-popper' : undefined;

    const openMenu = Boolean(anchorMenuEl);
    const menuPopoverId = openMenuPopover ? 'menu-popper' : undefined;

    const handleCollaborationDetails = (e) => {
        //e.stopPropagation(); // stop the row from being clicked
        console.log(row)
        //setAnchorEl(e.currentTarget);
    }

    const handlePopoverClose = (e) => {
        //e.stopPropagation(); // stop the row from being clicked
        setAnchorEl(null);
    };

    const handleUsersDetails = (e) => {
        //e.stopPropagation(); // stop the row from being clicked
        console.log(row)
        //setAnchorUserEl(e.currentTarget);
    }

    const handleUserPopoverClose = (e) => {
        e.stopPropagation(); // stop the row from being clicked
        setAnchorUserEl(null);
    };

    const handleMenuClick = (e) => {
        e.stopPropagation(); // stop the row from being clicked
        setAnchorMenuEl(e.currentTarget);
    };
    
    const handleMenuClose = (e) => {
        e.stopPropagation(); // stop the row from being clicked
        setAnchorMenuEl(null);
    };


    const handleUnarchiveDataset = (e) => {
        e.stopPropagation(); // stop the row from being clicked
        props.unArchiveDataset(row, datasetIndex)
        setAnchorMenuEl(null);
    }


    useEffect(() => {

        if (datasetSlug === row.dataset_key) {
            rowOpen();
        }

    }, [datasetSlug, experimentSlug]);
    
    useEffect(() => {

        if (open) {
            getExperiments();
        }

        return () => {
            setExperiments([])
            setPage(0)
            setNext(null)
        }

    }, [open])

    useEffect(() => {
        if (open) {
            getExperiments();
        }
    }, [page])


    useEffect(() => {
        if (isBottom && next) {
            const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
            const params = Object.fromEntries(urlSearchParams.entries());
            setPage(params.page)
        }
    }, [isBottom])

    useEffect(() => {

    }, [sidebarStatus])


    const sendUnarchiveExperiment = (archive, experiment) => {
        //setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'PATCH',
            url: `${API_URL}/userinfo/archive-experiment/${experiment.id}/`,
            data: { archive: archive, type: 'experiment' },
            headers: {
                'Authorization': `Token ${token}`
            },
        };

        axios(config).then((res) => {
            const data = res.data
            console.log("You Archived", data)
            // props.archiveExperiment(experiment)
            //setExperiment([...data[0]])
            //setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                //setError("Please make sure you are logged in.")
            } else {
                //setError("Make sure you are connected to the internet.")
            }
            //setLoading(false)
        })
    }

    
    /// this is coming from the single experiment 
    const handleArchiveExperiment = (experiment, expIndex) => {
        const result = experiments.filter(obj => {
            return obj.id !== experiment.id
        })
        console.log("Experiment-to-Archive", result)
        setExperiments([...result]);

        setExperimentUnarchived({
            message: "Experiment unarchived successfully",
            index: expIndex,
            experiment: experiment
        });
        //setTimeout(() => {
            // sendUnarchiveExperiment("1", experiment);
            // setExperimentUnarchived(null);
        //}, 2000);
    }

    // console.log("EXP", row.is_archived)

    return (
        <React.Fragment>
            {isSticky && props.showHeader === true && (
        /*
        sticky will be the copy of table header while sticky 
        needed as otherwise table won't preserve columns width
        */
                <TableRow>

                    <TableCell style={{ border: 0, paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <TableContainer className="experiments-table-conatainer">
                            <Table
                                className="table sticky"
                                style={{
                                    display: openDatasetDialog ? 'none' : '',
                                    position: "fixed",
                                    width:  coords.width,
                                    top: 0,
                                    left: coords.left,
                                    zIndex: 1300
                                }}>
                                
                                <TableBody>
                                    <TableRow selected={open} {...rowProps} onClick={rowOpen}>
                                        <TableCell style={{ width: '40px' }}>
                        <IconButton
                            title='Dataset Info'
                            onClick={handleDatasetDetails}
                            className="icon-button" aria-label="archive" color="primary">
                            {/* <InfoOutlined /> */}
                            <InfoIcon className="icon" />
                        </IconButton>    
                                        </TableCell>
                                    
                                        <TableCell>{row.title}</TableCell>
                                        <TableCell>
                                            <Grid container alignItems='center'>

                                            
                                            {
                                                    !row.isCollaboration && <div
                                                        title={row.access_type === "Global" ? "Owner can share dataset with everyone ​" : "Owner can share dataset within own organization​"}
                                                        aria-describedby={popoverId}
                                                        onClick={handleCollaborationDetails}
                                                        className={`tag ${row.access_type}-tag`}>
                                                        <span>{row.access_type}</span>
                                                </div>
                                            }
                                                {row.isCollaboration ?
                                                    
                                                    <>
                                                        <Grid item>
                                                            <div
                                                                title="Owner can share dataset with own organization​"
                                                                aria-describedby={popoverId}
                                                                onClick={handleCollaborationDetails}
                                                                className="tag competition-tag">
                                                            <span>Collaboration</span>
                                                        </div>
                                                            <CustomPopOver
                                                                id={popoverId}
                                                                open={openPopover}
                                                                anchorEl={anchorEl}
                                                                onClose={handlePopoverClose}>
                                                                <CollaborationPopup dataset={row} />
                                                            </CustomPopOver>
                                                        </Grid>
                                                    </>
                                                    : null
                                                }
                                            </Grid>
                                            
                                        </TableCell>
                                        <TableCell id={labelId}>
                                            <Grid container alignItems="center">
                                                <Grid xs={7} item>
                                                    {row.dataset_key}
                                                </Grid>
                                                <Grid xs={3} item style={{ paddingLeft: '4px', alignSelf: 'flex-start' }}>
                                                    <CopyToClipboard text={row.dataset_key}
                                                            onCopy={showCopyMessage}>
                                                            <IconButton title="Copy ID​" onClick={copyDatasetId} className="icon-button" aria-label="share" color="primary">
                                                                <CopyIcon className="icon" />
                                                            </IconButton>
                                                    </CopyToClipboard>
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                        {/* <TableCell >{row.class_count}</TableCell> */}
                                        <TableCell>{numberWithSpaces(row.images)}</TableCell>
                                    
                                        
                                        <TableCell title="N° of Experiments executed">{row.experiments}</TableCell>
                                    
                                        <TableCell> 
                                            <Grid container justifyContent="flex-start" alignItems='center'>
                                                
                                                <AvatarGroup aria-describedby={userPopoverId} onClick={handleUsersDetails}
                                                    spacing={5}
                                                    classes={{ root: "avatars-group", avatar: "avatar-small" }} max={5}>
                                                    {row.isCollaboration ?
                                                        <Avatar title={`Host: ${row.host.name}`} className="avatar-small" alt={row.host.name} src={`${row.host.company_logo}`} />
                                                        :
                                                        <Avatar title={`Owner: ${row.owner.first_name} ${row.owner.last_name}`} className="avatar-small" alt={row.owner.first_name} src={`${row.owner.profile_image}`} />
                                                    }
                                                    {
                                                        row.users && row.users.map((user) => {
                                                            return user.id !== row.owner.id? <Avatar className="avatar-small" alt={user.first_name} src={`${user.profile_image}`} /> : null
                                                        })
                                                    }
                                                </AvatarGroup>
                                                <Avatar title="Add user to dataset​" onClick={handleUsersDetails} className="avatar-icon">
                                                    <InviteIcon className="icon" />
                                                </Avatar>
                                                <CustomPopOver
                                                    id={userPopoverId}
                                                    open={usersPopover}
                                                    anchorEl={anchorUserEl}
                                                    onClose={handleUserPopoverClose}>
                                                    <TeamPopup
                                                        datasetKey={row.dataset_key}
                                                        datasetId={row.id}
                                                        isCollaboration={row.isCollaboration}
                                                        users={row.users}
                                                        owner={row.owner}
                                                        pending={row.pending_invitations}
                                                        host={row.host}
                                                        accessType={row.access_type} />
                                                </CustomPopOver>
                                            </Grid>

                                            
                                        </TableCell>

                                        <TableCell>
                                            {
                                                timeSince( new Date(lastChange.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })))
                                            }
                                        </TableCell>
                                        
                                        <TableCell>
                                            <Grid container direction="row" alignItems="center">
                                                {
                                                    row.status === trainingState.completed ?
                                                        <CheckIcon className="icon" />
                                                    :
                                                    (
                                                        // <div class="pulse-blob"></div>
                                                        <TrainingRunningIcon className="icon" />
                                                    )
                                                }
                                            </Grid>
                                        </TableCell>

                                        <TableCell>
                                            <IconButton
                                                aria-describedby={menuPopoverId}
                                                // onClick={handleUnarchiveDataset}
                                                onClick={handleMenuClick}
                                                className="icon-button"
                                                aria-label="archive"
                                                color="primary">
                                                {/* <ArchiveIcon className="icon" /> */}
                                                <MenuIcon className="icon" />
                                            </IconButton>
                                            <CustomPopOver
                                                id={menuPopoverId}
                                                open={openMenu}
                                                anchorEl={anchorMenuEl}
                                                onClose={handleMenuClose}
                                                ContainerPadding= {{
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                    paddingTop: '24px',
                                                    paddingBottom: '24px'
                                                }}>
                                            
                                            { row.is_archived && <StyledMenuItem onClick={handleUnarchiveDataset}>
                                                    <ListItemIcon>
                                                        <UnarchiveIcon className="icon" />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Unarchive" />
                                                </StyledMenuItem>}

                                                <StyledMenuItem onClick={rowOpen}>
                                                    <ListItemIcon>
                                                    { open ? <CloseIcon className="icon" /> : <OpenIcon className="icon" />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={open ? "Close" : "Open"} />
                                                </StyledMenuItem>
                                                
                                            </CustomPopOver>
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                </TableRow>
                
            )}
            
            {props.showHeader && (
                <TableRow
                    selected={open}
                    {...rowProps}
                    onClick={rowOpen}
                    className={row.is_archived ? "archived" : ''}>
                
                <TableCell style={{ width: '40px' }}>
                    <IconButton
                        title='Dataset Info'
                        onClick={handleDatasetDetails}
                        className="icon-button" aria-label="archive" color="primary">
                        {/* <InfoOutlined /> */}
                        <InfoIcon className="icon" />
                    </IconButton>    
                </TableCell>
              
                <TableCell>{row.title}</TableCell>
                <TableCell>
                    <Grid container alignItems='center'>

                    
                    {
                            !row.isCollaboration && <div
                                title={row.access_type === "Global" ? "Owner can share dataset with everyone ​" : "Owner can share dataset within own organization​"}
                                aria-describedby={popoverId}
                                onClick={handleCollaborationDetails}
                                className={`tag ${row.access_type}-tag`}>
                                <span>{row.access_type}</span>
                        </div>
                    }
                        {row.isCollaboration ?
                            
                            <>
                                <Grid item>
                                    <div
                                        title="Owner can share dataset with own organization​"
                                        aria-describedby={popoverId}
                                        onClick={handleCollaborationDetails}
                                        className="tag competition-tag">
                                    <span>Collaboration</span>
                                </div>
                                    <CustomPopOver
                                        id={popoverId}
                                        open={openPopover}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}>
                                        <CollaborationPopup dataset={row} />
                                    </CustomPopOver>
                                </Grid>
                            </>
                            : null
                        }
                    </Grid>
                    
                </TableCell>
                <TableCell onClick={copyDatasetId} id={labelId}>
                   
                    <CopyToClipboard text={row.dataset_key}
                        onCopy={showCopyMessage}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {row.dataset_key}
                                </Grid>
                            </Grid>
                        </CopyToClipboard>
                   
                </TableCell>

                {/* <TableCell >{row.class_count}</TableCell> */}
                <TableCell>{numberWithSpaces(row?.dataset_meta?.unique_data_items_count || 0)}</TableCell>
              
                
                <TableCell title="N° of Experiments executed">{row.experiments}</TableCell>
              
                <TableCell> 
                    <Grid container justifyContent="flex-start" alignItems='center'>
                        
                        <AvatarGroup aria-describedby={userPopoverId} onClick={handleUsersDetails}
                            spacing={5}
                            classes={{ root: "avatars-group", avatar: "avatar-small" }} max={5}>
                            {row.isCollaboration ?
                                <Avatar title={`Host: ${row?.host?.name}`} className="avatar-small" alt={row?.host?.name} src={`${row?.host?.company_logo}`} />
                                :
                                <Avatar title={`Owner: ${row.owner.first_name} ${row.owner.last_name}`} className="avatar-small" alt={row.owner.first_name} src={`${row.owner.profile_image}`} />
                            }
                            {
                                row.users && row.users.map((user) => {
                                    return user.id !== row.owner.id? <Avatar className="avatar-small" alt={user.first_name} src={`${user.profile_image}`} /> : null
                                })
                            }
                        </AvatarGroup>
                        <Avatar title="Add user to dataset​" onClick={handleUsersDetails} className="avatar-icon">
                            <InviteIcon className="icon" />
                        </Avatar>
                        <CustomPopOver
                            id={userPopoverId}
                            open={usersPopover}
                            anchorEl={anchorUserEl}
                            onClose={handleUserPopoverClose}>
                            <TeamPopup
                                datasetKey={row.dataset_key}
                                datasetId={row.id}
                                isCollaboration={row.isCollaboration}
                                users={row.users}
                                owner={row.owner}
                                pending={row.pending_invitations}
                                host={row.host}
                                accessType={row.access_type} />
                        </CustomPopOver>
                    </Grid>

                    
                </TableCell>

                <TableCell>
                    {
                        timeSince( new Date(lastChange.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })))
                    }
                </TableCell>
                
                <TableCell>
                    <Grid container direction="row" alignItems="center">
                        {
                            row.status === trainingState.completed ?
                                <CheckIcon className="icon" />
                            :
                            (
                                // <div class="pulse-blob"></div>
                                <TrainingRunningIcon className="icon" />
                            )
                        }
                    </Grid>
                </TableCell>

                <TableCell>
                    <IconButton
                        aria-describedby={menuPopoverId}
                        // onClick={handleUnarchiveDataset}
                        onClick={handleMenuClick}
                        className="icon-button"
                        aria-label="archive"
                        color="primary">
                        {/* <ArchiveIcon className="icon" /> */}
                        <MenuIcon className="icon" />
                    </IconButton>
                    <CustomPopOver
                        id={menuPopoverId}
                        open={openMenu}
                        anchorEl={anchorMenuEl}
                        onClose={handleMenuClose}
                        ContainerPadding= {{
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            paddingTop: '24px',
                            paddingBottom: '24px'
                        }}>
                    
                        <StyledMenuItem onClick={handleUnarchiveDataset}>
                            <ListItemIcon>
                                <UnarchiveIcon className="icon" />
                            </ListItemIcon>
                            <ListItemText primary="Unarchive" />
                        </StyledMenuItem>

                        <StyledMenuItem onClick={rowOpen}>
                            <ListItemIcon>
                               { open ? <CloseIcon className="icon" /> : <OpenIcon className="icon" />}
                            </ListItemIcon>
                            <ListItemText primary={open ? "Close" : "Open"} />
                        </StyledMenuItem>
                        
                    </CustomPopOver>
                </TableCell>
                
                </TableRow>
            )}

            {/* 
                ////// Expansion ////////////////
                /////\\\\\\\\\\\\\\///////////////
             */}
            
            <TableRow ref={componentRef}>
                <TableCell style={{ border: 0, paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse
                        className="experiment-container"
                        style={{ minHeight: "60px"}}
                        in={open}
                        timeout="auto" unmountOnExit>

                        
                            <Grid
                                alignItems="center"
                                container
                                style={{
                                position: isSticky ? "fixed" : "absolute",
                                top: isSticky ? "60px" : "",
                                marginTop: isSticky ? "0px" : ( props.showHeader ? "20px" : "-6px"),
                                // marginBottom: isSticky ? "0px" : "4px",
                                marginLeft: "61px",
                                paddingBottom: "10px",
                                paddingLeft: "10px",
                                paddingTop: "8px",
                                zIndex: 100,
                                width: "100%",
                                maxWidth: componentRef?.current?.clientWidth,
                                }}
                                >

                                    {!loading && props.showHeader === false && (
                                        <Grid
                                            style={{
                                            // position: "absolute",
                                            marginLeft: "auto",
                                            // right: -360,
                                            paddingRight: "105px",
                                            display: "flex",
                                        justifyContent: "flex-end",
                                            }}
                                            item
                                        >
                                                                    <Box
                                                                        
                                            onClick={props.showArchived}
                                            style={{
                                                borderColor: "#707070",
                                                backgroundColor: "#707070",
                                                borderStyle: "solid",
                                                borderWidth: "1px",
                                                borderRadius: "5px",
                                                paddingLeft: "12px",
                                                paddingRight: "12px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                cursor: "pointer",
                                            }}
                                            >
                                            <Typography
                                                style={{
                                                fontSize: "10px",
                                                fontStyle: "normal",
                                                color: "#FFFFFF",
                                                }}
                                            >
                                                Archived
                                            </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                        
                        
                        
                        
                        {
                            experiments.length > 0 && experiments.map((experiment, index) => {
                                return <ExperimentRow
                                    key={experiment.id}
                                    expIndex={index}
                                    experimentData={experiment}
                                    unArchiveExperiment={handleArchiveExperiment}
                                    showHeader={props.showHeader}
                                />
                            })
                        }
                        {
                            loading && <Grid container justify="center" alignItems="center"><CircularProgress /></Grid>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>

            
                <Portal>
                {
                    copied &&
                    <Snackbar open={copied} autoHideDuration={3000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                            <Alert
                                iconMapping={{ success: <NotificationCheckIcon style={{ fontSize: 20 }} /> }}
                                variant="filled"
                                severity="success">Dataset ID successfully copied</Alert>
                    </Snackbar>
                }

                {experimentUnarchived !== null && 
                    <Snackbar
                        open={(experimentUnarchived !== null)}
                        autoHideDuration={2000}
                        disableWindowBlurListener
                        onClose={() => {
                            //console.log("CLCLLC");
                            if (experimentUnarchived !== null) {
                                sendUnarchiveExperiment(false, experimentUnarchived.experiment);
                            }
                            setExperimentUnarchived(null);
                        }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                        <Alert
                            variant="filled"
                            severity="success"
                            iconMapping={{ success: <NotificationCheckIcon style={{ fontSize: 20 }} /> }}
                            action={
                                <Button size="small" onClick={() => {
                                    const expeUndo = experiments;
                                    expeUndo.splice(experimentUnarchived.index, 0, experimentUnarchived.experiment);
                                    setExperiments([...expeUndo]);
                                    console.log("EXP-UNDO", expeUndo)
                                    setExperimentUnarchived(null)
                                }}>UNDO</Button>
                            }
                        >{experimentUnarchived.message}</Alert>
                    </Snackbar>
                }
                </Portal>
            
            <DatasetDialog dataset_type={dataset_type} imageUrl={IMAGE_URL} dataset={row} open={openDatasetDialog} closeCallback={()=> setOpenDatasetDialog(false)} />
        </React.Fragment>
    );
}

export default DataSetRow;