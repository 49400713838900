import React from "react";
import { useHistory } from "react-router";
import OverView from "./OverView";
import DataView from "./DataView";
import LeaderBoard from "./LeaderBoard";
import Experiments from "./Experiments";
import DataAnalysis from "./DataAnalysis";
import Rules from "./Rules";
import Discussion from "./Discussion";
import MySubmissions from "./MySubmissions";


const CollaborationDetails = (props) => {
  let history = useHistory();
  
  if (!props || !props.id) {
    history.push({ pathname: "/collaborations" });
  }

  if (props.filter === "overview") {
    return <OverView {...props} />;
  } else if (props.filter === "data") {
    return <DataView {...props} />;
  } else if (props.filter === "exploratory_data_analysis") {
    return <DataAnalysis {...props} />;
  } else if (props.filter === "experiments") {
    return <Experiments {...props} />;
  } else if (props.filter === "leaderboard") {
    return <LeaderBoard {...props} />;
  } else if (props.filter === "my_submissions") {
    return <MySubmissions {...props} />;
  } else if (props.filter === "rules") {
    return <Rules {...props} openModel={props.openModel} />;
  } else if (props.filter === "discussion") {
    return <Discussion {...props} />;
  }
};

export default CollaborationDetails;
