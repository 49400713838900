import { Avatar, Box, Button, Checkbox, Divider, FormControlLabel, Grid, GridList, IconButton, InputBase, Modal, Paper, Slider, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import { Defects, defectStandards, tubeVoltage, machineParts, material, defectsColors } from '../../../utils';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { spacing, sizing } from '@material-ui/system';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import CustomCheckBox from '../../CustomCheckBox';


import { StackedCarousel } from 'react-stacked-carousel'
import 'react-stacked-carousel/dist/index.css';
import { GlobalContext, SAMPLES_IMAGES_URL, SERVER_URL } from '../../../Context/GlobalState';
import ImageModelData from './ImageModelData';
import AnimatedText from '../../AnimatedText';
import { valuesToDefects } from '../../../utils/format';

const DatasetExampleImagesTab = (props) => {
    const [modelImage, setModelImage] = useState(null);
    const [open, setOpen] = useState(false);
    const [zoom, setZoom] = useState(false);
    const { dataset, dataset: { dataset_type } } = props;
    const [sampleImages, setSampleImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const IMAGE_URL = dataset_type !== "industrial" ? SAMPLES_IMAGES_URL : SERVER_URL + 'images/xray/'

    useEffect(() => {
        if (dataset.data_samples) {
            const sampleImages = [
                1,
                ...dataset.data_samples.slice(0, 5),
                ...dataset.data_samples.slice(dataset.data_samples.length - 4, dataset.data_samples.length)
            ];
            setSampleImages(sampleImages);
            setSelectedImage(dataset.data_samples && { ...sampleImages[0], defects: {}, index: 0 });
        }
    }, [dataset]);
    

    const onImageClick = (event) => {
        setOpen(true);
        setTimeout(() => {
            setZoom(true)
        })
    }
    
    const handleClose = () => {
        setOpen(false);
        setZoom(false);
        // console.log("Clicked");
    };

    const onCardChange = (card) => {

        const adjustIndex = card.currentIndex
        const sample = sampleImages[adjustIndex];
        console.log("onCardChange", sample)
        if (sample) {
            const defects = valuesToDefects(sample);
            setSelectedImage({ ...sample, defects, index: adjustIndex });
        }
    }


    console.log("selectedImage", selectedImage)

    return sampleImages.length > 0 && selectedImage && (
        <>
            <Grid container direction="column" justify="flex-start">

                {selectedImage.index > 0 && <Box mt={2} pl={4} pr={2} pb={1}>
                    <div className="bold-400-12 label-margin" style={{ minHeight: '20px' }}>
                        {dataset_type === "industrial" && selectedImage.index > 0
                            && <AnimatedText text={`${selectedImage?.part}, ${selectedImage?.voltage}kV, ${selectedImage?.material}`} />}
                        { selectedImage.index > 0 && <AnimatedText text={`${selectedImage?.label}`} />}
                        
                    </div>
                    <div className="grey-italic-text" style={{ textAlign: 'right' }}>
                       &nbsp; { selectedImage.index > 0 && `${selectedImage.index}/${sampleImages.length - 1}`}
                    </div>
                </Box>
                }

                <Box mt={selectedImage.index > 0 ? 0 : 3}>
                        <StackedCarousel
                            autoRotate={false}
                            showSummary={true}
                            onCardChange={onCardChange}
                            containerClassName="carousel-container"
                            style={{ height: selectedImage.index > 0 ? "200px" : "320px" }}
                            cardClassName="card"
                            leftButton={<ChevronLeftRoundedIcon style={{ marginRight: '4px'}} fontSize="large" color="primary" />}
                            rightButton={<ChevronRightRoundedIcon style={{ marginLeft: '4px'}} fontSize="large" color="primary" />}
                        >
                        {

                            sampleImages.slice(1).map((sample, index) => {
                                //console.log("KEY", dataset.dataset_key + sample.id, sample.id, index)
                                /*
                                todo: the key must not use index. we need to change this to sample.id
                                */
                                // console.log("selectedImage", index)
                                return (
                                    <img
                                        alt=''
                                        onClick={onImageClick} key={dataset.dataset_key + index}
                                        src={`${IMAGE_URL}${sample.data_id}.jpg`}
                                        style={{ width: 'inherit' }} />
                                )
                            })
                        }

                    </StackedCarousel>
                </Box>

                
                { selectedImage.index > 0 &&
                    <Box mt={2} pl={4} pr={2} pb={1}>
                        <Box mt={0}>
                            { selectedImage.index > 0 &&
                                <div className="defect-label-tags">
                                {
                                        (selectedImage?.defect === "NOK" || selectedImage?.defect === true) ?
                                        <span className="nok">NOK</span>
                                        : <span className="ok">OK</span>
                                }
                                {
                                selectedImage?.defects && Object.entries(selectedImage.defects).map(entry => {
                                        const [key, value] = entry;
                                        return (
                                            <span key={key} className="single-defect" style={{ backgroundColor: defectsColors[key].dark }}>
                                                <AnimatedText text={`${key}: ${value}`} />
                                            </span>
                                        )
                                    })
                                }
                                </div>
                            }
                            
                        </Box>
                    </Box>
                    
}
            </Grid>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={open}
                // onBackdropClick={handleClose}
                onClose={handleClose}
                aria-labelledby="image-modal-title"
                aria-describedby="image-modal-description"
            >
                <ImageModelData dataset_type={dataset_type} imageUrl={IMAGE_URL} clickCallback={handleClose} sampleImages={sampleImages} selectedImage={selectedImage} />
            </Modal>
        </>

    );
}

export default DatasetExampleImagesTab;