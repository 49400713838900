import { Box, Button, Grid, SvgIcon } from '@material-ui/core';
import { ContactlessOutlined } from '@material-ui/icons';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import React from 'react';
import './style.css';
import { TwitterIcon, XingIcon, LinkedInIcon } from '../Icon';
import { Link } from 'react-router-dom';
import logoFull from 'assets/logo-full.svg'


const Footer = (props) => {
    return (
        <Box className="footer">
            <Box className="nav">
                <Grid container alignItems="center" justify="center" className="links">
                    <Grid xs={2} item>
                        <a href="https://www.tracebloc.io/"><img src={logoFull} /></a>
                    </Grid>
                    <Grid style={{ marginLeft: "auto "}} item xs={1}>
                        <div><a href="https://www.tracebloc.io/impressum">Impressum</a></div>
                    </Grid>
                    <Grid item xs={1}>
                        <div><a href="https://www.tracebloc.io/privacy">Privacy Policy</a></div>
                    </Grid>
                    <Grid item xs={1}>
                        <div><a href="https://www.tracebloc.io/terms">Terms</a></div>
                    </Grid>
                    <Grid style={{ marginLeft: "auto "}} item xs={1}>
                        <a href="https://twitter.com/tracebloc" target="_blank">
                            <TwitterIcon style={{ fontSize: '18px', marginRight: '6px' }} /> <span>Twitter</span>
                        </a>
                    </Grid>
                    <Grid item xs={1}>
                        <a href="https://www.linkedin.com/company/tracebloc/" target="_blank">
                            <LinkedInIcon style={{ fontSize: '18px', marginRight: '6px' }} /> <span>LinkedIn</span>
                        </a>
                    </Grid>

                    <Grid style={{ marginLeft: "auto "}} item xs={2}>
                        <div>Copyright 2022 tracebloc GmbH</div>
                    </Grid>
                </Grid>
            </Box>
            {/* <SvgIcon className="circle-one" viewBox="8.400005340576172 34.79998779296875 60 313.638671875">
                    <path d="M 164.9518737792969 34.79998779296875 C 78.63388061523438 34.79998779296875 8.400005340576172 105.0338516235352 8.400005340576172 191.3518981933594 C 8.400005340576172 278.2060546875 78.63388061523438 348.4398193359375 164.9518737792969 348.4398193359375 C 251.8060302734375 348.4398193359375 322.0398864746094 278.2060546875 322.0398864746094 191.3518981933594 C 322.0399475097656 105.0338516235352 251.8060302734375 34.79998779296875 164.9518737792969 34.79998779296875 Z M 164.9518737792969 266.4109497070312 C 124.7416229248047 266.4109497070312 92.03733062744141 233.7066192626953 92.03733062744141 193.4963836669922 C 92.03733062744141 153.2862243652344 124.7416229248047 120.0456390380859 164.9518737792969 120.0456390380859 C 205.1621704101562 120.0456390380859 238.402587890625 152.7499847412109 238.402587890625 193.4963836669922 C 238.402587890625 233.7066192626953 205.6983032226562 266.4109497070312 164.9518737792969 266.4109497070312 Z"></path>
            </SvgIcon>
            <SvgIcon className="circle-two" viewBox="45.200016021728516 -100 199.4423828125 199.443359375">
                <path d="M 144.9214477539062 0 C 89.69941711425781 0 45.20001602172852 44.49934005737305 45.20001602172852 99.72139739990234 C 45.20001602172852 154.9434814453125 89.69941711425781 199.4427947998047 144.9214477539062 199.4427947998047 C 200.1435699462891 199.4427947998047 244.6428833007812 154.9434814453125 244.6428833007812 99.72139739990234 C 244.6428833007812 44.49934005737305 200.1434783935547 0 144.9214477539062 0 Z M 144.9214477539062 147.4375762939453 C 119.1869354248047 147.4375762939453 98.27755737304688 126.5282363891602 98.27755737304688 100.7936782836914 C 98.27755737304688 75.05911254882812 119.1869354248047 54.1497917175293 144.9214477539062 54.1497917175293 C 170.6560516357422 54.1497917175293 191.5653381347656 75.05912017822266 191.5653381347656 100.7936782836914 C 191.5653381347656 126.5282363891602 170.6560516357422 147.4375762939453 144.9214477539062 147.4375762939453 Z"></path>
            </SvgIcon> */}
        </Box>
    );
}

export default Footer;