import { Box, Button, CircularProgress, Collapse, Grid, Paper, TextField } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import { spacing } from '@material-ui/system';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import axios from 'axios';
import LoginHeader from '../../Components/LoginHeader';
import Footer from '../../Components/Footer';
import { useParams, useHistory } from 'react-router-dom';


const ActivateAccount = (props) => {
    const { sidebar, user, login, network, getUserInfo, setUserUpdate } = useContext(GlobalContext);
    let history = useHistory();

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [relogin, setRelogin] = useState(false);
    const { userKey, activationKey } = useParams();

    message && console.log("ERROR", message)

    const redirectToLogin = () => {
        setTimeout(() => {
            history.push({
                pathname: '/'
            })

        }, 2000)
    }

    const sendActivateAccount = () => {
        setLoading(true);
        const config = {
            method: 'GET',
            url: `${API_URL}/activate/${userKey}/${activationKey}/`
        };
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios(config).then((res) => {
            const data = res.data
            console.log("You Archived", data);
            setMessage({
                status: "success",
                type: "Thank you",
                message: "Account activated successfully",
            });
            setLoading(false)
            redirectToLogin()
            
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response && error.response.status === 400) {
                setMessage({
                    status: "error",
                    type: "Invalid key",
                    message: "Invalid activation key or user id",
                });

            } else if (error.response && error.response.status === 404) {
                setMessage({
                    status: "error",
                    type: "Error",
                    message: "Activation key not found",
                });

            } else if (error.response && error.response.status === 403) {
                setMessage({
                    status: "error",
                    type: "Invalid activation key or user id",
                    message: "Activation key already been used",
                });

            } else if (error.response && error.response.status === 406) {
                // setError(error.response.data)
                setMessage({
                    status: "error",
                    type: "Activation key Expired",
                    message: error.response.data.message,
                });
            } else if (error.response && error.response.status === 500) {
                // setError(error.response.data)
                setMessage({
                    status: "error",
                    type: "Error Occurred",
                    message: "There seems to be an issue on our end. We are working hard to fix it as soon as possible.",
                });
            } else {
                setMessage({
                    status: "error",
                    type: "Network Error",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
            redirectToLogin()
        });
    }


    useEffect(() => {
        sendActivateAccount()
    }, []);

    return (
        <Box>
            <LoginHeader />
            <Box className="activation-main">
                <div className="activation">
                { loading ?
                        <CircularProgress />
                        :
                        <Grid className="form" container direction="column">
                            <Box style={{ width: '100%', padding: '0px 32px', alignSelf: 'center' }}>
                                <h2
                                    className="title"
                                    style={{ alignSelf: 'center', textAlign: 'center' }}>
                                    {message && message?.type}
                                </h2>
                                {/* <div className="sub-title">tracebloc lets you train your models on high quality data which increase the flexibility of your model and make it generally applicable. We provide you access to the most relevant data with just one click.​</div> */}
                            </Box>
                            {
                                message &&
                                <Grid item>
                                        {
                                            message?.status === "success" ? 
                                                <div className="sub-title">{message?.message}​</div>
                                            :
                                            <div className='error-message'>{message?.message}</div>
                                        }
                                </Grid>
                            }
                        </Grid>
                    }
                </div>
                <Footer />
            </Box>
        </Box>
    );
}

export default ActivateAccount;