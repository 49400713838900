import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./style.css";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Portal,
  Snackbar,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

import {
  BookMarkIcon,
  ShareIcon,
  EditIcon,
  ArchiveIcon,
  StyledRating,
  NotificationCheckIcon,
} from "../Icon";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Rating from "@material-ui/lab/Rating";
import { StarBorderOutlined, StarRate } from "@material-ui/icons";
import DataSetRow from "./DataSetRow";
import { GlobalContext, API_URL } from "../../Context/GlobalState";
import useStickyHeader from "../../utils/useStickyHeader";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import { useTourContext } from "Context/TourContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const STATUS = {
  FINISHED: "finished",
  PAUSED: "paused",
  RUNNING: "running",
  STOPPED: "stopped",
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    // console.log("ORDER-orderBy-1", b[orderBy], a[orderBy])
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    // console.log("ORDER-orderBy+1", b[orderBy], a[orderBy])
    return 1;
  }
  // console.log("ORDER-orderBy+0", b[orderBy], a[orderBy])
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  const allRecords = stabilizedThis.map((el) => el[0]);

  const pinned = [];
  const filtered = allRecords.filter((el) => {
    if (el.is_pinned) {
      pinned.push(el);
    }
    return !el.is_pinned;
  });

  console.log("result-EXP", filtered);
  console.log("result-EXP-pinned", pinned);

  return [...pinned, ...filtered];
}

const headCells = [
  { id: "options", numeric: true, disablePadding: false, label: "" },
  { id: "title", numeric: false, disablePadding: false, label: "Name" },
  { id: "access_type", numeric: false, disablePadding: false, label: "Type" },
  {
    id: "dataset_key",
    numeric: false,
    disablePadding: false,
    label: "Dataset ID",
  },
  // { id: 'class_count', numeric: false, disablePadding: false, label: 'Classes' },
  { id: "images", numeric: true, disablePadding: false, label: "Data" },
  { id: "experiments", numeric: true, disablePadding: false, label: "Exp." },
  { id: "members", numeric: true, disablePadding: false, label: "Team" },
  {
    id: "exp_updated_date",
    numeric: false,
    disablePadding: false,
    label: "Last",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Training" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
                     <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all datasets' }}/>
                </TableCell>
                */}
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.id !== "status" &&
              headCell.id !== "members" &&
              headCell.id !== "options" ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  classes={{
                    root: "sort-label",
                    icon: "sort-icon",
                    active: "sort-icon-active",
                  }}
                  IconComponent={ArrowDropDownOutlinedIcon}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const ExperimentsList = (props) => {
  const {
    state: {
      experiments,
      experiments: { loading, list, next },
    },
    updateArchiveDataset,
    getUserExperiments,
    updateExperimentsStatus,
    emptyUserExperiments,
  } = useContext(GlobalContext);
  const {
    setState,
    state: { run, stepIndex, steps },
  } = useTourContext();

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("exp_updated_date");
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const [datasetArchived, setDatasetArchived] = useState(null);
  const [page, setPage] = useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const ref = useRef(null);
  const { componentRef, isSticky, isBottom, coords } = useStickyHeader();
  const { datasetSlug, experimentSlug } = useParams();

  const getExpStatus = () => {
    // setLoading(true);
    const datasets = [];
    list.forEach((element) => {
      datasets.push(element.id);
    });
    updateExperimentsStatus(datasets.toString());
  };

  useEffect(() => {
    if (list.length === 0 && !loading) {
      // console.log("if LIST", list.length, loading, page)
      getUserExperiments();
    } else if (list.length > 0 && experiments.next && page > 0 && !loading) {
      // console.log("elseif LIST", list.length, next, loading, page)
      getUserExperiments(page);
    }
    // console.log("LIST", list.length, loading, page)
  }, [page]);

  useEffect(() => {
    let intervalStatus;
    // if (list.length > 0) {
    //     setState({ run: true, tourActive: true });
    //     console.log("APPTOUR", run, stepIndex)
    // }
    if (list.length > 0 && !loading) {
      intervalStatus = setInterval(() => {
        getExpStatus();
      }, 18000);
    }

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    if (height === 0) {
      if (next) {
        const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
        const params = Object.fromEntries(urlSearchParams.entries());
        setPage(params.page);
      }
    }
    // console.log("WINDOW_SCROLL", winScroll, height, intervalStatus)

    return () => {
      clearInterval(intervalStatus);
    };
  }, [list]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sendArchiveDataset = (archive, dataset) => {
    //setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "PATCH",
      url: `${API_URL}/userinfo/archive-dataset/${dataset.id}/`,
      data: { archive: archive, type: "dataset" },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        // props.archiveExperiment(experiment)
        //setExperiment([...data[0]])
        //setLoading(false)
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          //setError("Please make sure you are logged in.")
        } else {
          //setError("Make sure you are connected to the internet.")
        }
        //setLoading(false)
      });
  };

  /// this is coming from the single experiment
  const handleArchiveDataset = (dataset, datasetIndex) => {
    updateArchiveDataset({
      index: datasetIndex,
      dataset: dataset,
      remove: true,
    });

    setDatasetArchived({
      message: "Dataset archived successfully",
      index: datasetIndex,
      dataset: dataset,
    });
  };

  //   const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  //   };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  useEffect(() => {
    if (isBottom && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom]);

  const handleClickStart = () => {
    setState({ run: true, tourActive: true, stepIndex: 0 });
  };

  // console.log("isBottom", isBottom)
  return (
    <>
      {list.length > 0 ? (
        <TableContainer
          ref={componentRef}
          className="experiments-table-conatainer"
        >
          {/* <Button onClick={handleClickStart}>
                                Start the tour
                            </Button> */}

          <Table stickyHeader className="table" aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />

            <TableBody>
              {stableSort(list, getComparator(order, orderBy)).map(
                (row, index) => {
                  /* 
                                                isSelected will be used to mark a specific row as selected.
                                                currently the selection is handled on row level using the open state.
                                                */
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <DataSetRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      datasetIndex={index}
                      selected={isItemSelected}
                      key={row.id}
                      archiveDataset={handleArchiveDataset}
                      labelId={labelId}
                      row={row}
                      showHeader={true}
                      hasExperiment={() => {}}
                      expLoading={() => {}}
                    />
                  );
                }
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 2 * emptyRows }}>
                  <TableCell className="empty-td" colSpan={6}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {loading && (
            <Grid container justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}

          {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={list.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}/> */}
        </TableContainer>
      ) : (
        <Grid container justify="center" alignItems="center">
          {loading ? <CircularProgress /> : <div>No experiments found</div>}
        </Grid>
      )}
      <Portal>
        {datasetArchived !== null && (
          <Snackbar
            open={datasetArchived !== null}
            autoHideDuration={2000}
            disableWindowBlurListener
            onClose={() => {
              //console.log("CLCLLC");
              if (datasetArchived !== null) {
                sendArchiveDataset(true, datasetArchived.dataset);
              }
              setDatasetArchived(null);
            }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              variant="filled"
              severity="success"
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              action={
                <Button
                  size="small"
                  onClick={() => {
                    updateArchiveDataset({
                      index: datasetArchived.index,
                      dataset: datasetArchived.dataset,
                      remove: false,
                    });
                    setDatasetArchived(null);
                  }}
                >
                  UNDO
                </Button>
              }
            >
              {datasetArchived.message}
            </Alert>
          </Snackbar>
        )}
      </Portal>
    </>
  );
};

export default ExperimentsList;
