import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: "16px 8px 26px 0px",
    paddingLeft: "8px",
    paddingRight: "8px",
    textAlign: 'left',
    '& .MuiAlert-standardWarning .MuiAlert-message': {
      textAlign: 'left',
      width: 'auto',
    },
    '& > * + *': {
      marginTop: theme.spacing(2),
      textAlign: 'left',
      width: 'auto',
    },
  },
}));


const ErrorMessage = (props) => {
  const { error_message } = props.experiment;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);


  return (
    <div className={classes.root}>
    <Collapse in={open}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Warning</AlertTitle>
          {error_message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default React.memo(ErrorMessage);
