
export const numberWithSpaces = (x) => {

    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");


    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const numberWithDots = (x) => {
    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export const valuesToDefects = (sample) => {
    return Object.assign({},
        sample.porosity ? { porosity: `${sample.porosity} mm` } : null,
        sample.inclusion ? {inclusion: `${sample.inclusion} mm`} : null,
        sample.crack ? {crack: `${sample.crack} mm`} : null,
      );
}


export const daysRemaining = (date_future, date_now, abs=false) => {
   // get total seconds between the times
    date_future = new Date(date_future)
    var delta = (date_future - date_now) / 1000; /// user Math.abs for always positive

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = Math.floor(delta % 60);  // in theory the modulus is not required

    let t = {
      'days': `${abs ? Math.abs(days) : days}d`,
      'hours': `${abs ? Math.abs(hours) : hours}h`,
      'minutes': `${abs ? Math.abs(minutes) : minutes}m`,
      'seconds': `${abs? Math.abs(seconds) : seconds}s`
    };
    // ['days', 'hours'].forEach( q => {
    //     if (eval(q) > 0) {
    //         t[q] = eval(q);
    //     }
    // });
    return t;
}

// if days > 1, do not display time.
export const daysRemaining_with_time = (date_future, date_now, abs=false) => {
  // get total seconds between the times
   date_future = new Date(date_future)
   var delta = (date_future - date_now) / 1000; /// user Math.abs for always positive

   // calculate (and subtract) whole days
   var days = Math.floor(delta / 86400);
   delta -= days * 86400;

   // calculate (and subtract) whole hours
   var hours = Math.floor(delta / 3600) % 24;
   delta -= hours * 3600;

   // calculate (and subtract) whole minutes
   var minutes = Math.floor(delta / 60) % 60;
   delta -= minutes * 60;

   // what's left is seconds
   var seconds = Math.floor(delta % 60);  // in theory the modulus is not required

   let t = {
     'days': `${abs ? Math.abs(days) : days}d`,
     'hours': `${abs ? Math.abs(hours) : hours}h`,
     'minutes': `${abs ? Math.abs(minutes) : minutes}m`,
     'seconds': `${abs? Math.abs(seconds) : seconds}s`
   };
   t = {
     ...t,
    'hours': `${ days > 3 ? "" : t.hours}`,
    'minutes': `${days > 3 ? "" : t.minutes}`,
    'seconds': `${days > 3 ? "" : t.seconds}`
   };
  
  //  return (
  //   (day && (day < 10 ? "0" + day.toFixed() : day.toFixed()) + "d " || "")
  //   + (hour && (hour < 10 ? "0" + Math.floor(hour) : hour.toFixed()) + "h " || "")
  //   + (minutes < 10 ? "0" + Math.floor(minutes) : minutes.toFixed()) + "m "
  //   + (seconds < 10 ? "0" + Math.floor(seconds) : seconds.toFixed()) + "s");
  
   return t;
}


export const timeSince = (date) => {

    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      if (Math.floor(interval) > 1) {
        return Math.floor(interval) + "y";
      }
      return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) > 1) {
        return Math.floor(interval) + "mo";
      }
      return Math.floor(interval) + "mo"; // singular
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) > 1) {
        return Math.floor(interval) + "d";
      }
      return Math.floor(interval) + "d";
      
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) > 1) {
        return Math.floor(interval) + "h";
      }
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) > 1) {
        return Math.floor(interval) + "m";
      }
      return Math.floor(interval) + "m";
      
    }
  return Math.floor(seconds) + "s"; 
}


// convert the
export function convertSecondsToDay(n) {
  // console.log("TIME-IN-SEC", n)
  const day =parseInt( n / (24 * 3600));

  n = n % (24 * 3600);
  const hour = parseInt(n / 3600);

  n %= 3600;
  const minutes = (n / 60);
  n %= 60;
  const seconds = n;

  return (
    (day && (day < 10 ? "0" + day.toFixed() : day.toFixed()) + "d " || "")
    + (hour && (hour < 10 ? "0" + Math.floor(hour) : hour.toFixed()) + "h " || "")
    + (minutes < 10 ? "0" + Math.floor(minutes) : minutes.toFixed()) + "m "
    + (seconds < 10 ? "0" + Math.floor(seconds) : seconds.toFixed()) + "s");
}


export function nFormatter(num, digits) {
  if (num < 1) {
    return num;
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });

  console.log(num, item)


  return item ? numberWithSpaces((num / item.value).toFixed(digits)) + ' ' + item.symbol : "0 ";
}


export const formatNumberToKMBT = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + " K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + " M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + " B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + " T";
};


// Forces signing on a number, returned as a string
export function printNumberSign(theNumber)
{
    if(theNumber > 0){
        return "+" + theNumber;
    }else{
        return theNumber.toString();
    }
}


// MM/dd/yyyy, hh:mm tt using Intl.DateTimeFormat
export function formatDate(date) {
  let p = new Intl.DateTimeFormat('en',{
    year:'numeric',
    month:'2-digit',
    day:'2-digit',
    hour:'2-digit',
    minute:'2-digit',
    hour12: false
  }).formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
  }, {});
  
  // DD:MM:YY-15:20  ${p.dayPeriod} for AM/PM
  return `${p.day}.${p.month}.${p.year} ${p.hour}:${p.minute}`; 
}


export function formatCommentDate(date) {
  let p = new Intl.DateTimeFormat('en',{
    year:'numeric',
    month:'2-digit',
    day:'2-digit',
    hour:'2-digit',
    minute:'2-digit',
    hour12: true
  }).formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
  }, {});
  
  // DD:MM:YY-15:20  ${p.dayPeriod} for AM/PM
  return `${p.day}.${p.month}.${p.year} ${p.hour}:${p.minute} ${p.dayPeriod}`; 
}


export function convertDateToDDMMYY(dateString) {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T.*$/;
  const match = dateString.match(regex);
  if (!match) return null;
  const year = match[1].substr(-2);
  const month = match[2];
  const day = match[3];
  return `${day}.${month}.${year}`;
}



export function getDateDifferenceInDays(date1Str, date2Str) {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);
  const diffInMs = (date2 - date1);
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays;
}


export function getMidDate(date1Str, date2Str) {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);
  const diffInMs = Math.abs(date2 - date1);
  const midInMs = Math.min(date1, date2) + diffInMs / 2;
  const midDate = new Date(midInMs)
  return midDate.toISOString();
}



export function formatDateWithTimeZone(inputDate) {
  const date = new Date(inputDate);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const parts = formatter.formatToParts(date);

  console.log("parts", parts)

  const day = parts.find(part => part.type === 'day').value;
  const month = parts.find(part => part.type === 'month').value;
  const year = parts.find(part => part.type === 'year').value;
  const hour = parts.find(part => part.type === 'hour').value;
  const minute = parts.find(part => part.type === 'minute').value;
  const timeZone = parts.find(part => part.type === 'timeZoneName').value;
  return `${day}.${month}.${year} - ${hour}:${minute} ${timeZone}`;
}



export function convertToMilligramAndGram(number) {

  // console.log("convertToMilligramAndGram", number)
  // Check if the input is a valid number
  if (typeof number !== 'number' || isNaN(number)) {
    return "Invalid input. Please provide a valid number.";
  }
  // Convert the number to milligrams
  const milligrams = number * 1000;
  // If the number is less than 0.1, return in milligrams
  if (number < 0.1) {
    return `${milligrams.toFixed(2)} mg`;
  } else {
    return `${number.toFixed(2)} g`;
  }
}