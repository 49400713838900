import React, { useCallback, useContext, useState, useRef, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import './style.css'
import { GlobalContext } from '../../../Context/GlobalState';
import DatasetDescriptionTab from './DatasetDescriptionTab';
import { TabContext, TabPanel } from '@material-ui/lab';
import { CustomTabs } from '../../CustomTabs/CustomTabs';
import { CustomTab } from '../../CustomTabs/CustomTab';

const DatasetDescription = (props) => {
    const [tab, setTab] = React.useState("all");
    const { sidebar, openSideBar } = useContext(GlobalContext);
    const { open } = sidebar;
    const ref = useRef(null);

    const handleChange = useCallback((event, newValue) => {
        setTab(newValue);
    });

  
    return (
        <Grid item xs={9} style={{ maxWidth: "70%", flexBasis: "70%"}} height="100%" ref={ref}>
            <TabContext value={tab}>
                <CustomTabs value={tab} onChange={handleChange} aria-label="ant example">
                    <CustomTab value="all" style={{ marginLeft: '1px', borderTopLeftRadius: '5px' }} label="Dataset description" />
                </CustomTabs>
                <Paper variant="outlined">
                    <TabPanel className="tab-panel inner-content" value={"all"}>
                        <DatasetDescriptionTab {...props} />
                    </TabPanel>
                </Paper>
            </TabContext>
        </Grid>
    );
}

export default DatasetDescription;