import { Box, Button, ButtonGroup, Container, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './style.css';
import logoFull from 'assets/logo-white.svg'
import { GlobalContext } from 'Context/GlobalState';

const LoginHeader = (props) => {
    const { user } = useContext(GlobalContext);
    let history = useHistory();

    console.log("history", history.location.pathname)

    return (
        <Box>
            <Box className="navigation">
                <Grid className="nav" container>
                    <a href="https://www.tracebloc.io/" className="logo">
                        <img src={logoFull} />
                    </a>
                    {!user && history.location.pathname !== "/" && <a href="/"><span>Already have an account?</span><ArrowForwardIcon /></a>}
                    { !user && history.location.pathname === "/" && <a href="/signup"><span>New to tracebloc?</span><ArrowForwardIcon /></a>}
                </Grid>
            </Box>
        </Box>
    );
}

export default LoginHeader;