import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import './style.css'
import { GlobalContext } from '../../../Context/GlobalState';
import TotalClassPerImageTab from './TotalClassPerImageTab';
import { TabContext, TabPanel } from '@material-ui/lab';
import { CustomTabs } from '../../CustomTabs/CustomTabs';
import { CustomTab } from '../../CustomTabs/CustomTab';

const TotalClassPerImage = (props) => {
    const { dataset, dataset: { count, groups, dataset_type } } = props;
    let defectsList = [];
    for(let key in count?.defect) {
        if (count.defect[key] > 0) {
            defectsList[key] = count.defect[key];
            defectsList.push({[key]: count.defect[key] })
        }
        // increase the count
    }

    const [tab, setTab] = React.useState("all");
    const handleChange = useCallback((event, newValue) => {
        setTab(newValue);
    });

    useEffect(() => {
        if (dataset_type === "medical") {
            setTab("all");
        }
    }, [])

    return (
        <Grid item xs={4} style={{ maxWidth: "30%", flexBasis: "30%"}} height="100%">
            <TabContext value={tab}>
                <CustomTabs className="custom-tab-container" value={tab} onChange={handleChange} aria-label="ant example">
                    {count?.defect && count.defect["crack"] && <CustomTab value="crack" label="crack" />}
                    {count?.defect && count.defect["inclusion"] && <CustomTab value="inclusion" label="inclusion" /> }
                    {count?.defect && count.defect["porosity"] && <CustomTab value="porosity" label="porosity" />}
                    <CustomTab value="all" label="Absolute frequency of labels per image" />
                </CustomTabs>
               
                <Paper variant="outlined">
                    
                    {
                        !tab && <div className="tab-panel inner-content" value={"all"}>
                            <TotalClassPerImageTab {...props} defect={"all"} />
                        </div>
                    }

                    <TabPanel className="tab-panel inner-content" value={"all"}>
                        <TotalClassPerImageTab {...props} defect={"all"} />
                    </TabPanel>
                    <TabPanel className="tab-panel inner-content" value={"crack"}>
                        <TotalClassPerImageTab {...props} defect={"crack"} />
                    </TabPanel>
                    <TabPanel className="tab-panel inner-content" value={"inclusion"}>
                        <TotalClassPerImageTab {...props} defect={"inclusion"} />
                    </TabPanel>
                    <TabPanel className="tab-panel inner-content" value={"porosity"}>
                        <TotalClassPerImageTab {...props} defect={"porosity"} />
                    </TabPanel>

                    <TabPanel className="tab-panel inner-content" value={"disease"}>
                        <TotalClassPerImageTab {...props} defect={"disease"} />
                    </TabPanel>
                </Paper>
            </TabContext>
        </Grid>
    );
}

export default TotalClassPerImage;