import {
  Grid,
  Collapse,
  TableCell,
  TableRow,
  Tooltip as MuiToolTip,
  CircularProgress,
  Button,
  Snackbar,
  Portal,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";

import { convertDateToDDMMYY, convertToMilligramAndGram, formatDate, formatNumberToKMBT, nFormatter, numberWithSpaces, printNumberSign, timeSince } from "utils/format";
// import ExperimentRow from "./ExperimentRow";
import { API_URL } from "Context/GlobalState";
import imagePlaceHolder from "assets/avatar.png";
import ExperimentRow from "Components/ExperimentsList/ExperimentRow";
import { Alert } from "@material-ui/lab";
import { NotificationCheckIcon } from "Components/Icon";
// import ExperimentRow from "Components/ExperimentsList/ExperimentRow";

// { id, name, date, members, rating };
function MySubmissionsRow(props) {
  const {
    row,
    labelId,
    onClick,
    totalPrizes,
    position,
    totalEntries,
    dataset,
    is_private,
    baseEnergyConsumption,
    totalFinalSubmissions,
    submitSubmission,
    finalSubmission,
    addSubmission,
    deleteSubmission,
    ...rowProps
  } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [experiment, setExperiment] = useState(null);
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [flash, setFlash] = useState(false);

  const updated_date = new Date(row.submit_date);

  
  const rowOpen = () => {
    setOpen(!open);
    getExperimentDetails()
    onClick();
  };

  const getExperimentDetails = () => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    
    const config = {
      method: "get",
      url: `${API_URL}/experiment-details/${dataset}/${row.experiment_id}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        setExperiment({ ...data[0] });
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };



  const submitThisSubmission = (e) => {
    e.stopPropagation();

    if (e.target.checked && totalFinalSubmissions > 1) {
      // console.log(e.target.checked, totalFinalSubmissions)
      setFlash(true)
      setTimeout(() => setFlash(false), 1000);
      return;
    }

    let data = {
        "experiment": row.experiment_id,
        "cycle": row.cycle
    };
    setLoadingSubmission(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const submission = finalSubmission.length > 0 ? `${finalSubmission[0].id}/` : ''
    
    let method = totalFinalSubmissions > 1 ? "POST" : "POST";
    if (e.target.checked === false) {
      method = "DELETE"
    }

    // console.log("DELETE-get", dataset, finalSubmission, !e.target.checked, method);
    const config = {
      method: method,
      url: `${API_URL}/submit/final/${dataset}/${submission}`,
      headers: {
          Authorization: `Token ${token}`,
      },
      data: data
    };
    axios(config)
    .then((res) => {
      const data = res.data;
      // console.log("FinalSubmissions-get", data, dataset);
      setSuccess(true)
      setSuccessMessage("Inference Deleted Successfully.")
      setError(null)
      // console.log("DELETE-get", data, dataset, finalSubmission, !e.target.checked, method);
      if (method === "DELETE") {
        deleteSubmission(finalSubmission[0]?.id)
      } else {
        addSubmission(data);
        setSuccessMessage("Inference Submitted successfully.")
      }
      
      setLoadingSubmission(false);
        // getSubmission()
    })
    .catch((error) => {
        console.log("CATCH", error.response.data);
        if (error.response && error.response.data) {
            setError(error.response.data)
        } else {
            setError("Make sure you are connected to the internet.")
        }
        setLoadingSubmission(false);
    });
    
  };







  const errorMessages = () => {
    // Check if the 'experiment' key exists in the error data
    let errorList = null
    if (error.experiment) {
        errorList = (
        error.experiment.map((message, index) => (
            <p style={{ textAlign: 'left'}} key={index}>experiment: {message}</p>
        ))
      );
    }

    // Check if the 'cycle' key exists in the error data
    if (error.cycle) {
        const cycleList = (
            error.cycle.map((message, index) => (
              <p style={{ textAlign: 'left'}} key={index}>cycle: {message}</p>
            ))
        );
        errorList = [errorList, ...cycleList]
    }

    if (error.non_field_errors) {
        const cycleList = (
            error.non_field_errors.map((message, index) => (
              <p style={{ textAlign: 'left'}} key={index}>{message}</p>
            ))
        );
        errorList = [errorList, ...cycleList]
    }

    // If none of the expected keys are found, display a generic error message
    return (
        errorList ? errorList : <p style={{ textAlign: 'left'}}>An unknown error occurred.</p>
    );
  }

  // console.log("###LEADER-finalSubmission", finalSubmission)
  //id, name, notebook, members, score, entries, last
  return (
    <React.Fragment>
      <TableRow
        style={{
          cursor:
          typeof(row.experiment) === "object"
              ? "default"
              : "pointer",
        }}
        {...rowProps}
        onClick={typeof(row.experiment) !== "object" ? rowOpen : null}
      >
        {/* id */}
        <TableCell id={labelId} style={{ width: "45px" }}>
          {position <= totalPrizes && (
            <div
              className="money-bar"
              style={{
                opacity: (totalEntries - (position - 1)) / totalEntries,
              }}
            ></div>
          )}
          {position}
        </TableCell>

        {/* Experiment Key */}
        <TableCell
          style={{
            textAlign: "left",
            width: "80px",
          }}
          id={labelId}
        >
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',

          }}>
            {row.experiment_key}
          
          </div>
        </TableCell>

        {/* Cycle Number */}
        <TableCell
          style={{
            width: "50px",
          }}
        >
        {row.cycle}
        </TableCell>

        {/* Date */}
        <TableCell
          // style={{
          //   width: "50px",
          // }}
        >
        {formatDate(new Date(row.created_date))}
        </TableCell>


        {/* accuracy */}
        <TableCell>
          <MuiToolTip
            title={`Accuracy`}
          >
            <div>{ (row.inference_captured && parseFloat(row.accuracy || 0).toFixed(4)) || "~"}</div>
          </MuiToolTip>
        </TableCell>

        {/* Loss */}
        <TableCell>
          <MuiToolTip
            title={`Loss`}
          >
            <div>{ (row.inference_captured &&  parseFloat(row.loss || 0).toFixed(4)) || "~"}</div>
          </MuiToolTip>
        </TableCell>


        {/* model_parameters */}
        <TableCell
          style={{
            // minWidth: "200px",
            // width: '15%',
            // maxWidth: '200px',
          }}
        >
          <MuiToolTip
            title={`Score calculated using ${row?.score_formula_display}`}
          >
          <div style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            width: '100%'
          }}>
            <div style={{ marginRight: 8, width: '50%', textAlign: 'right'}}>
            {(row?.model_parameters && formatNumberToKMBT(row?.model_parameters || 0)) || ""}
            </div>
            
            <div style={{ marginLeft: 8, width: '50%', textAlign: 'left'}}>
            {(row?.model_size && formatNumberToKMBT(row?.model_size || 0) + "B") || ""}
            </div>
          </div>

          </MuiToolTip>
        </TableCell>

        {/* inference_flopsutilised */}
        <TableCell
          style={{
            // minWidth: "200px",
            // width: '20%',
            // maxWidth: '230px',
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}>
            <div style={{ marginRight: 8, width: '50%', textAlign: 'right'}}>
              {
               (row?.flopsutilised && Number.parseInt(row?.flopsutilised || 0).toExponential(2).replace(/e\+/g, ' e').replace(/e-/g, ' e-')) || ""
              }
            </div>
            
            <div style={{ marginLeft: 8, width: '50%', textAlign: 'left'}}>
              { 
                position === 1 ? "-" :
                (row?.flopsutilised && numberWithSpaces(printNumberSign(
                    (
                      ((row?.flopsutilised - baseEnergyConsumption) / baseEnergyConsumption) * 100
                    ).toFixed(1).replace(/\.0$/, '')
                  ) + " %")) || ""
              }
            </div>
          </div>
          
        </TableCell>



        {/* Flops Utilization */}
        {/* <TableCell
          style={{
            width: "200px",
          }}
        >
          
        </TableCell> */}

        {/*gco2 */}
        {
          <TableCell>
              <MuiToolTip
                title={`co2e`}>
                <div>{ (row.inference_captured && numberWithSpaces(convertToMilligramAndGram(parseFloat(row.gco2)))) || "~"}</div>
              </MuiToolTip>
            </TableCell>
        }
        
        
        {/* Submit */}
        <TableCell className="my-submission-cycle-btn">
          {/* <Button
            title={"Submit to leaderboard"}
            onClick={submitSubmission}
            className="my-submission-cycle-btn"
            style={{ cursor: row.inference_captured ? "pointer" : "wait" }}
            disabled={!row.inference_captured}>
            
            {row.inference_captured ? "Submit" : "Pending"}
          </Button> */}

          {loadingSubmission ?
            <div className="custom-checkbox-unchecked">
              <CircularProgress size={18} />
            </div> :
            <Checkbox
              onClick={submitThisSubmission}
              color="default"
              // disabled={totalFinalSubmissions > 1}
              checked={finalSubmission.length > 0}
              checkedIcon={<span style={{ border: '1px solid #3576a7', padding: '1px' }}><span className='custom-checked-icon'></span></span>}
              icon={<span style={{ border: '1px solid #3576a7', padding: '1px' }}><span className='custom-unchecked-icon' /></span>}
              classes={{ root: `custom-checkbox-unchecked ${ flash ? "flash-while-disabled" : ''}`, checked: 'custom-checkbox-checked' }}
          />
        
        }

        </TableCell>
        

      </TableRow>

      {/* 
                ////// Expansion ////////////////
                /////\\\\\\\\\\\\\\///////////////
             */}
      <TableRow>
        <TableCell
          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse
            className="experiment-container"
            in={typeof(row.experiment) !== "object" && open} // PLEASE UNCOMMENT WHEN PERMISSIONS IMPLEMENTED
            timeout="auto"
            unmountOnExit
          >
            {
              experiment && (
                <ExperimentRow
                  key={experiment.id}
                  expIndex={0}
                  experimentData={experiment}
                  // unArchiveExperiment={handleArchiveExperiment}
                  showHeader={false}
                // archiveExperiment={handleArchiveExperiment}
                />
              )
            }

            { !experiment && loading &&
               <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            }

          </Collapse>
        </TableCell>
      </TableRow>



      <Portal>
                {success && (
                    <Snackbar
                        open={success}
                        autoHideDuration={3000}
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        onClose={()=> setSuccess(null)}
                    >
                    <Alert
                        iconMapping={{
                        success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
                        }}
                        variant="filled"
                        severity="success"
                    >
                        {successMessage}
                    </Alert>
                    </Snackbar>
                )}

                {error && (
                    <Snackbar
                        open={error}
                        autoHideDuration={3000}
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        onClose={()=> setError(null)}
                    >
                    <Alert
                            // iconMapping={{
                            // success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
                            // }}
                            icon={false}
                            variant="filled"
                            severity="error"
                        >
                            {errorMessages()}
                        </Alert>
                    </Snackbar>
        )}
        

        {(
                <Snackbar
                    open={flash}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    onClose={()=> setError(null)}
                >
                <Alert
                        // iconMapping={{
                        // success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
                        // }}
                        icon={false}
                        variant="filled"
                        severity="error"
                    >
                        Max 2 submissions allowed. Please uncheck a submission.
                    </Alert>
                </Snackbar>
        )}

            </Portal>




    </React.Fragment>
  );
}

export default MySubmissionsRow;
