import React from "react";
import { useStyles } from "../style";


export default function CollaborationSpecificRules(props) {
  const classes = useStyles();
  const rules = props.rules.replace(/\n|\r/g, "");


  console.log("competition_rules_html", props);
  return (
    <div className={classes.root}
      dangerouslySetInnerHTML={{ __html: rules }}></div>
  );
}
