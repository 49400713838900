import { useEffect, useState } from "react";

const useOnScreen = (ref, options) => {

    const [isIntersecting, setIntersecting] = useState(false);
    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        options
    )
  
    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, []);
  
    return isIntersecting
}


export default useOnScreen;