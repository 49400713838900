import { Box, Button, CircularProgress, Collapse, Grid, IconButton, InputAdornment, Paper, TextField } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import { spacing } from '@material-ui/system';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import axios from 'axios';
import LoginHeader from '../../Components/LoginHeader';
import Footer from '../../Components/Footer';
import { useForm } from "react-hook-form";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { EmailIcon, FirstNameIcon, LastNameIcon, PasswordHiddenIcon, PasswordIcon, PasswordShowIcon, UserNameIcon } from '../../Components/Icon';

const OnBoard = (props) => {
    const { user, setUserUpdate } = useContext(GlobalContext);
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const location = useLocation()
    let history = useHistory();
    const usernameRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    useEffect(() => {
        if (user && user.is_password_changed) {
            history.push({pathname: `/`})
        }
    }, [user]);


    const signupUser = (registrationData) => {
        setLoading(true);

        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'PATCH',
            url: `${API_URL}/userinfo/`,
            headers: {
                'Authorization': `Token ${token}`
            },
            data: {...registrationData},
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("signupUser", data);
            setError(null);
            setSuccess(true);
            setLoading(false)
            const datasetKey = localStorage.getItem("datasetKey");
            localStorage.removeItem("datasetKey")
            setUserUpdate({ ...data, })
            history.push({
                pathname: `/` + (`experiments/${datasetKey || "" }`),
            })
        }).catch((error) => {
            console.log('CATCH', error.response.data);
            if (error.response.status === 404) {
                setError("error", {
                    type: "required",
                    message: error.response.statusText,
                });

            } else if (error.response && error.response.data) {
                // setError(error.response.data)
                Object.entries(error.response.data).forEach((value) => {
                    if (["email", "password", "confirm_password"].includes(value[0])) {
                        setError(value[0], {
                            type: "required",
                            message: value[1].toString(),
                        });
                    }
                });
            } else {
                setError("error", {
                    type: "required",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
            // clearErrors()
        });
    }


    const onSubmit = data => {
        console.log('Register-validate', data)
        signupUser(data)

    };

    console.log('Register-validate-error', usernameRef?.current?.value, user);
    console.log('Location', location);

    return (
        <Box>
            <LoginHeader />
            <Box className="onboard-user-main">
                <div className="onboard-user">
                
                    <Grid onSubmit={handleSubmit(onSubmit)} component={"form"} className="form" container direction="column">
                
                    {
                        success &&
                        <Grid style={{ marginBottom: '8px' }} item>
                            <Alert icon={false} variant='filled' severity="success">Account created successfully.</Alert>
                        </Grid>
                    }

                    <Grid item>
                        <h3>Welcome to tracebloc!</h3>
                        <h3 style={{ color: "#212529", marginBottom: "24px" }}>Let’s begin the adventure</h3>
                    </Grid>
                    
                    
                        <Grid item>
                            <TextField
                                inputProps={{...register('email', {required: false,})}}
                                disabled
                                value={location?.state?.email}
                                inputRef={usernameRef}
                                fullWidth={true}
                                margin= 'normal'
                                className="login-email"
                                placeholder="Your email"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon style={{ marginRight: '8px' }} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                inputProps={{
                                    ...register("first_name", {
                                        required: "Please enter your first name",
                                        minLength: { value: 2, message: 'First name must be greater than 2 characters' },
                                        // pattern: { value: /[A-Za-z]{8}/, message: 'error message' }
                                    })
                                }}
                                onChange={()=> clearErrors()}
                                inputRef={firstNameRef}
                                fullWidth={true}
                                margin= 'normal'
                                className="login-username"
                                placeholder="First Name"
                                helperText= {errors.first_name && <span className='error'>{errors.first_name?.message}</span>}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FirstNameIcon style={{ marginRight: '8px' }} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item>
                        <TextField
                            inputProps={{
                                ...register("last_name", {
                                    required: "Please enter your last name",
                                    minLength: { value: 2, message: 'Last name must be greater than 2 characters' },
                                    // pattern: { value: /[A-Za-z]{8}/, message: 'error message' }
                                })
                            }}
                            onChange={()=> clearErrors()}
                            inputRef={firstNameRef}
                            fullWidth={true}
                            margin= 'normal'
                            className="login-username"
                            placeholder="Last Name"
                            helperText= {errors.last_name && <span className='error'>{errors.last_name?.message}</span>}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LastNameIcon style={{ marginRight: '8px' }} />
                                    </InputAdornment>
                                )
                            }}
                        />
                        </Grid>
                        
                        <Grid item>
                            <TextField
                                inputProps={{
                                    ...register("password", {
                                        required: 'Make sure it’s at least 8 Characters including a number and a lowercase letter',
                                        pattern: {
                                            value: /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                                            message: 'Make sure it’s at least 8 Characters including a number and a lowercase letter'
                                        }
                                    })
                                }}
                                onChange={()=> clearErrors()}
                                inputRef={passwordRef}
                                type={showPassword ? "text" : "password"}
                                fullWidth={true}
                                margin= 'normal'
                                className="login-password"
                                placeholder="Your password"
                                helperText= {errors.password && <span className='error'>{errors.password?.message}</span>}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon style={{ marginRight: '8px' }} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <PasswordShowIcon /> : <PasswordHiddenIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>

                    {/* <Grid item>
                        <TextField
                            inputProps={{
                                ...register("confirm_password", {
                                    required: "Confirm password is required",
                                    validate: value => value === passwordRef.current.value || 'Confirm password must match Password.',
                                    minLength: { value: 8, message: 'Your password must contain at least 8 characters.' },
                                    // pattern: { value: /[A-Za-z]{8}/, message: 'error message' }
                                })
                            }}
                            onChange={()=> clearErrors()}
                            inputRef={confirmPasswordRef}
                            type={showConfirmPassword ? "text" : "password"}
                            fullWidth={true}
                            margin= 'normal'
                            className="login-password"
                            placeholder="Confirm password"
                            helperText= {<span className='error'>{errors.confirm_password?.message}</span>}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon style={{ marginRight: '8px' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {showConfirmPassword ? <PasswordShowIcon /> : <PasswordHiddenIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                    </Grid> */}

                    { errors.manual && <div className='error-message'>{errors.manual.message}</div> }

                    {/* <Grid item>
                        <Link to="/">Already have an account? Login here.</Link>
                    </Grid> */}

                    <Grid item>
                        <Button
                            type='submit'
                            size="large"
                            variant="contained"
                            className="login-button"
                            color="primary"
                            classes={{ label: "label" }}
                            // onClick={LoginHandle}
                            disableElevation>
                            {loading ? <CircularProgress size={35} color="inherit" /> : "Sign up"}
                    </Button>

                    </Grid>
                    
                    </Grid>

                    <div className='terms-of-service'>
                       By creating an account, you agree to the <a href="https://www.tracebloc.io/terms">Terms of Service</a>. For more information about tracebloc’s privacy practices, see the <a href="https://www.tracebloc.io/privacy">tracebloc Privacy Statement</a>. We’ll occasionally send you account-related emails.
                    </div>
                </div>

               
                {/* <Footer /> */}
            </Box>
        </Box>
    );
}


export default OnBoard;