import { Box, Divider, Typography } from '@material-ui/core';
import { ColabIcon } from 'Components/Icon';
import DocumentationIcon from "assets/documentationIcon.png";
import RectPlayIcon from "assets/rectPlayIcon.png";
import React from 'react';

const subBoxContainerStyle = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
};
const subHeadingStyle = {
  color: "#3576A7",
  fontSize: "12px",
  fontStyle: "italic",
  fontWeight: "bold",
  marginLeft: "8px",
};
const subDescriptionStyle = {
  color: "#707070",
  fontSize: "12px",
  fontStyle: "italic",
  marginTop: "24px",
};



function PlaceHolderHelp(props) {
  return (
    <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "48px",
            marginBottom: "110px",
          }}
        >
          <Typography
          
            style={{
              fontSize: "16px",
              color: "#707070",
            }}
          >
            Ready to start competing?
          </Typography>

          <Typography
            style={{
              fontSize: "12px",
              color: "#707070",
            }}
          >
            Check out these helpful resources to train a model on our platform
            and submit to the leaderboard
          </Typography>
          <Box
            style={{
              marginTop: "61px",
              display: "flex",
            }}
          >
            <Box
              style={{
                width: "174px",
              }}
            >
              <Box
                component={"a"}
                href="https://colab.research.google.com/drive/1N00idtpoaq1lk9OJE6g4bMqd8o-Qex2C?usp=sharing"
                target="_blank"
                style={subBoxContainerStyle}>
                {/* <PlayCircleOutlineIcon
                  style={{ fontSize: "32px", color: "#266B9A" }}
                /> */}
                {/* <img src={PlayIcon} alt="playIcon" height={"31.75px"} /> */}
                <ColabIcon style={{ height: "31.75px" }} />
                <Typography style={subHeadingStyle}>How to train a Model</Typography>
              </Box>
              <Typography style={subDescriptionStyle}>
                Start an experiment with Colab: connect your Colab notebook and
                start training your model right away
              </Typography>
            </Box>

            <Divider
              flexItem
              orientation="vertical"
              variant="inset"
              style={{
                marginRight: "59.5px",
              }}
            />
            <Box
              style={{
                width: "174px",
              }}
            >
              <Box
                component={"a"}
                href="http://docs.tracebloc.io/"
                target="_blank"
                style={subBoxContainerStyle}>
                <img src={DocumentationIcon} alt="docIcon" height={"31.75px"} />
                <Typography style={subHeadingStyle}>Documentation</Typography>
              </Box>
              <Typography style={subDescriptionStyle}>
                Need some guidance before your dive in? Check out our
                documentation for step-by-step instructions on how to train a
                model on our platform
              </Typography>
            </Box>

            <Divider
              style={{
                marginRight: "59.5px",
              }}
              flexItem
              orientation="vertical"
              variant="inset"
            />
            <Box
              style={{
                width: "174px",
              }}
            >
              <Box
                component={"a"}
                href="#"
                target="_blank"
                style={subBoxContainerStyle}>
                <img src={RectPlayIcon} alt="rectPlayIcon" height={"31.75px"} />
                <Typography style={subHeadingStyle}>Video Tutorial</Typography>
              </Box>
              <Typography style={subDescriptionStyle}>
                Prefer a visual walkthrough? Watch our video tutorial to learn
                how to train a model on our platform
              </Typography>
            </Box>
          </Box>
        </Box>
  );
}

export default PlaceHolderHelp;