import { Box, Button, CircularProgress, Collapse, Grid, Paper, TextField } from '@material-ui/core';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import { spacing } from '@material-ui/system';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import axios from 'axios';
import Header from '../../Components/LoginHeader';
import Footer from '../../Components/Footer';
import { useParams, useHistory } from 'react-router-dom';


const AcceptInvite = (props) => {
    const { sidebar, user, login, network, getUserInfo, setUserUpdate } = useContext(GlobalContext);
    let history = useHistory();

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [relogin, setRelogin] = useState(false);
    const { inviteKey } = useParams();

    message && console.log("ERROR", message)

    const redirectToLogin = (data) => {
        const { user, dataset_key, email } = data;
        localStorage.setItem('_key_usr_tkn', user.token)
        delete user.token
        setUserUpdate({ ...user, })
        
        dataset_key && localStorage.setItem("datasetKey", dataset_key);
        setTimeout(() => {
            history.push({
                pathname: '/',
                state: {
                    datasetKey: dataset_key, 
                    email: email,
                   first_time: true,
                }
            })

        }, 2000)
    }

    const sendAcceptInvite = () => {
        setLoading(true);
        const config = {
            method: 'GET',
            url: `${API_URL}/invitations/accept-team-invite/${inviteKey}/`
        };
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
        axios(config).then((res) => {
            const data = res.data
            console.log("You Archived", data);
            setMessage({
                status: "success",
                type: "Thank you",
                message: data?.message,
            });
            setLoading(false)
            redirectToLogin(data)
            
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response && error.response.status === 404) {
                setMessage({
                    status: "error",
                    type: "Invalid Invitation",
                    message: error.response.data.message,
                });

            } else if (error.response && error.response.status === 406) {
                // setError(error.response.data)
                setMessage({
                    status: "error",
                    type: "Invitation Expired",
                    message: error.response.data.message,
                });
            } else if (error.response && error.response.status === 500) {
                // setError(error.response.data)
                setMessage({
                    status: "error",
                    type: "Error Occurred",
                    message: "There seems to be an issue on our end. We are working hard to fix it as soon as possible.",
                });
            } else {
                setMessage({
                    status: "error",
                    type: "Network Error",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setLoading(false)
        });
    }


    useEffect(() => {
        sendAcceptInvite()
    }, []);

    return (
        <Box>
            <Header />
            <Box className="invitation-main">
                <div className="invitation">
                { loading ?
                        <CircularProgress />
                        :
                        <Grid className="form" container direction="column">
                            <Box style={{ width: '100%', padding: '0px 32px', alignSelf: 'center' }}>
                                <h2
                                    className="title"
                                    style={{ alignSelf: 'center', textAlign: 'center' }}>
                                    {message && message?.type}
                                </h2>
                                {/* <div className="sub-title">tracebloc lets you train your models on high quality data which increase the flexibility of your model and make it generally applicable. We provide you access to the most relevant data with just one click.​</div> */}
                            </Box>
                            {
                                message &&
                                <Grid item>
                                        {
                                            message?.status === "success" ? 
                                                <div className="sub-title">{message?.message}​</div>
                                            :
                                            <div className='error-message'>{message?.message}</div>
                                        }
                                </Grid>
                            }
                        </Grid>
                        
                    }
                </div>
                <Footer />
            </Box>
        </Box>
    );
}

export default AcceptInvite;