import { Avatar, Badge, Box, Button, CircularProgress, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/styles';
import { CloseSharp } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import { API_URL, GlobalContext } from '../../Context/GlobalState';
import { AddPictureIcon, HandIcon, RunIcon } from '../Icon';
import DefaultAvatar from '../../assets/avatar.png'

const useStyles = makeStyles((theme) => ({
    wrapper: {
      position: 'relative',
    },
    fabProgress: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 1,
    },
  }));

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#bdbdbd',
        color: '#fff',
        width: '15px',
        minWidth: '15px',
        height: '15px',
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        "& .MuiSvgIcon-root": {
            fontSize: '15px',

        }

  }
}))(Badge);



const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 24,
      borderRadius: 4,
    },
    colorPrimary: {
      backgroundColor: "rgba(112, 112, 112, 1)",
    },
    bar: {
        borderRadius: 4,
        borderBottomRightRadius: 1,
        borderTopRightRadius: 1,
        backgroundColor: 'rgba(75, 189, 204, 1)',
    },
  }))(LinearProgress);

  

const UserPopup = ({ user, profile_image, closeCallback, itsMe }) => {
    const { logout, state: { experiments: { list } }, setUserUpdate } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileError, setFileError] = useState(false)
    const [error, setError] = useState(null);
    const [summary, setSummary] = useState(null);
    const profilePicRef = useRef(null);
    const classes = useStyles();


    console.log("UserPopup", user)

    const getUserSummary = () => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const user_id = user && user.id ? user.id : user.user_id
        const config = {
            method: 'GET',
            url: `${API_URL}/usersummary/${user_id}/`,
            data: { claps: 1 },
            headers: {
                'Authorization': `Token ${token}`
            },
        };

        axios(config).then((res) => {
            const data = res.data
            console.log("USER SUMMARY", data)
            setSummary({...data});
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure you are logged in.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        if (user) {
            getUserSummary()
        }
        
    }, [])

    const closePopup = (e) => {
        e.stopPropagation();
        closeCallback()
    }

    const handleLogout = () => {
        logout()
    }

    const updateProfile = ({ profile_image }) => {
        
        setUploading(true);

        const formData = new FormData();
        if (profile_image) {
            formData.append("profile_image", profile_image);
        }

        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'PATCH',
            url: `${API_URL}/userinfo/`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Token ${token}`
            },
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("FILE RETURNED", data);
            setError(null);
            setUploading(false);
            setUserUpdate({...data })
        }).catch((error) => {
            if (error.response.status === 404) {
                setError("error", {
                    type: "manual",
                    message: error.response.statusText,
                });
            } else if (error.response && error.response.data) {
                setError("error", {
                    type: "manual",
                    message: "An Error occurred.",
                });
            } else {
                setError("error", {
                    type: "manual",
                    message: "Make sure you are connected to the internet.",
                });
            }
            setUploading(false)
        });
    }

    return (
        <div className="user-popup">
            
            <Grid container justify="space-between" alignItems="center" className="user-header">
                <Grid item>
                    {
                        summary ? (<span className="trainings">{summary.total_running_experiments}</span>)
                            :
                            <CircularProgress color="inherit" size={10} />
                    } <span>Trainings running</span>
                </Grid>
                <Grid item>
                    <Button onClick={closePopup} className="close-button"><CloseSharp className="icon" /> </Button>
                </Grid>
            </Grid>
        
            
            <Box mt={1} className="user-ds-info">
                <Grid container className="item">
                    <Grid item className="icon-container"><ViewListOutlinedIcon className="icon" /></Grid>
                    <Grid item className="datasets">{summary ? (<span>{summary.total_datasets}</span>) : <CircularProgress size={10} />}</Grid>
                    <Grid item className="text"><span>Datasets created</span></Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item className="icon-container"><HandIcon className="icon" /></Grid>
                    <Grid item className="datasets">{summary ? (<span>{summary.total_claps_received}</span>) : <CircularProgress size={10} />}</Grid>
                    <Grid item className="text"><span>Claps received</span></Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item className="icon-container"><RunIcon className="icon" /></Grid>
                    <Grid item className="datasets">{summary ? (<span>{summary.total_experiments}</span>) : <CircularProgress size={10} />}</Grid>
                    <Grid item className="text"><span>Experiments run</span></Grid>
                </Grid>
            </Box>


            <Divider />
            { itsMe &&
                <List className="user-actions" component="nav" aria-label="user settings">
                {/* <ListItem button className="item">
                    <ListItemIcon className="icon-container">
                        <SettingsOutlinedIcon className="icon" />
                    </ListItemIcon>
                    <ListItemText primary="Settings" className="text" />
                </ListItem> */}
                <ListItem button className="item" onClick={handleLogout}>
                    <ListItemIcon className="icon-container">
                        <ExitToAppOutlinedIcon className="icon"/>
                    </ListItemIcon>
                    <ListItemText primary="Logout" className="text" />
                </ListItem>
                </List>
            }


            
            <Grid container className="user-container" alignItems="center">
                <Grid item>
                    { itsMe && <input
                        ref={profilePicRef}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="profile-picture-file"
                            onChange={(e) => {
                                console.log("FILE", e.target.files[0])
                                const file = e.target.files[0]
                                if (file && file['type'].split('/')[0] === 'image') {
                                    updateProfile({profile_image: e.target.files[0]})
                                }
                                setPreview(e.target.files[0]);
                                // clearErrors()
                            }}
                    />}
                    <label htmlFor="profile-picture-file">
                        <IconButton component="span" style={{ padding: '6px', marginRight: '4px' }}>
                            <StyledBadge
                                component="span"
                                className={classes.wrapper}
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={itsMe ? <AddPictureIcon /> : null}>
                                <Avatar
                                    variant="circular"
                                    className={'avatar'}
                                    alt={`${user?.first_name}`}
                                    src={preview ? URL.createObjectURL(preview) : (profile_image || DefaultAvatar)}
                                />
                                {uploading ? <CircularProgress className={classes.fabProgress} size={32} /> : null} 
                            </StyledBadge>
                        </IconButton>
                    </label>
                </Grid>

                <Grid item>
                    <div className="user-name">{user?.first_name} {user?.last_name}</div>
                    <div className="user-email">{user?.email}</div>
                </Grid>

            </Grid>

        </div>
    );
}

export default UserPopup;