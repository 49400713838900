import { Box, Button, Grid, Paper, Slider, Select, MenuItem, Portal, Snackbar } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import './style.css'
import { spacing } from '@material-ui/system';
import DefectListBox from './DefectListBox';
import TubeVoltageBox from './TubeVoltageBox';
import MachinePartsBox from './MachinePartsBox';
import MaterialBox from './MaterialBox';
import Axios from 'axios';
import { makeStyles } from '@material-ui/styles';

import CompanyBox from './CompanyBox';
import { GlobalContext } from '../../Context/GlobalState';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import { CustomTabs } from '../CustomTabs/CustomTabs';
import { CustomTab } from '../CustomTabs/CustomTab';
import DefectsTab from './DefectsTab';
import LengthTab from './LengthTab';
import { Defects } from '../../utils';
import MedicalTab from './MedicalTab';
import AutonomousTab from './AutonomousTab';
import { NotificationCheckIcon } from 'Components/Icon';

  
const useStyles = makeStyles((theme) => ({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectType: {
        
        "& .MuiSelect-outlined": {
            // padding: "2px",
            paddingBottom: '0px',
            paddingTop: '0px',
            paddingLeft: '0px',
            fontSize: '12px',
            color: '#3576a7',
            textTransform: 'capitalize',
        },
        "& .MuiSelect-outlined:focus": {
            backgroundColor: '#fff'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        }
      }
  }));


const DatasetBuilder = (props) => {
    const {
        state, state: { dataset, datasetOptions }, createDatasetOptions,
        createDataSet, createMedicalDataSet, createAutonomousDataSet,
        getDataSet } = useContext(GlobalContext);
    const [tab, setTab] = React.useState("autonomous");
    const [ratio, setRatio] = useState(80);
    const [minClassesError, setMinClassesError] = useState(false)
    const { list, total, amplify, ok, nok } = state.dataset;
    const [datasetSize, setDatasetSize] = useState('small');
    const classes = useStyles();
    const handleChange = useCallback((event, newValue) => {
        setTab(newValue);
    });
    console.log("DATASET LENGTH:", state.dataset, ratio);

    useEffect(() => {
        if (tab === "medical") {
            setRatio(50)
        } else {
            setRatio(80)
        }
        console.log(tab, ratio);
    }, [tab])


    const showMinClassesError = () => {
        setMinClassesError(true)
        setTimeout(() => {
          setMinClassesError(false);
        }, 3000)
      }


    const createDatasetClick = () => {

        const ok = Math.ceil(total * (ratio / 100));
        const nok = Math.ceil(total * (100 - ratio) / 100);

        let defects = [];
        if (datasetOptions?.classes && datasetOptions?.classes.length < 2) {
            showMinClassesError()
            defects = datasetOptions.classes;
            return;
        } else {
            // select all defects if no defects selected.
            Defects.forEach(item => defects.push({ label: item.label, checked: false }))
        }


        console.log(" DEFECTS-X:", tab, datasetOptions?.classes);

        if (tab === 'medical') {
            createMedicalDataSet({
                ratio,
                annotation: datasetOptions.annotation,
                augmentation: datasetOptions.augmentation,
                category: datasetOptions?.category,
                classes: datasetOptions.classes,
                company: datasetOptions.company,
                gender: datasetOptions.gender,
                type: tab
            })
        } else if (tab === 'autonomous') {
            createAutonomousDataSet({
                ratio,
                annotation: datasetOptions.annotation,
                augmentation: datasetOptions.augmentation,
                category: datasetOptions?.category,
                classes: datasetOptions.classes,
                company: datasetOptions.company,
                type: tab
            })
        } else {

            getDataSet({
                ok,
                nok,
                annotation: datasetOptions.annotation,
                augmentation: datasetOptions.augmentation,
                defects: defects,
                voltages: datasetOptions.voltages,
                parts: datasetOptions.parts,
                material: datasetOptions.material,
                company: datasetOptions.company,
            });
    
            /// this is used to load sample images from the json-server for industrial xray
            createDataSet({
                ratio,
                annotation: datasetOptions.annotation,
                augmentation: datasetOptions.augmentation,
                defects: datasetOptions.defects,
                voltages: datasetOptions.voltages,
                parts: datasetOptions.parts,
                material: datasetOptions.material,
                company: datasetOptions.company,
                type: tab,
            })
        }
       

        console.log(" Total:", total * amplify, " amplify:", amplify,);
        console.log("OK:", ok * amplify, " NOK:", nok * amplify);
    }

    const handleDatasetSizeChange = (event) => {
        setDatasetSize(event.target.value);
      };

    return (
        <>
            
            <Grid id="dataset-builder" container direction="row" justify="flex-start">
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <CustomTabs value={tab} onChange={handleChange} aria-label="ant example">
                            {/* <CustomTab value="industrial" style={{ marginLeft: '1px', borderTopLeftRadius: '5px' }} label="Industrial Xray" /> */}
                            {
                                // <CustomTab value="length" style={{ borderTopRightRadius: '5px'}} label="Length" /> 
                                // Remove the style from medical xray if it is not the last
                            }
                            <CustomTab value="autonomous" label="Autonomous Driving" style={{ borderTopLeftRadius: '5px' }} /> 
                            <CustomTab value="medical" label="Human Chest Xray" style={{ borderTopRightRadius: '5px'}} /> 
                            
                        </CustomTabs>
                        <Paper variant="outlined">
                            {/* <TabPanel className="configuration-tab-Panel" value={"industrial"}>
                                <DefectsTab
                                    ratio={ratio}
                                    setRatio={setRatio}
                                />
                            </TabPanel> */}
                            <TabPanel className="configuration-tab-Panel" value={"medical"}>
                                <MedicalTab
                                    ratio={ratio}
                                    setRatio={setRatio}
                                />
                            </TabPanel>
                            <TabPanel className="configuration-tab-Panel" value={"autonomous"}>
                                <AutonomousTab
                                    ratio={ratio}
                                    setRatio={setRatio}
                                />
                            </TabPanel>
                        </Paper>
                    </TabContext>
    
                    <Box className="create-dataset">
                        <Paper variant="outlined" className="dataset-container" elevation={0}>
                            <Select
                                labelId="demo-customized-select-label"
                                id="dataset-access-type"
                                displayEmpty
                                variant='outlined'
                                value={datasetSize}
                                className={classes.selectType}
                                renderValue={(selected) => {
    
                                    if (selected.length === 0) {
                                    return <em>Type</em>;
                                    }
    
                                    return selected
                                }}
                            onChange={handleDatasetSizeChange}>
                                <MenuItem disabled value="">
                                    <Grid style={{width: "200px"}} justifyContent="space-between" container>
                                        <Grid xs={7} item>Size</Grid>
                                        <Grid item> Images</Grid>
                                    </Grid>
                                </MenuItem>
                                <MenuItem value={"small"}>
                                    <Grid style={{width: "200px"}} justifyContent="space-between" container>
                                        <Grid xs={7} item>Small</Grid>
                                        <Grid item> {" < 1K"}</Grid>
                                    </Grid>
                                </MenuItem>
                                <MenuItem disabled value={"medium"}>
                                    
                                    <Grid style={{ width: "200px" }} justifyContent="space-between" container>
                                        <Grid xs={7} item>Medium</Grid>
                                        <Grid item> {" < 10K"}</Grid>
                                    </Grid>
    
                                </MenuItem>
                                <MenuItem disabled value={"large"}>
                                    
                                    <Grid style={{ width: "200px" }} justifyContent="space-between" container>
                                        <Grid xs={7} item>Large</Grid>
                                        <Grid item> {" > 10K"}</Grid>
                                    </Grid>
                                </MenuItem>
                            </Select>
                            <Button className={dataset.count ? "greyed" : "active" } onClick={createDatasetClick}>Create Dataset</Button>
                        </Paper>
                        
                    </Box>
                </Grid>
            </Grid>

            <Portal>
                {
                minClassesError &&
                <Snackbar open={minClassesError} autoHideDuration={5000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                    <Alert
                        iconMapping={{ success: <NotificationCheckIcon style={{ fontSize: 20 }} /> }}
                        variant="filled"
                        severity="warning">Please provide least two classes.</Alert>
                </Snackbar>
                }
            </Portal>
            
        </> 
    );
}

export default DatasetBuilder;