import {
    OLD_API_URL
} from "../Context/GlobalState";

export const Defects = [{
        label: "porosity",
        diameter: 2.00,
        checked: true
    },
    {
        label: "crack",
        diameter: 1.00,
        checked: false
    },
    {
        label: "inclusion",
        diameter: 3.50,
        checked: false
    }
];

export const Diseases = [
    {
        label: "None",
        checked: false
    },
    {
        label: "Atelectasis",
        checked: false
    },
    {
        label: "Cardiomegaly",
        checked: false
    },
    {
        label: "Hernia",
        checked: false
    },
    {
        label: "Nodule",
        checked: false
    },
    {
        label: "Effusion",
        checked: false
    },
    {
        label: "Infiltration",
        checked: false
    },
    {
        label: "Emphysema",
        checked: false
    },
    {
        label: "Pleural_Thickening",
        checked: false
    },
    {
        label: "Pneumothorax",
        checked: false
    },
    {
        label: "Mass",
        checked: false
    },
    {
        label: "Fibrosis",
        checked: false
    },
    {
        label: "Consolidation",
        checked: false
    },
    {
        label: "Edema",
        checked: false
    },
    {
        label: "Pneumonia",
        checked: false
    },
];


export const Autonomous = [
    {
        label: "car",
        checked: false
    },
    {
        label: "person",
        checked: false
    },
    {
        label: "traffic light",
        checked: false
    },
    {
        label: "traffic sign",
        checked: false
    },
    {
        label: "truck",
        checked: false
    },
];

export const Age = [{
        label: "Between",
        value: 0,
        checked: true
    },
    {
        label: "and",
        value: 99,
        checked: true
    },
];



export const defectStandards = [{
        label: "all",
        value: "all"
    },
    {
        label: "low",
        value: "low"
    },
    {
        label: "medium",
        value: "medium"
    },
    {
        label: "high",
        value: "high"
    },
];

export const tubeVoltage = [{
        label: "all",
        value: "all"
    },
    {
        label: "140kV",
        value: "140kV"
    },
    {
        label: "225kV",
        value: "225kV"
    },
    {
        label: "750kV",
        value: "750kV"
    },
];

export const machineParts = [{
        label: "all",
        value: "all"
    },
    {
        label: "cylinderHead",
        value: "Cylinder Head"
    },
    {
        label: "wheel carrier",
        value: "wheel carrier"
    },
    // { label: "Part 1", value: "Part 1" },
    // { label: "Part 2", value: "Part 2" },
    // { label: "Part 3", value: "Part 3" },
];

export const material = [{
        label: "all",
        value: "all"
    },
    {
        label: "aluminum",
        value: "aluminum"
    },
    {
        label: "steel",
        value: "steel"
    },
];

export const company = [{
        label: "all",
        value: "all"
    },
    {
        label: "GMAC",
        value: "GMAC"
    },
    {
        label: "ElringKlinger",
        value: "ElringKlinger"
    },
];

export const gender = [{
        label: "all",
        value: "all"
    },
    {
        label: "Female",
        value: "Female"
    },
    {
        label: "Male",
        value: "Male"
    },
];


export const defectsColors = {
    crack: {
        light: "rgba(72, 3, 85, 1)",
        dark: "rgba(65, 14, 82, 1)"
    },
    inclusion: {
        light: "rgba(255, 167, 0, 1)",
        dark: "rgba(255, 156, 41, 1)"
    },
    porosity: {
        light: "rgba(93, 211, 158, 1)",
        dark: "rgba(125, 208, 162, 1)"
    },

}


export function* splitNParts(num, parts) {
    let sumParts = 0;
    for (let i = 0; i < parts - 1; i++) {
        const pn = Math.ceil(Math.random() * (num - sumParts))
        yield pn
        sumParts += pn
    }
    yield num - sumParts;
}

export const deepCopy = (array) => {
    const newArray = [];
    array.forEach((v, i) => {
        const val = (typeof v === 'object') ? Object.assign({}, v) : v;
        newArray.push(val);
    });
    return newArray;

}


export const GenerateRESTUrls = (defect, {
    ok,
    nok,
    defects,
    voltages,
    parts,
    material,
    company
}) => {

    let urls = [];
    let newUrls = []
    const requests = [];
    let index = 0;


    if (defect === "NOK" && nok !== 0) {
        defects.forEach(d => {
            urls.push(
                `${OLD_API_URL}?defect=NOK&${d.label}_gte=${d.diameter}&_limit=10`
            )
        });
    } else {
        urls.push(
            `${OLD_API_URL}?defect=OK&_limit=10`
        )
    }

    console.log("NO NO", nok, urls);

    newUrls = [...urls]
    // company.forEach(c => {
    //     urls.forEach((url) => {
    //         newUrls[index] = `${url}&company=${c.label}`;
    //         index++;
    //     })
    // })

    urls = [...newUrls];
    index = 0;
    newUrls = [...urls];
    // urls.forEach(url => {
    //     voltages.forEach(v => {
    //         newUrls[index] = `${url}&voltage=${v.label.slice(0, -2)}`
    //         index++;
    //     })
    // });

    voltages.forEach(v => {
        urls.forEach(url => {
            newUrls[index] = `${url}&voltage=${v.label.slice(0, -2)}`
            index++;
        });
    })


    urls = [...newUrls];
    index = 0;
    newUrls = [...urls];
    urls.forEach(url => {
        parts.forEach(p => {
            newUrls[index] = `${url}&part=${p.label}`
            index++;
        })
    });

    urls = [...newUrls];
    index = 0;
    newUrls = [...urls];
    urls.forEach(url => {
        material.forEach(m => {
            newUrls[index] = `${url}&material=${m.label}`
            index++;
        })
    });

    urls = [...newUrls];
    return urls;
}


export const getRandomArrayIndices = (length, count) => {
    const newItems = [];
    const arr = [];
    for (let i = 0; i < length; i++) {
        arr.push(i);
    }
    for (let i = 0; i < count; i++) {
        const idx = Math.floor(Math.random() * arr.length);
        newItems.push(arr[idx]);
        arr.splice(idx, 1);
    }
    return newItems;
}


export function parseJsonString(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
}


export const countClasses = (classArray) => {
    let classes = 0
    classArray && Object.keys(classArray).forEach((item) => {
        if (classArray[item] > 0) {
            classes += 1
        }
    })
    return classes
}


export const checkCompetitors = (competitor_companies) => {

    const competitors = competitor_companies && competitor_companies.every(function (element) {
        return element.company_type === competitor_companies[0].company_type;
    })
    return competitors;
}


export function LightenDarkenColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}


export function pickHex(color1, color2, weight) {
    var w1 = weight;
    var w2 = 1 - w1;
    var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
}

export function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
    var color1 = rgbColor1;
    var color2 = rgbColor2;
    var fade = fadeFraction;
  
    // Do we have 3 colors for the gradient? Need to adjust the params.
    if (rgbColor3) {
      fade = fade * 2;
  
      // Find which interval to use and adjust the fade percentage
      if (fade >= 1) {
        fade -= 1;
        color1 = rgbColor2;
        color2 = rgbColor3;
      }
    }
  
    var diffRed = color2.red - color1.red;
    var diffGreen = color2.green - color1.green;
    var diffBlue = color2.blue - color1.blue;
  
    var gradient = {
      red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
      green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
      blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10),
    };
  
    return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
  }


export function isEmail(email) {
    const pattern = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i);

    if (!pattern.test(email)) {
      return false;
    }
    return true;
}


export const trainingState = {
    stopped: 0,
    inQueue: 1,
    paused: 2,
    completed: 3,
    training: 4,
    aborted: 6,
}


export function encodeQuery(data){
    let query = ""
    for (let d in data)
         query += encodeURIComponent(d) + '='
                  + encodeURIComponent(data[d]) + '&'
    return query !== "" ? "?" + query.slice(0, -1) : "";
}



export const colorsPalate = [
    "#4BBDCC", "#4AB9CA", "#49B6C8", "#48B2C6", "#46AEC4", "#45AAC2", "#44A7C0", "#43A3BE", "#429FBC",
    "#419BBA", "#3F98B9", "#3E94B7", "#3D90B5",
    "#3C8CB3",
    "#3B89B1",
    "#3A85AF",
    "#3881AD",
    "#377DAB",
    "#367AA9",
    "#3576A7",
]



export function isDateBetween(from, to, check) {

    let fDate,lDate,cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}
