import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Comment from './Comment';
import './style.css';
import { API_URL } from '../../../../../../Context/GlobalState';
import { Avatar, Button, CircularProgress, Grid, Popover } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { Send } from '@material-ui/icons';
import UserPopup from '../../../../../UserPopup';
import { AddCommentIcon } from '../../../../../Icon';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '5px',
        borderColor: '#E6E6E6',
        border: 0,
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
            padding: 0,
        },
        "& .MuiOutlinedInput-multiline": {
            padding: 0,
        },
        '& .MuiTextField-root': {
            width: '100%',
            color: '#f7f7f7',
        },
        '& textarea': {
            width: '100%',
            fontSize: 12,
            lineHeight: '20px',
            height: '32px',
            padding: '6px 12px',
            border: '1px solid #E6E6E6',
            borderWidth: '0px',
            borderRightWidth: '1px'
      },
    },
}));

const Comments = (props) => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [comments, setComments] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    
    const classes = useStyles();
    const [value, setValue] = React.useState('');
  
    console.log("Comments", props);

    const getComments = () => {
        setLoading(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'get',
            url: `${API_URL}/comment/${props.experiment}/${page ? '?page=' + page : ''}`,
            headers: {
              'Authorization': `Token ${token}`
            }
          };
        axios(config).then((res) => {
            const data = res.data
            // setComments([...comments, ...data.results])
            setComments([...data.results])
            setTotalPages(data.total_pages)
            setPageSize(data.page_size)
            setLoading(false)
            setError(null)
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure your credentials are correct.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setLoading(false)
        })
    }

    console.log('Comments-LIST', comments)
    useEffect(() => {
        if (props.expanded) {
            getComments();
        }
        // if (experiments.length > 0 && experiments.next) {
        //     getExperiments();
        //     console.log("EXPERIMENTS-LIST-PAGE-NEXT", page);
        // }
    }, [props.expanded, page])


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const saveComment = () => {
        setSaving(true);
        const token = localStorage.getItem('_key_usr_tkn')
        const config = {
            method: 'post',
            url: `${API_URL}/comment/`,
            data: { experimentId: props.experiment, comment: value },
            headers: {
              'Authorization': `Token ${token}`
            }
        };
        axios(config).then((res) => {
            const data = res.data
            console.log("COMMENT-OBJECT", data);
            setComments([...data, ...comments])
            setSaving(false)
            setError(null)
            setValue("")
        }).catch((error) => {
            console.log('CATCH', error.response);
            if (error.response) {
                setError("Please make sure you are authorized.")
            } else {
                setError("Make sure you are connected to the internet.")
            }
            setSaving(false)
        })
    }
    
    const saveChildComment = (comment) => {
        console.log("Returned", comment[0]);
        // const filteredComments = comments.filter((item) => item.id !== comment[0].id)
        const filteredComments = comments.map((item) => {
            if (item.id === comment[0].id) {
                item.child = comment[0].child;
            }
            return item;
        })
        setComments([...filteredComments])
    }
    
    const commentsList = comments.map((comment, index) => {
        if (comment.child.length > 0) {
            return (
                <Comment saveChildComment={saveChildComment} comment={comment} key={comment.id} more={true}>
                    {
                        comment.child.map((child) => {
                            return (<Comment key={child.id} comment={child} saveChildComment={saveChildComment} child={true} />)
                        })
                    }
                </Comment>
            )
        }
        return (
            <Comment saveChildComment={saveChildComment} key={comment.id} comment={comment} />
        )
    })


    const handlePageChange = (event, value) => {
        setPage(value);
      };


    const commentsCount = comments.length + comments.reduce(((sum, array) => sum + array["child"].length), 0);


    /// Popup open and close 
    const openUserPopup = Boolean(userAnchorEl);
    const userPopupId = openUserPopup ? 'simple-user-popover' : undefined;
    
    const handleUserPopup = (e) => {
        e.stopPropagation();
        setUserAnchorEl(e.currentTarget)
    }

    const handleCloseUserPopup = (e) => {
        setUserAnchorEl(null)
    }

    return (
        <div className={`comments ${props.expanded ? 'expand' : ''}`}>
            <div className="comments-count">
                {loading ? <CircularProgress size={10} /> : commentsCount} comments
            </div>
            <Grid container>
                <Grid item>
                    <Avatar
                        onClick={handleUserPopup}
                        src={props.Me.profile_image}
                        className="avatar"
                        alt={props.Me.first_name}
                    />
                    <Popover
                        id={userPopupId}
                        open={openUserPopup}
                        anchorEl={userAnchorEl}
                        onClose={handleCloseUserPopup}
                        elevation={0}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        >
                        <UserPopup itsMe={false} profile_image={props.Me.profile_image} user={props.Me} closeCallback={handleCloseUserPopup} />
                    </Popover>
                </Grid>
                <Grid xs={11} item>
                    <div className={classes.root}>
                        <TextField
                            id="standard-multiline-flexible"
                            label=""
                            placeholder="Leave a comment"
                            multiline
                            value={value}
                            rowsMax={4}
                            rows={1}
                            variant="outlined"
                            onChange={handleChange}
                        />
                        <Button onClick={saveComment} className="create-comment-btn" disabled={value.length < 1}>
                            {saving ? <CircularProgress color="inherit" size={20} /> : <AddCommentIcon />}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div style={{ opacity: loading ? '0.4' : '1.0' }} >
                {commentsList}
            </div>
            
            {
                loading && <Grid container justify="center" alignItems="center" style={{ marginTop: 16 }}>
                    <CircularProgress />
                </Grid>
            }
            {
                (commentsList.length === 0 && !loading)
                && <Grid container justify="center" alignItems="center">
                        <span className="no-comments">
                            {/* No comments */}
                        </span>
                        
                </Grid>
            }
        
            { commentsList.length > 0 && totalPages > 1 &&
                <Grid container justify="center" style={{ marginTop: 16, marginBottom: 16 }}>
                    <Pagination count={totalPages} page={page} size="small" onChange={handlePageChange} />
                </Grid>
            }

        </div>
    );
}

export default Comments;