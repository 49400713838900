import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import './style.css'
import { GlobalContext } from '../../../Context/GlobalState';
import { TabContext, TabPanel } from '@material-ui/lab';
import { CustomTabs } from '../../CustomTabs/CustomTabs';
import { CustomTab } from '../../CustomTabs/CustomTab';
import TrainingParametersTab from './TrainingParametersTab';

const TrainingParameters = (props) => {
    const { dataset, dataset: { count, groups } } = props;
    const [tab, setTab] = useState("all");
    let defectsList = [];
    const diseaseLength = count?.disease && Object.keys(count?.disease).length || 0;
    for(let key in count?.defect) {
        if (count.defect[key] > 0) {
            defectsList[key] = count.defect[key];
            defectsList.push({[key]: count.defect[key] })
        }
        // increase the count
    }

    console.log("MEDICAL-COUNT", count?.disease && Object.keys(count?.disease).length)
    console.log("DEFECTS", defectsList.length);
    const handleChange = useCallback((event, newValue) => {
        setTab(newValue);
    });

    useEffect(() => {
        if (defectsList.length === 1 && defectsList[0]) {
            setTab(Object.keys(defectsList[0])[0])
        }

    }, [])

    return (
        <Grid item xs={4} style={{ maxWidth: "40%", flexBasis: "40%"}} height="100%">
            <TabContext value={tab}>
                <CustomTabs className="custom-tab-container"  value={tab} onChange={handleChange} aria-label="ant example">
                    {<CustomTab value="all" label="Required model parameters" />}
                </CustomTabs>
                <Paper variant="outlined">
                    {
                        !tab && <div className="tab-panel inner-content" value={"all"}>
                            <TrainingParametersTab {...props} defect={"all"} />
                        </div>
                    }
                    <TabPanel className="tab-panel inner-content" value={"all"}>
                        <TrainingParametersTab {...props} isSaving={props.isSaving} defect={"all"} />
                    </TabPanel>
                    
                </Paper>
            </TabContext>
        </Grid>
    );
}

export default TrainingParameters;