import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./style.css";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import {
  BookMarkIcon,
  ShareIcon,
  EditIcon,
  ArchiveIcon,
  StyledRating,
} from "../../Icon";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Rating from "@material-ui/lab/Rating";
import { StarBorderOutlined, StarRate } from "@material-ui/icons";
import LeaderBoardRow from "./LeaderBoardRow";
import { Alert } from "@material-ui/lab";

import bmw from "../../../assets/bmw.png";
import diehl from "../../../assets/diehl.png";
import bosch from "../../../assets/bosch.png";
import airbus from "../../../assets/airbus.png";
import plusgfplus from "../../../assets/plusgfplus.png";
import siemens from "../../../assets/siemens.png";
import accenture from "../../../assets/accenture.png";
import awesome from "../../../assets/awesome.png";
import bcg from "../../../assets/bcg.png";
import Deloitte from "../../../assets/Deloitte.png";
import { API_URL } from "../../../Context/GlobalState";
import axios from "axios";
import useStickyHeader from "../../../utils/useStickyHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "#",
    description: "",
    leftAlign: true,
  },
  // { id: 'logo', numeric: false, disablePadding: false, label: '', description: "" },
  {
    id: "team",
    numeric: false,
    disablePadding: false,
    label: "Team",
    description: "",
    leftAlign: true,
  },
  {
    id: "members",
    numeric: false,
    disablePadding: false,
    label: "Members",
    description: "",
    leftAlign: true,
  },
  {
    id: "running_score",
    numeric: true,
    disablePadding: false,
    label: "Score",
    description: "",
  },
  {
    id: "final_score",
    numeric: true,
    disablePadding: false,
    label: "Final Score",
    description: "",
  },
  {
    id: "model_parameters",
    numeric: false,
    disablePadding: false,
    label: "Size (Params / Bytes)",
    description: "Size of the model in parameters and in bytes",
    leftAlign: true,
  },
  {
    id: "inference_flopsutilised",
    numeric: false,
    disablePadding: false,
    label: "Energy Consumption (FLOP / Rel %)",
    description: "Average energy consumption per inference in FLOP and relative energy consumption compared to the baseline (leading model)",
  },
  {
    id: "inference_gco2",
    numeric: false,
    disablePadding: false,
    label: "CO2e",
    description: "Energy consumption in grams or milligram of CO2 equivalent",
  },
  {
    id: "entries",
    numeric: false,
    disablePadding: false,
    label: "Submits",
    description: "# of Experiments run by Competitor",
  },
  {
    id: "flops",
    numeric: true,
    disablePadding: false,
    label: "Computation Budget",
    description: "Provided Computation by Host per Collaboration​",
  },
  {
    id: "last",
    numeric: true,
    disablePadding: false,
    label: "Last",
    description: "Last submitted Experiment​",
  }
];

function EnhancedTableHead(props) {
  const { classes, numSelected, rowCount, martic, final_score, isSustainable } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === "final_score" && !final_score) {
            return null
          }
          if (headCell.id === "inference_gco2" && !isSustainable) {
            return null
          }
          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "default"}
              className={headCell.id}
              style={{}}
            >
              {headCell.id === "code" ? (
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        Benchmarked Model results​ <br />
                        <br />
                      </div>
                      <div>
                        At the end of the competition
                        <br />
                        the Model which make it into
                        <br />
                        the money will be shared
                        <br />
                        with the Host. ​
                      </div>
                    </React.Fragment>
                  }
                >
                  <span>{headCell.label}</span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${
                    headCell.id === "score" ? martic : headCell.description
                  }`}
                >
                  <span>{headCell.label}</span>
                </Tooltip>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const LeaderBoard = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("score");
  const [page, setPage] = useState(0);
  const [leaderboard, setLeaderBoard] = useState([]);
  const [next, setNext] = useState(null);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { componentRef, isSticky, isBottom, coords } = useStickyHeader();


  const getLeaderBoardEntries = (page) => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");

    let headers = {}
    if (token) {
      headers = {
        Authorization: `Token ${token}`,
      }
    }

    const config = {
      method: "get",
      url: `${API_URL}/leaderboard/${props.dataset}/${
        page ? "?page=" + page : ""
      }`,
      headers,
    };
    axios(config)
      .then((res) => {
        const data = res.data;
        console.log(data);
        setLeaderBoard([
          ...leaderboard,
          ...data.results]);
        setNext(data.next);
        setLoading(false);
        setError(null);

      })
      .catch((error) => {
        console.log("CATCH", error.response);
        if (error.response) {
          setError("Please make sure your credentials are correct.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (leaderboard.length === 0) {
      getLeaderBoardEntries();
    }

    const allZero = Object.values(coords).every(value => value === 0);

    if (leaderboard.length > 0 && next) {
      getLeaderBoardEntries(page);
    }
  }, [page]);

  useEffect(() => {
    console.log("Scroll-LEADERBOARD", isBottom, coords);
    const allZero = Object.values(coords).every(value => value === 0);
    if ((isBottom || allZero) && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom, next]);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, leaderboard.length - page * rowsPerPage);

  const handleClick = (e, id) => {
    console.log("Clicked", e, id);
  };

  console.log("pages-LEADERBOARD", leaderboard.length, isBottom, next, coords);
  console.log("###LEADERBOARD", props.score_formula_display);
  console.log("###is_sustainable", props.is_sustainable);

  const getFinalScoreStatus = () => {
    if (leaderboard.length > 0) {
      if (leaderboard[0]?.final_score) {
          return true
        }
    } 
    return false
  }

  return leaderboard.length > 0 ? (
    <Box pt={1}>
      {/* <Alert severity="info" color="info">
                    <div>This leaderboard is calculated with approximately 12% of the test data.</div>
                    The final results will be based on the other 88%, so the final standings may be different.
                </Alert> */}
      <div class="competition-leaderboard__legends">
        <div class="competition-leaderboard__legend competition-leaderboard__legend--in-the-money">
        Price contenders
        </div>
        <div class="competition-leaderboard__legend description">
          This leaderboard is calculated with approximately {props.test_data_percentage}% of the test data. The final results will be based on the other {props.final_data_percentage}%, so the final standings may be different.
        </div>
        {/*<div class="competition-leaderboard__legend competition-leaderboard__legend--silver">Silver</div>
                    <div class="competition-leaderboard__legend competition-leaderboard__legend--bronze">Bronze</div> */}
      </div>

      <TableContainer
        TableContainer
        ref={componentRef}
        style={{ marginTop: "8px", maxHeight: "calc(100vh - 262px)" }}
        className="table-conatainer"
      >
        <Table stickyHeader className="leaderboard-table" aria-label="sticky simple table">
          <EnhancedTableHead
            martic={props.score_formula_display}
            final_score={ getFinalScoreStatus() }
            classes={classes}
            numSelected={selected.length}
            rowCount={leaderboard.length}
            isSustainable={props.is_sustainable}
          />
          <TableBody>

            {
              /// no sort on client side 
              //stableSort(leaderboard, getComparator(order, orderBy))
              leaderboard.map(
              (row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <LeaderBoardRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    totalEntries={leaderboard.length}
                    position={index + 1}
                    baseEnergyConsumption={leaderboard[0].inference_flopsutilised}
                    totalPrizes={props.prizes.length}
                    selected={isItemSelected}
                    is_private={props.is_private}
                    key={row.id}
                    labelId={labelId}
                    dataset={props.dataset}
                    row={row}
                    scoreFormula={props.score_formula_display}
                    hostCompany={props?.host_company}
                    isSustainable={props?.is_sustainable}
                  />
                );
              }
              )
            }

            
            {
              loading ?
                <TableRow>
                  <TableCell className="empty-td" colSpan={7}>
                    <Grid container justify="center" alignItems="center"><CircularProgress /></Grid>
                  </TableCell>
                </TableRow>
                : null}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell className="empty-td" colSpan={7}>
                  &nbsp;
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <Grid container justify="center" alignItems="center">
      {loading ? <CircularProgress /> : <div>No entries found</div>}
    </Grid>
  );
};

export default LeaderBoard;
